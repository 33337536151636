// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatMessage_message__uGX-Y {
    max-width: 50%;
}

.ChatMessage_para__tEtVo {
    width: max-content;
    padding: 15px;
    border-radius: 5px;
    font-size: 14px;
    background: #f7f7f7;
    max-width: 100%;
}

.ChatMessage_message_detail__-HVMi {
    display: flex;
    column-gap: 10px;
    margin-top: 10px;
}

.ChatMessage_message_span__S3mk- {
    font-size: 13px;
}

.ChatMessage_reply__hG9N9 {
    margin-left: auto;
}

.ChatMessage_reply__hG9N9 .ChatMessage_message_detail__-HVMi {
    justify-content: flex-end;
}

.ChatMessage_reply__hG9N9 .ChatMessage_para__tEtVo {
    background: none;
    border: 1px solid #d9d9d9;
    margin-left: auto;

}






/* Quotation */

.ChatMessage_quotation__fgKRg {
    padding: 14px 15px 10px;
    margin-top: 10px;
    background: #f2fffe;
    border-radius:5px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 5px;
    align-items: center;
    column-gap: 20px;
    cursor: pointer;
}

.ChatMessage_quotation__fgKRg h6 {
    width: 100%;
    font-size: 17px;
    margin-bottom: 5px;
}

.ChatMessage_quotation__fgKRg span {
    font-size: 13px;
    color: #857d7d;
    font-weight: 500;
}

.ChatMessage_quotation__fgKRg b {
    width: 100%;
    font-size: 25px;
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/Component/ChatCompoments/ChatMessage/ChatMessage.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,iBAAiB;;AAErB;;;;;;;AAOA,cAAc;;AAEd;IACI,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,aAAa;IACb,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".message {\n    max-width: 50%;\n}\n\n.para {\n    width: max-content;\n    padding: 15px;\n    border-radius: 5px;\n    font-size: 14px;\n    background: #f7f7f7;\n    max-width: 100%;\n}\n\n.message_detail {\n    display: flex;\n    column-gap: 10px;\n    margin-top: 10px;\n}\n\n.message_span {\n    font-size: 13px;\n}\n\n.reply {\n    margin-left: auto;\n}\n\n.reply .message_detail {\n    justify-content: flex-end;\n}\n\n.reply .para {\n    background: none;\n    border: 1px solid #d9d9d9;\n    margin-left: auto;\n\n}\n\n\n\n\n\n\n/* Quotation */\n\n.quotation {\n    padding: 14px 15px 10px;\n    margin-top: 10px;\n    background: #f2fffe;\n    border-radius:5px;\n    display: flex;\n    flex-wrap: wrap;\n    row-gap: 5px;\n    align-items: center;\n    column-gap: 20px;\n    cursor: pointer;\n}\n\n.quotation h6 {\n    width: 100%;\n    font-size: 17px;\n    margin-bottom: 5px;\n}\n\n.quotation span {\n    font-size: 13px;\n    color: #857d7d;\n    font-weight: 500;\n}\n\n.quotation b {\n    width: 100%;\n    font-size: 25px;\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": `ChatMessage_message__uGX-Y`,
	"para": `ChatMessage_para__tEtVo`,
	"message_detail": `ChatMessage_message_detail__-HVMi`,
	"message_span": `ChatMessage_message_span__S3mk-`,
	"reply": `ChatMessage_reply__hG9N9`,
	"quotation": `ChatMessage_quotation__fgKRg`
};
export default ___CSS_LOADER_EXPORT___;
