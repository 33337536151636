import React, { useEffect, useState } from 'react'
import styles from './modal.module.css'
import { Button, Form, Modal } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { BASE_URL } from '../../../Apis/BaseUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import ToasterUpdate from '../../../Component/Toaster/ToasterUpdate';
import moment from 'moment';
import DropInputCoupon from '../../../Component/DropDownComp/DropInputCoupon';
import AsyncSelectData from '../../../Component/AsyncSelectData/AsyncSelectData';
import { LuAlertCircle } from "react-icons/lu";


const CoupanModal = ({ isOpen, closeModal, getData }) => {
    const [loading, setLoading] = useState(false)
    const [categoryData, setCategoryData] = useState([])
    const [labelName, setLableName] = useState('')
    const [code, setCode] = useState('')
    const [couponType, setCouponType] = useState('category')
    const [dateValue, setDateValue] = useState('')
    const [discountType, setDiscountType] = useState('percentage')
    const [discount, setDiscount] = useState('')
    const [maxDiscount, setMaxDiscount] = useState('')
    const [minAmount, setMinAmount] = useState('')
    const [selectData, setSelectData] = useState([])
    const [currentCategory, setCurrentCategory] = useState({})
    const [currentProduct, setCurrentProduct] = useState({})
    const [objectValue, setObjectValue] = useState("category")
    const [containsSpace, setContainsSpace] = useState(false);
    const [showInput, setShowInput] = useState(false);
    const [value, setValue] = useState(); // Default value greater than 0


    const handleCheckboxChange = (e) => {
        setShowInput(e.target.checked);
    };


    const handleCodeChange = (e) => {
        const newValue = e.target.value;
        const hasSpace = newValue.includes(' ');
        console.log(hasSpace)
        if (hasSpace) {
            setContainsSpace(true);
        } else {
            setContainsSpace(false)
        }
        // Only update the code state if there are no spaces
        if (!hasSpace) {
            setCode(newValue);
        }
    };


    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    let token = profileToken.token;
    const obj = {
        category: {
            url: "category",
            state: currentCategory,
            stateHandler: setCategoryData,
            placeholder: "select category"
        },
        product: {
            url: "product",
            state: currentProduct,
            stateHandler: (data) => setCurrentProduct(data),
            placeholder: "select product"
        }
    }



    const getCategoryData = async (query) => {
        // console.log(register)
        let res = await axios.get(`${BASE_URL}/${obj[objectValue]?.url ? obj[objectValue]?.url : 'category'}?limit=10&page=1&search=${query}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token} `,
            },
        })
        // console.log(res.data.data)
        let resData = res?.data?.data?.docs?.map(item => ({
            value: item._id,
            label: item.name
        }));
        // console.log(resData)
        // return resData

        setSelectData(resData)
        // setPageInfo({ ...res.data.data, docs: null })
    }

    const selectProps = {
        placeholder: obj[objectValue]?.placeholder,
        data: selectData,
        dataHandler: getCategoryData,
        valueHandler: obj[objectValue]?.stateHandler,
    }
    useEffect(() => {
        if (couponType !== "global") {
            setObjectValue(couponType)
        }
    }, [couponType])



    const handleAdd = async () => {
        let bdy = {
            couponCode: code,
            couponLabel: labelName,
            expiryDate: dateValue,
            couponType: couponType, // 'category', 'product', 'global'
            minimumAmount: minAmount,
            discountType: discountType,       // percentage, fixed
            discountValue: discount,
            maxDiscount: maxDiscount,
        }
        if (couponType === 'category') {
            bdy.applicableCategory = currentCategory?.value; // Add category field
        } else if (couponType === 'product') {
            bdy.applicableProduct = currentProduct?.value; // Add product field
        }
        let register = `${BASE_URL}/coupon`
        const myToast = toast.loading('Please Wait...')
        setLoading(true)
        try {

            let res = await axios.post(register, bdy, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token} `,
                },
            })
            console.log(res)
            if (!res.data.success) {
                ToasterUpdate(myToast, res.data.message, "error")
            }
            ToasterUpdate(myToast, res.data.message, "success")
            getData()
        } catch (error) {
            ToasterUpdate(myToast, error.message, "error")
        }
        finally {
            setLoading(false)
            closeModal()
        }

    }
    let new_d = moment(new Date()).format('YYYY-MM-DDTHH:mm');
    console.log(containsSpace, "contain")

    const handleChange = (e) => {
        const newValue = parseInt(e.target.value);
        if (newValue > 0) {
          setValue(newValue); // bad me dekhna pdega
        }
      };


    return (
        <React.Fragment>
            <Modal show={isOpen} onHide={closeModal}>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: "18px" }}>Add Coupon</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-3'>
                    <div className={styles.main_section_name}>
                        <div className='d-flex flex-column w-100'>
                            <label for="name">Coupon Label</label>
                            <input type="text" value={labelName} style={{ fontSize: "14px", color: "#989898" }} className='px-2' onChange={(e) => setLableName(e.target.value)} id='name' />
                        </div>
                        {/* <div className='d-flex flex-column w-100'>
                            <label for="name">Coupon code</label>
                            <input className='px-2' style={{ fontSize: "14px", color: "#989898" }} type="text" value={code} onChange={(e) => setCode(e.target.value)} id='name' />
                        </div> */}
                        {/* <div className='d-flex flex-column w-100'>
                            <label htmlFor="name">Coupon code</label>
                            <input
                                className='px-2'
                                style={{ fontSize: "14px", color: "#989898" }}
                                type="text"
                                value={code}
                                onChange={(e) => setCode(e.target.value.replace(/\s/g, ''))}
                                onKeyPress={(e) => {
                                    if (e.key === ' ') {
                                        e.preventDefault();
                                    }
                                }}
                                id='name'
                            />
                        </div> */}
                        <div className='d-flex flex-column w-100'>
                            <label htmlFor="name">Coupon code</label>
                            <input
                                className='px-2'
                                style={{ fontSize: "14px", color: "#989898" }}
                                type="text"
                                value={code}
                                onChange={handleCodeChange}
                                // onKeyPress={handleKeyPress}
                                id='name'
                            />
                            {containsSpace && <p style={{ color: 'red', fontSize: "13px" }} className='d-flex gap-2 align-items-center'><LuAlertCircle />
                                Spaces are not allowed</p>}
                        </div>

                    </div>

                    <div className={styles.main_section_name}>
                        <div className='d-flex flex-column w-100'>
                            <label for="name">Coupon Type</label>
                            <Form.Select aria-label="Default select example" value={couponType} onChange={(e) => setCouponType(e.target.value)}>
                                {/* <option>Select Product</option> */}
                                <option value="category" selected>Category</option>
                                <option value="product">Product</option>
                                <option value="global">Global</option>
                            </Form.Select>
                        </div>
                        {couponType != "global" &&
                            <div className='d-flex flex-column w-100'>
                                <label for="name">Applicable Options</label>
                                {/* {couponType == "product" ?
                                <DropInputCoupon func={getCategoryData} data={categoryData} search={search} setSearch={setSearch}/> : <DropInputCoupon func={getProductData} data={productData} search={search2} setSearch={setSearch2}/> } */}

                                <AsyncSelectData {...selectProps} />

                                {/* <Form.Select aria-label="Default select example" value={applicableOptions} onChange={(e) => setApplicableOptions(e.target.value)}>
                                    {couponType == "product" ? productData.map((item, index) => (
                                        <option key={index} value={item._id} selected>{item.name} </option>
                                    )) : categoryData.map((item, index) => (
                                        <option key={index} value={item._id} selected>{item.name}</option>
                                    ))}
                                </Form.Select> */}
                            </div>}

                    </div>

                    <div className={styles.main_section}>
                        <label for="name2">Expiry Date</label>
                        <input type="datetime-local" style={{ fontSize: "14px", color: "#989898" }} min={new_d} value={dateValue} onChange={(e) => setDateValue(e.target.value)} id='name2' />
                    </div>
                    <div className={styles.main_section}>
                        <label className='d-flex align-items-center gap-2'>
                            <input
                                type="checkbox"
                                onChange={handleCheckboxChange}
                                //   className='h-0'
                                style={{ height: "30px" }}
                            />
                          Set Redemption limit
                        </label>
                        {showInput && (
                            <div>
                                <input
                                   placeholder="Enter redemption limit..."
                                   className='w-100'
                                   type="number"
                                   id="numberInput"
                                   value={value}
                                   min={1} // Minimum value greater than 0
                                   onChange={handleChange}
                                />
                            </div>
                        )}
                    </div>
                    <div className={`${styles.main_section} flex-row`}>
                        <div className='d-flex flex-column w-100'>
                            <label for="meta" className='mb-2'>Discount Type</label>
                            <Form.Select aria-label="Default select example" style={{ fontSize: "14px", color: "#989898", height: "40px" }} value={discountType} onChange={(e) => setDiscountType(e.target.value)}>

                                <option value="percentage" selected>Percentage</option>
                                <option value="fixed">Fixed</option>

                            </Form.Select>
                        </div>
                        {
                            discountType != "fixed" && <div className='d-flex flex-column w-100'>
                                <label for="name" className='mb-2'>Max Discount</label>
                                <input type="number" style={{ fontSize: "14px", color: "#989898" }} value={maxDiscount} onChange={(e) => setMaxDiscount(e.target.value)} id='name' />
                            </div>
                        }
                    </div>
                    <div className={styles.main_section}>
                        <label for="name">Minimum Order Value</label>
                        <input type="number" style={{ fontSize: "14px", color: "#989898" }} value={minAmount} onChange={(e) => setMinAmount(e.target.value)} id='name' />
                    </div>
                    <div className={styles.main_section}>
                        <label for="name">Discount</label>
                        <input value={discount} style={{ fontSize: "14px", color: "#989898" }} onChange={(e) => setDiscount(e.target.value)} type="number" id='name' />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={styles.model_button}>
                        <button className='bg-white text-black border' variant="secondary" onClick={closeModal}> Cancel  </button>
                        <button variant="primary" onClick={() => handleAdd()}>  Save </button>
                    </div>

                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default CoupanModal