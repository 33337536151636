import React, { useEffect, useState } from 'react'
import styles from './product.module.css';
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import ContainerSection from '../../Component/ContainerSection/ContainerSection'
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../Apis/BaseUrl';
import axios from 'axios';
import NewPagination from '../../Component/NewPagination/NewPagination';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import FallProduct from '../../Component/FallbackImgae/FallProduct';
import ToasterUpdate from '../../Component/Toaster/ToasterUpdate';
import NotFound from '../../Component/NotFound/NotFound';
import formatter from '../../Component/NumberFormatter/formatter';
import { FormatWithoutDoller } from '../../Component/FormatNumber/FormatWithoutDoller';
import { FormatNumber } from '../../Component/FormatNumber/FormatNumber';

const td1 = [
    {
        h5: 'Total Products',
        p: '0'
    },
    {
        h5: 'Active Products',
        p: '0',
    },
    {
        h5: 'Recent Products',
        p: '0'
    },
    {
        h5: 'Out of Stock Products',
        p: '0'
    },

    {
        h5: 'Group Buy Products',
        p: '0'
    },
    {
        h5: 'Instabuild',
        p: '0'
    }
]

const AllProduct = () => {
    const [data, setData] = useState([])
    const [page, setPage] = useState(1)
    const [pageInfo, setPageInfo] = useState({})
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const [tileData, setTileData] = useState({})
    const [keySearch, setKeySearch] = useState('')

    const navigate = useNavigate()
    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;
    const getTileData = async () => {

        let register = `${BASE_URL}/product/tiles`
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        console.log(res.data.data)
        td1[0].p = FormatWithoutDoller(res.data.data?.totalProducts || 0)
        td1[1].p = FormatWithoutDoller(res.data.data?.activeProducts || 0)
        td1[2].p = FormatWithoutDoller(res.data.data?.recentProducts || 0)
        td1[3].p = FormatWithoutDoller(res.data.data?.outofstockProducts || 0)
        td1[4].p = FormatWithoutDoller(res.data.data?.groupByProducts || 0)
        td1[5].p = FormatWithoutDoller(res.data.data?.instabuildProducts || 0)
        setTileData(res.data.data)
    }

    useEffect(() => {
        getTileData()
    }, [])

    const getData = async () => {

        let register = `${BASE_URL}/product?limit=${limit}&page=${page}&search=${search}&key=${keySearch}`

        // console.log(register)
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        console.log(res.data.data.docs)
        setData(res.data.data.docs)
        setPageInfo({ ...res.data.data, docs: null })
    }

    useEffect(() => {
        getData()
    }, [limit, page, search, keySearch]
    )

    useEffect(() => {
        setPage(1)
    }, [limit]
    )

    const paginationProps = {
        setPage,
        pageInfo, setLimit
    }
    const handleDelete = async (id) => {
        console.log(id)
        const myToast = toast.loading('Please Wait...')
        setLoading(true)
        try {
            let register = `${BASE_URL}/product/${id}/remove`

            const response = await axios.delete(register,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`
                    }
                });
            console.log(response)
            toast.update(myToast, {
                render: response.data.message,
                type: 'success',
                isLoading: false,
                autoClose: 1500
            });

            getData()
        } catch (error) {
            toast.update(myToast, {
                render: error.message,
                type: 'error',
                isLoading: false,
                autoClose: 1500
            })
            console.error('Error deleting resource:', error);
        }
        finally {
            setLoading(false)
        }
    }

    const handleActive = async (id) => {
        // console.log(item)
        let register = `${BASE_URL}/product/${id}/approve`
        const myToast = toast.loading('Please Wait...')
        // console.log(register)
        try {

            let res = await axios.put(register, {}, {
                // withCredentials : true,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token} `,
                },
            })

            if (!res.data.success) {
                ToasterUpdate(myToast, res.data.message, "error")
            }
            ToasterUpdate(myToast, res.data.message, "success")
            getData()
        } catch (error) {
            // console.log(error)
            ToasterUpdate(myToast, error.message, "error")
        }

    };
    //   console.log(data)
    return (
        <React.Fragment>
            <Helmet>
                <title>All Product</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            {/* -----------------hero-section---------------- */}

            <div className={styles.hero_Section}>
                <ContainerSection data={td1} cls={styles.cls} />

                <div className={styles.filter_section}>
                    <div className={styles.filter}>
                        <p>Filters : </p>
                        <p>Status </p>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio"
                                name="flexRadioDefault" id="flexRadioDefault2"
                                value=""
                                onChange={(e) => setSearch(e.target.value)}
                                checked={search === ''} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                All
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                value="active"
                                onChange={(e) => setSearch(e.target.value)} checked={search === 'active'} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Active
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                value="inactive"
                                onChange={(e) => setSearch(e.target.value)} checked={search === 'inactive'} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Inactive
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                value="instock"
                                onChange={(e) => setSearch(e.target.value)} checked={search === 'instock'} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                In Stock
                            </label>
                        </div>
                        <div
                            className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="outofstock"
                                onChange={(e) => setSearch(e.target.value)} checked={search === 'outofstock'} />
                            <label className={styles.form_check_label} for="flexRadioDefault2">
                                Out of Stock
                            </label>
                        </div>
                        <div
                            className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="groupby"
                                onChange={(e) => setSearch(e.target.value)} checked={search === 'groupby'} />
                            <label className={styles.form_check_label} for="flexRadioDefault2">
                                Group Buy
                            </label>
                        </div>
                        <div
                            className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="instabuild"
                                onChange={(e) => setSearch(e.target.value)} checked={search === 'instabuild'} />
                            <label className={styles.form_check_label} for="flexRadioDefault2">
                                Instabuild
                            </label>
                        </div>

                    </div>
                    {/* <div className={styles.btn_section}>
                        <button>Add Product</button>
                    </div> */}
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                    <h6 className='my-4'>All Products</h6>
                    <input type="text" value={keySearch} onChange={(e) => setKeySearch(e.target.value)} style={{
                        height: "40px",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #d9d9d9", outline: "none"
                    }} placeholder="Search..." required />

                </div>


                {/* -------------table_section-------------- */}
                {data.length > 0 ?
                    <>
                        <div className={styles.main_table}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        {/* <th scope="col"><input type="radio" /></th> */}
                                        <th className='px-5'>Status</th>
                                        <th>Image</th>
                                        <th>SKU</th>
                                        <th>Product Name</th>
                                        <th>Date created</th>
                                        <th>Available</th>
                                        <th>Approve</th>
                                        <th>Instabuild</th>
                                        <th>Group Buy</th>
                                        <th>Price</th>
                                        <th>Seller</th>
                                        {/* <th scope="col">Feature</th> */}
                                        <th className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((item, index) => (
                                        <tr>

                                            {/* <th scope="row"><input type="radio" /></th> */}
                                            <td className='px-5'>{item.inStock == true ? "In Stock" : "Out of Stock"}</td>
                                            <td><FallProduct cls={styles.img1} imgData={item?.url} alt="wood" /></td>
                                            <td>{item?.sku}</td>
                                            <td className='text-capitalize'>{item?.name?.length > 0 ? item.name.slice(0, 20) + "..." : item?.name}</td>
                                            <td><Moment format="YYYY/DD/MM">{item?.estDeliveryDate}</Moment></td>
                                            <td><input type="number" placeholder='0' value={item?.quantityAvailable} /></td>

                                            <td>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" onChange={() => handleActive(item._id)} checked={item.isApproved} />
                                                </div>
                                            </td>
                                            <td>{item?.instabuild == true ? "yes" : "no"}</td>
                                            <td>{item?.groupBy == true ? "yes" : "no"}</td>
                                            <td>{FormatNumber(item?.price || 0)}</td>
                                            <td className='text-capitalize'>{item?.seller?.name}</td>
                                            {/* <td>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked />
                                    </div>
                                </td> */}
                                            <td>
                                                <div className={`${styles.image_section} d-flex justify-content-center`} style={{ cursor: "pointer" }}>
                                                    {/* <img src="/image/edit.png" alt="edit" /> */}
                                                    <a href={`https://visual-build.netlify.app/product/${item.slug}?id=${item._id}`} target='_blank'> <img src="/image/eye.png" alt="copy" /></a>
                                                    <img src="/image/delete.png" alt="delete" onClick={() => handleDelete(item._id)} />
                                                </div>
                                            </td>
                                        </tr>))}

                                </tbody>
                            </table>
                        </div>
                        <NewPagination {...paginationProps} />

                    </>
                    : <NotFound />}
            </div>

        </React.Fragment>
    )
}

export default AllProduct