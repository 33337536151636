import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import EditBanner from './Modal/EditBanner'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../Apis/BaseUrl'
import axios from 'axios'
import Cookies from 'js-cookie'
import EditSlider from './Modal/EditSlider'
import EditGroupBuySlider from './Modal/EditGroupSlider'
import EditClearanceSlider from './Modal/EditClearanceSlider'

const CardSection = (props) => {
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const closeModal = () => setShow(false)
  const [show1, setShow1] = useState(false)
  const handleShow1 = () => setShow1(true)
  const closeModal1 = () => setShow1(false)
  const [show2, setShow2] = useState(false)
  const handleShow2 = () => setShow2(true)
  const closeModal2 = () => setShow2(false)
  const [show3, setShow3] = useState(false)
  const handleShow3 = () => setShow3(true)
  const closeModal3 = () => setShow3(false)
  const [loading, setLoading] = useState(false)

  let profileTokenJson = Cookies.get("v&b_token");
  let profileToken = JSON.parse(profileTokenJson);
  // console.log(profileToken.token)
  let token = profileToken.token;

  const handleDelete = async (id) => {
    console.log(id)
    const myToast = toast.loading('Please Wait...')
    setLoading(true)
    try {
      let register = `${BASE_URL}/banner/${id}/delete`

      const response = await axios.delete(register,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        });
      console.log(response)
      toast.update(myToast, {
        render: response.data.message,
        type: 'success',
        isLoading: false,
        autoClose: 1500
      });

      props?.func()
    } catch (error) {
      toast.update(myToast, {
        render: error.message,
        type: 'error',
        isLoading: false,
        autoClose: 1500
      })
      console.error('Error deleting resource:', error);
    }
    finally {
      setLoading(false)
    }
  }
  const handleDelete1 = async (id) => {
    console.log(id)
    const myToast = toast.loading('Please Wait...')
    setLoading(true)
    try {
      let register = `${BASE_URL}/slider/${id}/delete`

      const response = await axios.delete(register,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        });
      console.log(response)
      toast.update(myToast, {
        render: response.data.message,
        type: 'success',
        isLoading: false,
        autoClose: 1500
      });

      props?.func()
    } catch (error) {
      toast.update(myToast, {
        render: error.message,
        type: 'error',
        isLoading: false,
        autoClose: 1500
      })
      console.error('Error deleting resource:', error);
    }
    finally {
      setLoading(false)
    }
  }
  const handleDelete2 = async (id) => {
    console.log(id)
    const myToast = toast.loading('Please Wait...')
    setLoading(true)
    try {
      let register = `${BASE_URL}/groupbuyslider/${id}/delete`

      const response = await axios.delete(register,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        });
      console.log(response)
      toast.update(myToast, {
        render: response.data.message,
        type: 'success',
        isLoading: false,
        autoClose: 1500
      });

      props?.func()
    } catch (error) {
      toast.update(myToast, {
        render: error.message,
        type: 'error',
        isLoading: false,
        autoClose: 1500
      })
      console.error('Error deleting resource:', error);
    }
    finally {
      setLoading(false)
    }
  }
  const handleDelete3 = async (id) => {
    console.log(id)
    const myToast = toast.loading('Please Wait...')
    setLoading(true)
    try {
      let register = `${BASE_URL}/clearanceslider/${id}/delete`

      const response = await axios.delete(register,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        });
      console.log(response)
      toast.update(myToast, {
        render: response.data.message,
        type: 'success',
        isLoading: false,
        autoClose: 1500
      });

      props?.func()
    } catch (error) {
      toast.update(myToast, {
        render: error.message,
        type: 'error',
        isLoading: false,
        autoClose: 1500
      })
      console.error('Error deleting resource:', error);
    }
    finally {
      setLoading(false)
    }
  }

  const heading = props?.data?.heading; // Assuming heading is a string

  // Check if heading exists before trying to capitalize
  const capitalizedHeading = heading ? heading.charAt(0).toUpperCase() + heading.slice(1) : '';

  return (
    <>
      <Card className='my-3'>
        <Card.Body className='d-flex justify-content-between align-items-center'>
          <Card.Text className='mb-0 fw-medium'>
            {capitalizedHeading}
            <p className='mb-0 fw-normal'>Sequence : {props?.data?.sequence}</p>
          </Card.Text>
          <div className='d-flex gap-2'>
            {props.banner && (
              <>
                <img src="/image/edit.png" style={{ cursor: "pointer" }} onClick={() => handleShow(props?.data?._id)} alt="edit" />
                <img src="/image/delete.png" style={{ cursor: "pointer" }} onClick={() => handleDelete(props?.data?._id)} alt="delete" /></>
            ) }
            {props.slider && (<>
              <img src="/image/edit.png" style={{ cursor: "pointer" }} onClick={() => handleShow1(props?.data?._id)} alt="edit" />
              <img src="/image/delete.png" style={{ cursor: "pointer" }} onClick={() => handleDelete1(props?.data?._id)} alt="delete" /></>)}
            {props.groupSlider && (<>
              <img src="/image/edit.png" style={{ cursor: "pointer" }} onClick={() => handleShow2(props?.data?._id)} alt="edit" />
              <img src="/image/delete.png" style={{ cursor: "pointer" }} onClick={() => handleDelete2(props?.data?._id)} alt="delete" /></>)}
            {props.clearance && (<>
              <img src="/image/edit.png" style={{ cursor: "pointer" }} onClick={() => handleShow3(props?.data?._id)} alt="edit" />
              <img src="/image/delete.png" style={{ cursor: "pointer" }} onClick={() => handleDelete3(props?.data?._id)} alt="delete" /></>)}
          </div>    
          </Card.Body>
      </Card>
      {show && <EditBanner isOpen={show} closeModal={closeModal} data={props?.data} func={props?.func} />}
      {show1 && <EditSlider isOpen={show1} closeModal={closeModal1} data={props?.data} func={props?.func} />}
      {show2 && <EditGroupBuySlider isOpen={show2} closeModal={closeModal2} data={props?.data} func={props?.func} />}
      {show3 && <EditClearanceSlider isOpen={show3} closeModal={closeModal3} data={props?.data} func={props?.func} />}
    </>
  )
}

export default CardSection