// NumberFormatUtils.js
import React from 'react';

export function FormatWithoutDoller(num) {
  if (num >= 100000) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + ' million';
    }
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  }
  return  num?.toLocaleString();
}
