import React, { useRef, useState } from 'react'
import classes from './ChatTextarea.module.css'
import { IoSendSharp } from 'react-icons/io5'
import { MdAdd } from 'react-icons/md'
import BlackButton from '../../BlackButton/BlackButton'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import { RxCross2 } from 'react-icons/rx'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL } from '../../../Apis/BaseUrl'

const ChatTextarea = ({ reqData }) => {
    const [myMessage, setMyMessage] = useState('')
    const [newMessage, setNewMessage] = useState("")
    const [file, setFile] = useState("")
    const [filename, setFileName] = useState("")
    const [loading, setLoading] = useState(null)
    const fileInputRef = useRef(null);

    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.access_token)
    let token = profileToken.token;
    const { id } = useParams()

    const handleFileChange = (e) => {


        const file = e.target.files[0];
        console.log(file);
        setFile(file)
        if (file) {
            const fileType = file.type;
            if (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'video/mp4') {

                const reader = new FileReader();

                reader.onload = (e) => {
                    console.log(e.target);
                    setFileName(e.target.result)
                };

                reader.readAsDataURL(file);

                fileInputRef.current.value = '';
            } else {
                toast.error('Please select jpeg,png or mp4 format')
            }
        }
    };

    const resetFunc = () => {
        setFileName(null)
        setFile(null)
    }
    // console.log(id)

    const sendData = async (e) => {
        e.preventDefault()
        const formData = new FormData();

        if (!newMessage && !file) {
            return toast.error('Either select a file or send a message')
        }

        formData.append('message', newMessage);
        formData.append('attachment', file);
        const register = `${BASE_URL}/ticket/${id}/respond`
        console.log(newMessage)
        // //console.log(formData, "ppppppppp")

        // const myToast = toast.loading('Please Wait...')
        setLoading(true)
        try {
            const response = await axios.put(register, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            })
          
            reqData()
        } catch (error) {
            console.error('Error while uploading data:', error);

        }
        finally {
            setNewMessage("")
            setFile(null)
            setLoading(false)
        }
    };

    return (
        <>
            <form
                onSubmit={sendData}
                className={classes.my_form}>
                <div className={classes.input_container}>
                    <input value={newMessage} onChange={e => setNewMessage(e.target.value)} type="text" placeholder={!file ? 'message...' : ''} />

                </div>
                {/* {file && <span className={classes.attach1}>{file.name}</span>} */}

                <div className={classes.icon_btn}>
                    {/* <MySVG1 src={input_add_icon} /> */}
                    <input type="file" onChange={handleFileChange} ref={fileInputRef} style={{ display: "none" }} id="file" />

                    <label htmlFor="file">
                        <MdAdd style={{ cursor: "pointer" }} />
                    </label>

                </div>
                <BlackButton type='submit' cls={`${classes.my_btn}`}><IoSendSharp /></BlackButton>
            </form>
            <div className={classes.file_show}>
                {file &&
                    <div className={classes.render_img}>
                        <img src={filename} alt="" />
                        <button type='button' className={classes.del_btn} onClick={resetFunc}><RxCross2 /></button>
                    </div>
                }
            </div>
        </>
    )
}

export default ChatTextarea
