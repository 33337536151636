import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import styles from './coupons.module.css'
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import ContainerSection from '../../Component/ContainerSection/ContainerSection'
import { Button, Col, Form } from 'react-bootstrap'
import CoupanModal from './CoupanModal/CoupanModal'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { BASE_URL } from '../../Apis/BaseUrl'
import axios from 'axios'
import NewPagination from '../../Component/NewPagination/NewPagination'
import Moment from 'react-moment'
import ToasterUpdate from '../../Component/Toaster/ToasterUpdate'
import { toast } from 'react-toastify'
import SearchBar from '../../Component/SearchBar/SearchBar'
import EditCoupan from './CoupanModal/EditCoupon'
import formatter from '../../Component/NumberFormatter/formatter'
import NotFound from '../../Component/NotFound/NotFound'


const Coupons = () => {
    const [activeStatus, setActiveStatus] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [editId, setEditId] = useState("")
    const [data, setData] = useState([])
    const [page, setPage] = useState(1)
    const [pageInfo, setPageInfo] = useState({})
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState('')

    const navigate = useNavigate()
    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;

    const getData = async () => {

        let register = `${BASE_URL}/coupon?limit=${limit}&page=${page}&search=${search}&status=${status}`

        // console.log(register)
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        // console.log(res.data.data.docs)
        setData(res.data.data.docs)
        setPageInfo({ ...res.data.data, docs: null })
    }

    useEffect(() => {
        getData()
    }, [page, limit, search, status])
    
useEffect(() => {
    setPage(1)
}, [limit]
)

    const paginationProps = {
        setPage,
        pageInfo, setLimit
    }

    const closeModal = () => {
        setIsOpen(false)
    }
    const openModal = () => {
        setIsOpen(true)
    }

    const handleAccept = async (id, item) => {
        // e.preventDefault()
        // console.log(id)
        let register = `${BASE_URL}/coupon/${id}/edit`
        const myToast = toast.loading('Please Wait...')
        // console.log(item)
        try {

            let res = await axios.put(register, { active: !item.active }, {
                // withCredentials : true,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token} `,
                },
            })

            if (!res.data.success) {
                ToasterUpdate(myToast, res.data.message, "error")
            }
            ToasterUpdate(myToast, res.data.message, "success")
            getData()
        } catch (error) {
            // console.log(error)
            ToasterUpdate(myToast, error.message, "error")
        }

    };

    const handleOpen = (id) => {
        setOpenEdit(true)
        setEditId(id)
    }
    const closeEditModal = () => {
        setOpenEdit(false)
    }

    const handleDelete = async (id) => {
        console.log(id)
        const myToast = toast.loading('Please Wait...')
        setLoading(true)
        try {
            let register = `${BASE_URL}/coupon/${id}/remove`

            const response = await axios.delete(register,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`
                    }
                });
            console.log(response)
            toast.update(myToast, {
                render: response.data.message,
                type: 'success',
                isLoading: false,
                autoClose: 1500
            });

            getData()
        } catch (error) {
            toast.update(myToast, {
                render: error.message,
                type: 'error',
                isLoading: false,
                autoClose: 1500
            })
            console.error('Error deleting resource:', error);
        }
        finally {
            setLoading(false)
        }
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>Coupons</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            {/* ----------Hero-Section----------- */}

            <div className={styles.hero_Section}>
                <ContainerSection />
                <div className={styles.filter_section}>
                    <div className={styles.filter}>
                        <p>Filters : </p>
                        <p>Status </p>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio"
                                name="flexRadioDefault" id="flexRadioDefault2"
                                value=""
                                onChange={(e) => setStatus(e.target.value)}
                                checked={status === ''} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                All
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                value="active"
                                onChange={(e) => setStatus(e.target.value)} checked={status === 'active'} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Active
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                value="inactive"
                                onChange={(e) => setStatus(e.target.value)} checked={status === 'inactive'} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Inactive
                            </label>
                        </div>
                    </div>
                </div>
                <div className={styles.mainSection}>

                    <div className='w-100'>
                        <Col className='d-flex justify-content-between align-items-center'>
                            <h6>Coupons</h6>
                            <div className={styles.btn_section}>

                                <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} style={{
                                    height: "40px",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    border: "1px solid #d9d9d9", outline: "none"
                                }} placeholder="Search..." required />


                                {isOpen && <CoupanModal getData={getData} isOpen={isOpen} closeModal={closeModal} />}
                                <Button onClick={openModal}>Add Coupon</Button>
                            </div>
                        </Col>


                    </div>
                </div>
               


                {/* -------------table_section-------------- */}
                {data.length > 0 ?
                    <>
                        <div className={styles.main_table}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        {/* <th scope="col">Sno</th> */}

                                        <th className='px-5'>Code</th>
                                        <th style={{width:"300px"}} >Coupon Label</th>
                                        <th >Type</th>
                                        <th>Start Date</th>
                                        <th >Expiry Date</th>
                                        <th> Status</th>
                                        <th> Discount</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((item) => (
                                        <tr>
                                            {/* <td>1</td> */}
                                            <td className='px-5' style={{ width: "150px" }}>{item.couponCode}</td>
                                            <td className='text-capitalize' style={{width:"300px"}}>{item.couponLabel}   </td>
                                            <td className='text-capitalize'>{item.couponType}   </td>
                                            <td>
                                                <Moment format="YYYY/MM/DD" utc>{item.updatedAt}</Moment>
                                            </td>
                                            <td>
                                                <Moment format="YYYY/MM/DD" utc>{item.expiryDate}</Moment>
                                            </td>
                                            <td>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" onChange={() => handleAccept(item._id, item)} checked={item.active} />
                                                </div>
                                            </td>

                                            <td>{item.discountType === "percentage" ? item.discountValue + "%" : formatter.format(item?.discountValue || 0)}</td>
                                            <td>
                                                <div className={styles.image_section}>
                                                    <img src="/image/edit.png" alt="edit" onClick={() => handleOpen(item._id)} />
                                                    <img src="/image/delete.png" alt="delete" onClick={() => handleDelete(item._id)} />
                                                </div>

                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <NewPagination {...paginationProps} />

                    </>
                    : <NotFound />}


            </div>
            {openEdit && <EditCoupan getData={getData} isOpen={openEdit} id={editId} closeModal={closeEditModal} />}
        </React.Fragment>
    )
}

export default Coupons