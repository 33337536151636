import React, { useState } from 'react'
import styles from './section.module.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../../Apis/BaseUrl';
import axios from 'axios';
import { fileChecker } from '../../../Component/FileChecker/fileChecker';
import Cookies from 'js-cookie';
import FileLabel from '../../../Component/FileLabel/FileLabel';

const BrandsModal = ({ isOpen, closeModal, data }) => {
    const [selectedImage, setSelectedImage] = useState(null);
const[name, setName] = useState('')
const[metaTitle, setMetaTitle] = useState('')
const[metaDescription, setMetaDescription] = useState('')
const[loading, setLoading] = useState(false)
const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
};
    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;

    const handleDataUpload = async () => {
        // alert('clicked')
        // console.log("yyyy")
        const formData = new FormData();
        formData.append('name', name);
        formData.append('meta_title', metaTitle);
        formData.append('meta_description', metaDescription);
        if (selectedImage) {
            let checkFile = fileChecker(selectedImage)
            if (!checkFile.value) {
                toast.error(checkFile.message)
                return 
            }
            formData.append('logo', selectedImage);
        }
        const register = `${BASE_URL}/brand`

        const myToast = toast.loading('Please Wait...')
        setLoading(true)
        try {
            const response = await axios.post(register, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            })

            console.log(response)
            if (!response.data.success) {
                throw new Error(response.data.message)
            }
            toast.update(myToast, {
                render: response.data.message,
                type: 'success',
                isLoading: false,
                autoClose: 1500
            });
            data()
        } catch (error) {
            console.error('Error while uploading data:', error);
        
            toast.update(myToast, {
                render: error.message,
                type: 'error',
                isLoading: false,
                autoClose: 1500
            });
        }
        finally {
            closeModal()
            setName("")
            setSelectedImage(null)
            setLoading(false)
        }
    };

    return (
        <React.Fragment>
            <Modal show={isOpen} onHide={closeModal}>
                <Modal.Header>
                    <Modal.Title style={{fontSize:"18px"}}>Add new Brand</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-3'>
                    {/* <div className={styles.main_section}>
                        <label for="name">Add new Brand</label>
                        <input type="text" id='name' />
                    </div> */}
                    <div className={styles.main_section}>
                        <label for="name2">Name</label>
                        <input type="text" value={name} onChange={(e)=> setName(e.target.value)} id='name2' />
                    </div>
                  
                    <div className={styles.main_section}>
                        <label for="Image">Upload Image </label>
                        {/* <input className='p-0' onChange={(e) => setSelectedImage(e.target.files[0])} type="file" id='Image' /> */}
                        <FileLabel  id="attachment" file_label={styles.file_label}  setter={handleImageChange}
                                            
                                            placeholder='upload logo' />
                    </div>
                    <div className={styles.main_section}>
                        <label for="name2">Meta Title</label>
                        <input type="text" value={metaTitle} onChange={(e)=> setMetaTitle(e.target.value)} id='name2' />
                        
                    </div>
                    <div className={styles.main_section}>
                        <label for="name2">Meta Description</label>
                        <input type="text" value={metaDescription} onChange={(e)=> setMetaDescription(e.target.value)} id='name2' />
                       
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                    <div className={styles.model_button}>
                        <Button className='bg-white text-black border' variant="secondary" onClick={closeModal}> Cancel  </Button>
                        <Button variant="primary" onClick={handleDataUpload}>  Save </Button>
                    </div>

                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default BrandsModal