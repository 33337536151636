import React, { useEffect, useState } from 'react'
import classes from './Pay.module.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import RatingCard from '../../../Component/Rating/RatingCard';
import Cookies from 'js-cookie';
import axios from 'axios';
import { BASE_URL } from '../../../Apis/BaseUrl';
import ToasterUpdate from '../../../Component/Toaster/ToasterUpdate';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import formatter from '../../../Component/NumberFormatter/formatter';
import { FormatNumber } from '../../../Component/FormatNumber/FormatNumber';


const PaymentModal = ({ isOpen, closeModal, id, func }) => {
    const[reply, setReply] = useState('')
    
    // console.log(data)
    const[data, setData] = useState({})

    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;

    const getData = async () => {
             // console.log(register)
        let res = await axios.get(`${BASE_URL}/payment/${id}/show`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token} `,
              },
        })
        console.log(res.data.data)
        setData(res.data.data)
        // setPageInfo({ ...res.data.data, docs: null })
      }

      useEffect(() => {
        getData()
    }, [])


    return (
        <React.Fragment>
            <Modal show={isOpen} onHide={closeModal}>
                <Modal.Title>

                    <div className={classes.header}>
                        <div>
                            <h6>Payment Details</h6>
                        </div>
                       
                    </div>
                    <hr className='mb-0' />
                </Modal.Title>

                <Modal.Body className={`${classes.bdy} mb-3`}>
                   <div className={classes.div_mdl}>
                    <p>Transaction ID</p>
                    <p>{data?.paymentRefNumber ? "#" + data?.paymentRefNumber : "---"}</p>
                   </div>
                 
                   <div className={classes.div_mdl}>
                    <p>Order ID</p>
                    <p>{data?.orderId ? "#" + data?.orderId : "---" }</p>
                   </div>
                 
                   {/* <div className={classes.div_mdl}>
                    <p>Seller name</p>
                    <p>{data?.seller}</p>
                   </div> */}
                   <div className={classes.div_mdl}>
                    <p>Customer name</p>
                    <p>{data?.userId?.name}</p>
                   </div>
                   <div className={classes.div_mdl}>
                    <p>Date of Payment</p>
                    <p><Moment format='YYYY/MM/DD'>{data?.date}</Moment></p>
                   </div>
                   <div className={classes.div_mdl}>
                    <p>Payment Method</p>
                    <p className='text-capitalize'>{data?.paymentMethod}</p>
                   </div>
                   <div className={classes.div_mdl}>
                    <p>Amount</p>
                    <p>
                        {FormatNumber(data?.amount || 0)}
                        {/* ${data?.amount} */}
                        </p>
                   </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={classes.model_button}>
                        <Button  variant="primary" onClick={closeModal}> Close  </Button>
                        {/* <Button variant="primary" onClick={handleAdd}>Pubish</Button> */}
                    </div>

                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default PaymentModal