import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import styles from './new.module.css';
import { IoMdAdd } from "react-icons/io";
import 'react-quill/dist/quill.snow.css';
import { Helmet } from 'react-helmet';
import DatePicker from "react-datepicker";
import { Col, Form, Row } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'

const AddNewProduct = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [editorContent, setEditorContent] = useState('');

    return (
        <React.Fragment>
            <Helmet>
                <title>Add New Product</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            {/* -----------------hero-section---------------- */}

            <div className={styles.hero_Section}>
                <Row className={styles.hero_row_Section}>
                    <Col md={8} sm={12}>
                        <div className={styles.product_info}>
                            <div className={styles.product_info_section}>
                                <h6>Product Information</h6>
                            </div>
                            <div className={styles.main_section}>
                                <Form>
                                    <div className={styles.input_container}>
                                        <Row className='w-100'>
                                            <Col md={5} sm={12}> <label for="inputField">Product Name:</label></Col>
                                            <Col md={7} sm={12}> <input type="text" id="inputField" name="inputField" placeholder='Product Name' /></Col>
                                        </Row>

                                    </div>
                                    <div className={styles.input_container}>
                                        <Row className='w-100'>

                                            <Col md={5} sm={12}> <label for="inputField_2">Brand:</label></Col>
                                            <Col md={7} sm={12}>    <input type="text" id="inputField_2" name="inputField" placeholder='Select Brand' /></Col>
                                        </Row>
                                    </div>
                                    <div className={styles.input_container}>
                                        <Row className='w-100'>
                                            <Col md={5} sm={12}> <label for="inputField_3">Unit:</label></Col>
                                            <Col md={7} sm={12}><input type="text" id="inputField_3" name="inputField" placeholder='Unit (e.g. KG, Pc etc)' /></Col>
                                        </Row>

                                    </div>
                                    <div className={styles.input_container}>
                                        <Row className='w-100'>
                                            <Col md={5} sm={12}><label for="weight">Weight (In Kg):</label></Col>
                                            <Col md={7} sm={12}><input type="number" id="weight" name="inputField" placeholder='0.00' /></Col>
                                        </Row>
                                    </div>
                                    <div className={styles.input_container} >
                                        <Row className='w-100'>
                                            <Col md={5} sm={12}><label for="inputField_5">Minimum Purchase Qty:</label></Col>
                                            <Col md={7} sm={12}> <input type="text" id="inputField_5" name="inputField" placeholder='1' /></Col>
                                        </Row>
                                    </div>

                                    <div className={styles.input_container} >
                                        <Row className='w-100'>
                                            <Col md={5} sm={12}><label for="inputField_6">Tags:</label></Col>
                                            <Col md={7} sm={12}>  <input type="text" id="inputField_6" name="inputField" placeholder='Type and hit enter to add a tag' /></Col>
                                        </Row>
                                    </div>
                                    <div className={styles.input_container} >
                                        <Row className='w-100'>
                                            <Col md={5} sm={12}> <label for="inputField_7">Barcode:</label></Col>
                                            <Col md={7} sm={12}>  <input type="text" id="inputField_7" name="inputField" placeholder='Barcode' /></Col>
                                        </Row>
                                    </div>


                                    <div className={`form-check form-switch ${styles.form_switch}`}>
                                        <Form.Check
                                            reverse
                                            className='d-flex justify-content-end ps-2 flex-row-reverse'
                                            label="Refundable :"
                                            type="switch"
                                            id="custom-switch"
                                        />
                                    </div>

                                </Form>
                            </div>
                        </div>

                        {/* ---------upload-section----------- */}

                        <div className={styles.uploadSection}>
                            <p>Product Images</p>
                            <hr />
                            <div className={styles.forminput}>
                                <label for="left">Gallery Images</label>
                                <input type="file" id="left" name="leftInput" />
                            </div>
                            <div className={styles.forminput}>
                                <label for="left2">Thumbnail Image <br />
                                    (290x300)</label>
                                <input type="file" id="left2" name="leftInput" />
                            </div>

                        </div>

                        <div className={styles.uploadSection}>
                            <p>Product Videos</p>
                            <hr />
                            <div className={styles.formvideo}>
                                <label for="leftInputVideo">Video Provider</label>
                                <input type="text" id="leftInputVideo" placeholder="Video Link" />
                            </div>
                            <div className={styles.formvideo}>
                                <label for="leftInputVideo2">Video Link</label>
                                <input type="text" id="leftInputVideo2" placeholder="Video Link" />
                            </div>


                        </div>

                        <div className={styles.uploadSection}>
                            <p>Product Variation</p>
                            <hr />
                            <div className={styles.colors_section}>
                                <div className={styles.child}>
                                    Colors
                                </div>
                                <input type="text" id="leftInput" className={styles.input_text} />
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                </div>

                            </div>

                            <div className={styles.attributes}>
                                <div className={styles.child}>
                                    Colors
                                </div>
                                <input type="text" id="leftInput" placeholder='Nothing selected' className={styles.input_tag} />

                            </div>

                        </div>

                        <div className={styles.product_section}>
                            <h6>Product price + stock</h6>
                            <Form>
                                <div className={styles.input_container}>
                                    <label for="inputField">Unit price</label>
                                    <input type="text" id="inputField" name="inputField" placeholder='0' />
                                </div>
                                <div className={`${styles.discount_input} ${styles.input_container}`}>
                                    <label for="inputField">Discount Date Range</label>
                                    <DatePicker format="y/MM/dd" selected={startDate} onChange={(date) => setStartDate(date)} />
                                </div>
                                <div className={`${styles.discount_input} ${styles.input_container}`}>
                                    <label for="inputField">Discount</label>
                                    <input type="text" id="inputField" name="inputField" placeholder='0' />
                                    <input type="text" id="inputField" name="inputField" />
                                </div>
                                <div className={`${styles.discount_input} ${styles.input_container}`}>
                                    <label for="inputField">Quantity</label>
                                    <input type="text" id="inputField" name="inputField" placeholder='0' />
                                </div>
                                <div className={`${styles.discount_input} ${styles.input_container}`}>
                                    <label for="inputField">SKU</label>
                                    <input type="text" id="inputField" name="inputField" placeholder='SKU' />
                                </div>
                                <div className={`${styles.discount_input} ${styles.input_container}`}>
                                    <label for="inputField">External link</label>
                                    <input type="text" id="inputField" name="inputField" placeholder='External link' />
                                </div>
                            </Form>
                        </div>

                        <div className={styles.descrption_section}>
                            <h6>Product Description</h6>
                            <div>
                                <ReactQuill
                                    theme="snow" // Choose a theme ('snow' or 'bubble')
                                    value={editorContent}
                                    onChange={setEditorContent}
                                />
                            </div>
                        </div>

                        <div className={styles.specification}>
                            <h6>PDF Specification</h6>
                            <div className='pt-3'>
                                <label for="pdf">PDF Specification</label>
                                <input type="file" id='pdf' />
                            </div>
                        </div>

                        <div className={styles.specification}>
                            <h6>SEO Meta Tags</h6>
                            <div className={styles.meta}>
                                <label for="meta_tags">Meta Tittle</label>
                                <input type="text" id='meta_tags' placeholder='Meta Title' />
                            </div>
                            <div className={styles.meta}>
                                <label for="meta_tags">Description</label>
                                <textarea type="text" id='meta_tags' rows="4" cols="50" placeholder='Meta Title'></textarea>
                            </div>
                            <div className={`${styles.meta_image} ${styles.meta}`}>
                                <label for="image">Meta Image</label>
                                <input type="file" id='image' />
                            </div>
                        </div>


                    </Col>

                    {/* --------------Right-Section--------------- */}

                    <Col md={4} sm={12}>
                        <div className={styles.category_section}>
                            <div className={styles.child_category}>
                                <h5>Product Category</h5>
                                <h6>Select Main</h6>
                            </div>
                            <hr className='m-0' />
                            <div className='p-3'>
                                <div className={styles.section}>
                                    <div className='d-flex gap-2'>
                                        <IoMdAdd />
                                        <input type="checkbox" />
                                        <p>caulk-adhesive</p>
                                    </div>
                                    <input type="radio" />
                                </div>

                                <div className={styles.section}>
                                    <div className='d-flex gap-2'>
                                        <IoMdAdd />
                                        <input type="checkbox" />
                                        <p>Bathroom, Kitchen & Storage</p>
                                    </div>
                                    <input type="radio" />
                                </div>
                                <div className={styles.section}>
                                    <div className='d-flex gap-2'>
                                        <IoMdAdd />
                                        <input type="checkbox" />
                                        <p>concrete-cement masonry</p>
                                    </div>
                                    <input type="radio" />
                                </div>
                                <div className={styles.section}>
                                    <div className='d-flex gap-2'>
                                        <IoMdAdd />
                                        <input type="checkbox" />
                                        <p>Doors</p>
                                    </div>
                                    <input type="radio" />
                                </div>
                            </div>

                        </div>

                        <div className={`${styles.category_section} ${styles.shipping_section}`}>
                            <div className={styles.child_category}>
                                <h5>Shipping Configuration</h5>
                            </div>
                            <hr className='m-0' />
                            <div className='p-3'>
                                <div className={`form-check form-switch ${styles.form_switch_shipping}`}>
                                    <Form.Check
                                        reverse
                                        label="Free Shipping"
                                        type="switch"
                                        id="custom-switch-1"
                                    />
                                </div>
                                <div className={`form-check form-switch ${styles.form_switch_shipping}`}>
                                    <Form.Check
                                        reverse
                                        label="Flat Rate"
                                        type="switch"
                                        id="custom-switch-2"
                                    />
                                </div>
                                <div className={`form-check form-switch ${styles.form_switch_shipping}`}>
                                    <Form.Check
                                        reverse
                                        label="Is Product Quantity"
                                        type="switch"
                                        id="custom-switch-3"
                                    />
                                </div>

                            </div>

                        </div>

                        <div className={`${styles.category_section} ${styles.shipping_section}`}>
                            <div className={styles.child_category}>
                                <h5>Category</h5>
                            </div>
                            <hr className='m-0' />
                            <div className='p-3'>
                                <div className={`form-check form-switch ${styles.form_switch_shipping}`}>
                                    <Form.Check
                                        reverse
                                        label="Group Buy"
                                        type="switch"
                                        id="custom-switch-4"
                                    />
                                </div>
                                <div className={`form-check form-switch ${styles.form_switch_shipping}`}>
                                    <Form.Check
                                        reverse
                                        label="Instabuild"
                                        type="switch"
                                        id="custom-switch-5"
                                    />
                                </div>

                            </div>

                        </div>

                        <div className={`${styles.category_section} ${styles.shipping_section}`}>
                            <div className={styles.child_category}>
                                <h5>Low Stock Quantity Warning</h5>
                            </div>

                            <hr className='m-0' />

                            <div className={styles.quality_section}>
                                <label for='Quantity'>Quantity</label>
                                <input type="tel" id="Quantity" name="leftInput" placeholder='0' />
                            </div>

                        </div>

                        <div className={`${styles.category_section} ${styles.shipping_section}`}>
                            <div className={styles.child_category}>
                                <h5>Stock Visibility State</h5>
                            </div>
                            <hr className='m-0' />
                            <div className='p-3'>
                                <div className={`form-check form-switch ${styles.form_switch_shipping}`}>
                                    <Form.Check
                                        reverse
                                        label="Show Stock Quantity"
                                        type="switch"
                                        id="custom"
                                    />
                                </div>
                                <div className={`form-check form-switch ${styles.form_switch_shipping}`}>
                                    <Form.Check
                                        reverse
                                        label="Show Stock With file  id='pdf'
                                        Only"
                                        type="switch"
                                        id="custom-1"
                                    />
                                </div>
                                <div className={`form-check form-switch ${styles.form_switch_shipping}`}>
                                    <Form.Check
                                        reverse
                                        label="Hide Stock"
                                        type="switch"
                                        id="custom-2"
                                    />
                                </div>

                            </div>

                        </div>

                        <div className={`${styles.category_section} ${styles.shipping_section}`}>
                            <div className={styles.child_category}>
                                <h5>Stock Visibility State</h5>
                            </div>
                            <hr className='m-0' />
                            <div className='p-3'>
                                <div className={`form-check form-switch ${styles.form_switch_shipping}`}>
                                    <Form.Check
                                        reverse
                                        label="Status"
                                        type="switch"
                                        id="custom-switch-6"
                                    />
                                </div>

                            </div>

                        </div>

                        <div className={styles.shipping_time}>
                            <h6>Estimate Shipping Time</h6>

                            <label for="days">Shipping Days</label>
                            <div className={styles.days}>
                                <input type="text" id='days' placeholder='Shipping Days' />
                                <button>Days</button>
                            </div>

                        </div>

                        <div className={styles.vat_section}>
                            <h6>  Vat & TAX</h6>

                            <label for='value'>Tax</label>
                            <div className={styles.vat_chlid}>
                                <input type="text" placeholder='0' id='value' />
                                <select className="form-select" aria-label="Default select example">
                                    <option selected>Flat</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>

                            <label for='value'>Vat</label>
                            <div className={styles.vat_chlid}>
                                <input type="text" placeholder='0' id='value' />
                                <select className="form-select" aria-label="Default select example">
                                    <option selected>Flat</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>

                        </div>

                    </Col>
                </Row>
            </div>

        </React.Fragment>
    )
}

export default AddNewProduct