// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flash_hero_Section__9QNxY {
    padding: 30px 40px;
}

.flash_product_review_section__t7Tv4 {
    margin-top: 20px;

    & h6 {
        font-family: Inter;
        font-size: 1.1rem;
        font-weight: 500;
    }
}



/* ----------main-table------------ */

.flash_main_table__fa01H {
    margin-top: 30px;
}

.flash_main_table__fa01H table thead {
    border: none;
    border-radius: 6px;
}

.flash_main_table__fa01H table tbody {
    border-radius: 4px;
    border: 1px solid #d9d9d9;
}

.flash_main_table__fa01H table thead tr th {
    border-bottom: none;
    font-family: Inter;
    font-size: 0.97rem;
    font-weight: 500;
    white-space: normal;

}


.flash_main_table__fa01H table tbody tr td {
    white-space: normal;
    font-family: Inter;
    font-size: 0.97rem;
    font-weight: 500;
    height: auto;


}

.flash_image_section__rrpsu img {
    width: 35px;
    height: 35px;
    margin-left: 5px;
    cursor: pointer;
}


/* ----------media-queries----------- */

@media screen and (max-width : 568px) {
    .flash_hero_Section__9QNxY {
        padding: 30px 10px;
    }

    .flash_main_table__fa01H {
        width: 100%;
        overflow-x: scroll;
    }

    .flash_image_section__rrpsu {
        display: flex;
        gap: 5px;
    }

}`, "",{"version":3,"sources":["webpack://./src/Pages/FlashDetails/flash.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;;IAEhB;QACI,kBAAkB;QAClB,iBAAiB;QACjB,gBAAgB;IACpB;AACJ;;;;AAIA,qCAAqC;;AAErC;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;;AAEvB;;;AAGA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;;;AAGhB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;;AAGA,uCAAuC;;AAEvC;IACI;QACI,kBAAkB;IACtB;;IAEA;QACI,WAAW;QACX,kBAAkB;IACtB;;IAEA;QACI,aAAa;QACb,QAAQ;IACZ;;AAEJ","sourcesContent":[".hero_Section {\n    padding: 30px 40px;\n}\n\n.product_review_section {\n    margin-top: 20px;\n\n    & h6 {\n        font-family: Inter;\n        font-size: 1.1rem;\n        font-weight: 500;\n    }\n}\n\n\n\n/* ----------main-table------------ */\n\n.main_table {\n    margin-top: 30px;\n}\n\n.main_table table thead {\n    border: none;\n    border-radius: 6px;\n}\n\n.main_table table tbody {\n    border-radius: 4px;\n    border: 1px solid #d9d9d9;\n}\n\n.main_table table thead tr th {\n    border-bottom: none;\n    font-family: Inter;\n    font-size: 0.97rem;\n    font-weight: 500;\n    white-space: normal;\n\n}\n\n\n.main_table table tbody tr td {\n    white-space: normal;\n    font-family: Inter;\n    font-size: 0.97rem;\n    font-weight: 500;\n    height: auto;\n\n\n}\n\n.image_section img {\n    width: 35px;\n    height: 35px;\n    margin-left: 5px;\n    cursor: pointer;\n}\n\n\n/* ----------media-queries----------- */\n\n@media screen and (max-width : 568px) {\n    .hero_Section {\n        padding: 30px 10px;\n    }\n\n    .main_table {\n        width: 100%;\n        overflow-x: scroll;\n    }\n\n    .image_section {\n        display: flex;\n        gap: 5px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero_Section": `flash_hero_Section__9QNxY`,
	"product_review_section": `flash_product_review_section__t7Tv4`,
	"main_table": `flash_main_table__fa01H`,
	"image_section": `flash_image_section__rrpsu`
};
export default ___CSS_LOADER_EXPORT___;
