import React, { useEffect, useRef, useState } from 'react'
import styles from './edit.module.css'
import { Button, Col, Form, Row } from 'react-bootstrap'
import TopNavbar from '../../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../../Component/NavbarSection/NavbarSection'
import { Helmet } from 'react-helmet'
import ToasterUpdate from '../../../Component/Toaster/ToasterUpdate'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../../Apis/BaseUrl'
import { useNavigate, useParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import { fileChecker } from '../../../Component/FileChecker/fileChecker'
import axios from 'axios'
import FileLabel from '../../../Component/FileLabel/FileLabel'
import FallProduct from '../../../Component/FallbackImgae/FallProduct'
import JoditEditor from 'jodit-react';


const EditBlogs = () => {
    const [title, setTitle] = useState('')
    const [slug, setSlug] = useState('')
    const [shortDesc, setShortDesc] = useState('')
    const [description, setDescription] = useState('')
    const [category, setCategory] = useState('')
    const [metaTitle, setMetaTitle] = useState('')
    const [metaDescription, setMetaDescription] = useState('')
    const [metaKeywords, setMetaKeywords] = useState('')
    const [selectedImage, setSelectedImage] = useState(null)
    const [file, setFile] = useState("")
    const [loading, setLoading] = useState(false)
    const [selectedOption, setSelectedOption] = useState('');
    const [youtubeUrl, setYoutubeUrl] = useState('')
    const editor = useRef(null);
	const [content, setContent] = useState('');

    // const [value, setValue] = useState(RichTextEditor.createEmptyValue());

 
    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;

    const { id } = useParams()

    const navigate = useNavigate()

    const getDataById = async () => {

        let register = `${BASE_URL}/blog/${id}`
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        console.log(res.data.data)
        setTitle(res.data.data.title)
        setSlug(res.data.data.slug)
        setShortDesc(res.data.data.short_description)
        setDescription(res.data.data.description)
        setSelectedOption(res.data.data.contentType)
        setCategory(res.data.data.category)
        setMetaTitle(res.data.data.meta_title)
        setMetaDescription(res.data.data.meta_description)
        setMetaKeywords(res.data.data.meta_keywords)
        setFile(res.data.data.bannerUrl)
        setYoutubeUrl(res.data.data.bannerUrl || "")
        // setValue(RichTextEditor.createValueFromString(res.data.data.description, 'html'));

    }
    useEffect(() => {
        getDataById()
    }, [])

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
    };
    const handleEdit = async (e) => {
        e.preventDefault()
        // const plainTextContent = (new DOMParser().parseFromString(value.toString('html'), 'text/html')).documentElement.textContent;
        const formData = new FormData();
        formData.append('title', title);
        formData.append('slug', slug);
        formData.append('short_description', shortDesc);
        formData.append('description', description);
        formData.append('category', category);
        formData.append('meta_title', metaTitle);
        formData.append('meta_description', metaDescription);
        formData.append('meta_keywords', metaKeywords);
        formData.append('contentType', selectedOption);

        if (selectedOption == "image") {
            // console.log("yes")
            let checkFile = fileChecker(selectedImage)
            if (!checkFile.value) {
                toast.error(checkFile.message)
                return
            }
            formData.append('banner', selectedImage);
        } else {
            // console.log("no")
            formData.append('banner', youtubeUrl);
            // console.log(youtubeUrl)
        }
        // if (selectedImage) {
        //     let checkFile = fileChecker(selectedImage)
        //     if (!checkFile.value) {
        //         toast.error(checkFile.message)
        //         return
        //     }
        //     formData.append('banner', selectedImage);
        // }

        console.log(selectedImage)
        let register = `${BASE_URL}/blog/${id}`
        const myToast = toast.loading('Please Wait...')
        setLoading(true)
        try {

            let res = await axios.put(register, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token} `,
                },
            })
            console.log(res)
            if (!res.data.success) {
                ToasterUpdate(myToast, res.data.message, "error")
                return
            }
            ToasterUpdate(myToast, res.data.message, "success")
            // getData()
            navigate('/blogs')
        } catch (error) {
            // console.log(error)
            ToasterUpdate(myToast, error.message, "error")
        }
        finally {
            setLoading(false)
            setSelectedImage(null)

        }
    };


    return (
        <React.Fragment>
            <Helmet>
                <title>Edit Blogs</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />
            {/* -----------------hero-section---------------- */}

            <div className={styles.hero_Section}>

                <Row className={styles.categories}>
                    <Col className='mx-auto' md={9}>
                        <h6 className='mb-4'>Edit Blog <a href='https://visual-build.netlify.app/blogs' target='_blank' style={{ textDecoration: "none" }}>(visit Blog )</a></h6>
                        <div className={styles.product_info}>
                            <div className={styles.product_info_section}>
                                <h6>Basic Info</h6>
                            </div>
                            <div className={styles.main_section}>
                                <Form>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="inputField"> Blog Title *</label>
                                        <input type="text" id="inputField_3" value={title}
                                            onChange={(e) => setTitle(e.target.value)} required placeholder='Filon Asset Store' />
                                    </div>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="category">Category</label>
                                        <Form.Select required aria-label="Default select example" value={category} onChange={(e) => setCategory(e.target.value)} >

                                            <option value="tip">Tip</option>
                                            <option value="trend">Trend</option>
                                            <option value="growth">Growth</option>
                                            <option value="review">Review</option>
                                        </Form.Select>
                                        {/* <input type="text" id="category"
                                            value={category}
                                            onChange={(e) => setCategory(e.target.value)} required placeholder='Filon Asset Store' /> */}
                                    </div>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="Slug">Slug</label>
                                        <input type="text" value={slug}
                                            onChange={(e) => setSlug(e.target.value)} id="Slug" required placeholder='Filon Asset Store' />
                                    </div>
                                    <Form.Group className={`${styles.file}`} controlId="formFile">
                                        <Form.Label>Banner</Form.Label>
                                        {/* {selectedOption === '' && (  */}
                                        <Form.Select value={selectedOption} className={styles.select1} onChange={handleOptionChange} required aria-label="Default select example">
                                            <option value="">Select an option</option>
                                            <option value="youtube">Video (YouTube URL)</option>
                                            <option value="image">File</option>
                                        </Form.Select>
                                    </Form.Group>
                                  
                                        {selectedOption === 'youtube' && (
                                              <Form.Group className={`${styles.file}`} controlId="formFile">
                                              <Form.Label>YouTube Url</Form.Label>
                                            <div className={styles.vidio}>
                                                {/* <label htmlFor="videoUrl">YouTube URL:</label> */}
                                                <input type="text" style={{
                                                    height: "45px",
                                                    border: "1px solid #d9d9d9",
                                                    borderRadius: "5px", outline: "none", color: "#989898"
                                                }} className="w-100 px-3" value={youtubeUrl} onChange={(e) => setYoutubeUrl(e.target.value)} id="videoUrl" name="videoUrl" />
                                            </div>
                                            </Form.Group>
                                        )}
                                
                                    {/* )} */}

                                    <Form.Group className={`${styles.file}`} controlId="formFile">
                                        {selectedOption === 'image' && (
                                            (<> <Form.Label>Upload File:</Form.Label>
                                                <div className={styles.vidio}>
                                                    {/* <label htmlFor="file">Upload File:</label> */}
                                                    <div className={`${styles.img_banner} ${styles.im11}`}>
                                                        <FileLabel cls={styles.cls_width2} id="attachment" setter={handleImageChange}
                                                            //  onChange={(e) => setSelectedImage(e.target.files[0])} 
                                                            placeholder='upload logo' />
                                                        <FallProduct imgData={file} alyt="logo" />
                                                    </div>
                                                </div> </>)
                                        )}
                                    </Form.Group>
                                    {/* <input type="file" id="file" name="file"
                                         style={{
                                         border: "1px solid #d9d9d9",
                                         borderRadius: "5px"}}  onChange={(e) => setSelectedImage(e.target.files[0])} className='w-100 p-2' /> */}
                                    {/* <div className={`${styles.img_banner}`}>
                                            <FileLabel cls={styles.cls_width} id="attachment"  setter={handleImageChange} 
                                            //  onChange={(e) => setSelectedImage(e.target.files[0])} 
                                             placeholder='upload logo' />
                                            <img src={file} alyt="logo" />
                                        </div> */}


                                    {/* <div className={`${styles.file}`}>
                                        <label for="inputField_2">Banner</label>
                                        <div className={styles.img_banner}>
                                            <input type="file" id="inputField_2" 
                                        onChange={(e) => setSelectedImage(e.target.files[0])}  placeholder='upload logo' />
                                            <img src={file} alt="logo" />
                                        </div>

                                    </div> */}

                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="Description">Short Description *</label>
                                        <input type="text" id="Short Description" value={shortDesc}
                                            onChange={(e) => setShortDesc(e.target.value)} required placeholder='enter Short Description' />
                                    </div>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="Description"> Body *</label>
                          <div className={styles.jodit}>
                          <JoditEditor
			ref={editor}
			value={description}
			tabIndex={1} // tabIndex of textarea
			onBlur={newContent => setDescription(newContent)} // preferred to use only this option to update the content for performance reasons
			onChange={newContent => {}}
		/>
                          </div>
                                        {/* <RichTextEditor className={styles.edit}
                                            value={value}
                                            onChange={handleChange}
                                        /> */}
                                        {/* <textarea id="Description" value={description} onChange={(e) => setDescription(e.target.value)} required placeholder='Body' /> */}

                                        {/* <input type="text" id="Description" value={description}
                                            onChange={(e) => setDescription(e.target.value)} required placeholder='Description' /> */}
                                    </div>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="Title">Meta Title</label>
                                        <input type="address" id="Title" value={metaTitle}
                                            onChange={(e) => setMetaTitle(e.target.value)} required placeholder='enter meta title' />
                                    </div>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="inputField_5">Meta Description</label>
                                        <input type="text" id="inputField_5" value={metaDescription}
                                            onChange={(e) => setMetaDescription(e.target.value)} required placeholder='enter meta description' />
                                    </div>
                                    {/* <div className={`${styles.input_container_unit}`}>
                                        <label for="meta">Meta Description</label>
                                        <input type="text" id="meta" value={title}
                                        onChange={(e) => setTitle(e.target.value)}  required placeholder='It is a long established' />
                                    </div> */}
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="meta1">Meta  keywords</label>
                                        <input type="text" value={metaKeywords}
                                            onChange={(e) => setMetaKeywords(e.target.value)} id="meta1" required placeholder='enter meta keywords' />
                                    </div>

                                    <div className={styles.model_button}>
                                        <button className='bg-white text-black border' variant="secondary"  onClick={()=>navigate('/blogs')}> Cancel  </button>
                                        <button onClick={handleEdit} variant="primary" disabled={loading}> Save </button>
                                    </div>

                                </Form>


                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default EditBlogs