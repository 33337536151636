import React, { useEffect, useState } from 'react'
import styles from './modal.module.css'
import { Button, Form, Modal } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { BASE_URL } from '../../../Apis/BaseUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import ToasterUpdate from '../../../Component/Toaster/ToasterUpdate';
import Moment from 'react-moment';
import moment from 'moment';
import AsyncSelectData from '../../../Component/AsyncSelectData/AsyncSelectData';

const EditCoupan = ({ isOpen, getData, closeModal, id }) => {
    
    const[loading, setLoading] = useState(false)
    const[categoryData, setCategoryData] = useState([])
    const[productData, setProductData] = useState([])
    const[search, setSearch] = useState('')
    const[labelName, setLableName] = useState('')
    const[code, setCode] = useState('')
    const[couponType, setCouponType] = useState('category')
    const[applicableOptions, setApplicableOptions] = useState('')
    const[dateValue, setDateValue] = useState('')
    const[discountType, setDiscountType] = useState('percentage')
    const[discount, setDiscount] = useState('')
    const[maxDiscount, setMaxDiscount] = useState('')
    const[minAmount, setMinAmount] = useState('')
    const [selectData, setSelectData] = useState([])
    const [currentCategory, setCurrentCategory] = useState({})
    const [currentProduct, setCurrentProduct] = useState({})
    const [objectValue, setObjectValue] = useState("category")
const[currentValue, setCurrentValue] = useState({})

    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    let token = profileToken.token;
    const obj = {
        category: {
            url: "category",
            state: currentCategory,
            stateHandler: setCategoryData,
            placeholder: "select category"
        },
        product: {
            url: "product",
            state: currentProduct,
            stateHandler: (data) => setCurrentProduct(data),
            placeholder: "select product"
        }
    }

    const getCoupenData = async () => {
   let res = await axios.get(`${BASE_URL}/coupon/${id}/show`, {
       headers: {
           "Content-Type": "application/json",
           Authorization: `Bearer ${token} `,
         },
   })
   console.log(res.data.data)
   setLableName(res.data.data.couponLabel)
   setCode(res.data.data.couponCode)
   setCouponType(res.data.data.couponType)
   setApplicableOptions(res.data.data.applicableCategory)
   const formattedDate = moment(res.data.data.expiryDate).format('YYYY-MM-DD');
   console.log(formattedDate)
   setDateValue(formattedDate)
   setDiscountType(res.data.data.discountType)
   setDiscount(res.data.data.discountValue)
   setMinAmount(res.data.data.minimumAmount)
   setMaxDiscount(res.data.data.maxDiscount)
   let resData =  {
    value: res.data.data._id,
    label: res.data.data.applicableCategory}
//    res?.data?.data?.docs?.map(item => (
   
// }));
console.log(resData)
setCurrentValue(resData)
 }

 useEffect(() => {
    getCoupenData()
}, [])


const getCategoryData = async (query) => {
    let res = await axios.get(`${BASE_URL}/${obj[objectValue]?.url ? obj[objectValue]?.url : 'category'}?limit=10&page=1&search=${query}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token} `,
        },
    })
    let resData = res?.data?.data?.docs?.map(item => ({
        value: item._id,
        label: item.name
    }));
    setSelectData(resData)
}

const selectProps = {
    placeholder: obj[objectValue]?.placeholder,
    data: selectData,
    dataHandler: getCategoryData,
    valueHandler: obj[objectValue]?.stateHandler,
    currentValue
}
useEffect(() => {
    if (couponType !== "global") {
        setObjectValue(couponType)

    }
}, [couponType])

//     const getCategoryData = async () => {
//         // console.log(register)
//    let res = await axios.get(`${BASE_URL}/category?limit=10&page=1&search=${search}`, {
//        headers: {
//            "Content-Type": "application/json",
//            Authorization: `Bearer ${token} `,
//          },
//    })
//    console.log(res.data.data)
//    setCategoryData(res.data.data?.docs)
//    // setPageInfo({ ...res.data.data, docs: null })
//  }

//  useEffect(() => {
//    getCategoryData()
// }, [search])

//     const getProductData = async () => {
//         // console.log(register)
//    let res = await axios.get(`${BASE_URL}/product?limit=10&page=1&search=${search}`, {
//        headers: {
//            "Content-Type": "application/json",
//            Authorization: `Bearer ${token} `,
//          },
//    })
//    console.log(res.data.data)
//    setProductData(res.data.data?.docs)
//    // setPageInfo({ ...res.data.data, docs: null })
//  }

//  useEffect(() => {
//    getProductData()
// }, [search])

const handleAdd = async (e) => {
    e.preventDefault()
let bdy = {
    couponCode: code,
    couponLabel: labelName,
    expiryDate: dateValue,
    couponType: couponType, // 'category', 'product', 'global'
    minimumAmount: minAmount,
    discountType: discountType,       // percentage, fixed
    discountValue: discount,
    maxDiscount: maxDiscount,
  }
  if (couponType === 'category') {
    bdy.applicableCategory =  currentCategory?.value;; // Add category field
  } else if (couponType === 'product') {
    bdy.applicableProduct =  currentProduct?.value;; // Add product field
  }
 let register = `${BASE_URL}/coupon/${id}/edit`
    const myToast = toast.loading('Please Wait...')
    setLoading(true)
    try {

        let res = await axios.put(register, bdy, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token} `,
            },
        })
        console.log(res)
        if (!res.data.success) {
            ToasterUpdate(myToast, res.data.message, "error")
        }
        ToasterUpdate(myToast, res.data.message, "success")
      getData()
    } catch (error) {
        ToasterUpdate(myToast, error.message, "error")
    }
    finally {
        setLoading(false)
       closeModal()
    }

}
   
    return (
        <React.Fragment>
          
            <Modal show={isOpen} onHide={closeModal}>
                <Modal.Header>
                    <Modal.Title style={{fontSize:"18px"}}>Edit Coupon</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-3'>
                    <div className={styles.main_section_name}>
                        <div className='d-flex flex-column w-100'>
                            <label for="name">Coupon Label</label>
                            <input type="text" className='px-2' style={{fontSize:"14px", color:"#989898"}} value={labelName} onChange={(e) => setLableName(e.target.value)} id='name' />
                        </div>
                        <div className='d-flex flex-column w-100'>
                            <label for="name">Coupon code</label>
                            <input type="text" className='px-2' style={{fontSize:"14px", color:"#989898"}} value={code} onChange={(e) => setCode(e.target.value)} id='name' />
                        </div>
                      
                    </div>
                    <div className={styles.main_section_name}>
                    <div className='d-flex flex-column w-100'>
                            <label for="name">Coupon Type</label>
                            <Form.Select aria-label="Default select example" value={couponType} onChange={(e) => setCouponType(e.target.value)}>
                                {/* <option>Select Product</option> */}
                                <option value="category" selected>Category</option>
                                <option value="product">Product</option>
                                <option value="global">Global</option>
                            </Form.Select>
                        </div>
                    {couponType != "global" && 
                        <div className='d-flex flex-column w-100'>
                        <label for="name">Applicable Options</label>
                        <AsyncSelectData {...selectProps} />
                        {/* <Form.Select aria-label="Default select example" value={applicableOptions} onChange={(e) => setApplicableOptions(e.target.value)}>
                        {couponType == "product" ? productData.map((item, index) => (
                                            <option key={index} value={item._id} selected>{item.name} </option>
                                        )) : categoryData.map((item, index) => (
                                            <option key={index} value={item._id} selected>{item.name}</option>
                                        )) }
                        </Form.Select> */}
                    </div>}
                      
                    </div>

                    <div className={styles.main_section}>
                        <label for="name2">Expiry Date</label>
                        <input type="date" style={{fontSize:"14px", color:"#989898"}} value={dateValue} onChange={(e) => setDateValue(e.target.value)} id='name2' />
                    </div>
                    <div className={`${styles.main_section} flex-row`}>
                    <div className='d-flex flex-column w-100'>
                        <label for="meta">Discount Type</label>
                        <Form.Select aria-label="Default select example" style={{fontSize:"14px", color:"#989898", height:"40px"}} value={discountType} onChange={(e) => setDiscountType(e.target.value)}>
                                
                                <option value="percentage" selected>Percentage</option>
                                <option value="fixed">Fixed</option>
                                
                            </Form.Select>
                            </div>
                          {
                            discountType != "fixed" &&   <div className='d-flex flex-column w-100'>
                            <label for="name">Max Discount</label>
                            <input type="number" style={{fontSize:"14px", color:"#989898"}} value={maxDiscount} onChange={(e) => setMaxDiscount(e.target.value)} id='name' />
                        </div>
                          }
                    </div>
                    <div className={styles.main_section}>
                            <label for="name">Minimum Order Value</label>
                            <input type="number" style={{fontSize:"14px", color:"#989898"}} value={minAmount} onChange={(e) => setMinAmount(e.target.value)} id='name' />
                        </div>
                    <div className={styles.main_section}>
                            <label for="name">Discount</label>
                            <input value={discount} style={{fontSize:"14px", color:"#989898"}} onChange={(e) => setDiscount(e.target.value)} type="number" id='name' />
                        </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={styles.model_button}>
                        <button className='bg-white text-black border' variant="secondary" onClick={closeModal}> Cancel  </button>
                        <button variant="primary" onClick={handleAdd}>  Save </button>
                    </div>

                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default EditCoupan