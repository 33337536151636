// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pay_div_mdl__48eFv {
    display: flex;
    gap: 2%;
}
.Pay_div_mdl__48eFv  p:nth-child(1){
    width: 40%;
    font-size: 16px;
    color: black;
    font-weight: 500;
}
.Pay_div_mdl__48eFv  p:nth-child(2){
    width: 60%;
    font-size: 16px;
    font-weight: 500;
    color: #989898;
}

.Pay_header__vHSmU {
    display: flex;
    justify-content: space-between;
    margin: 20px 20px 0;


    & h6 {
        font-family: Inter;
        font-size: 1.1rem;
        font-weight: 600;
    }
}



.Pay_model_button__dypde {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    & button {
        display: flex;
        width: 87px;
        height: 39px;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border: none;
        color: #FFF;
        font-family: Inter;
        font-size: 0.8rem;
        font-weight: 500;
        border-radius: 3px;
        background-color: var(--main-color);

    }
}

.Pay_model_button__dypde button:hover {
    color: #FFF;
    background: var(--main-color);
}
.Pay_bdy__L1l2T{
    padding: 20px 20px 0 20px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Payments/PayModal/Pay.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,OAAO;AACX;AACA;IACI,UAAU;IACV,eAAe;IACf,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,UAAU;IACV,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;;;IAGnB;QACI,kBAAkB;QAClB,iBAAiB;QACjB,gBAAgB;IACpB;AACJ;;;;AAIA;IACI,aAAa;IACb,yBAAyB;IACzB,SAAS;;IAET;QACI,aAAa;QACb,WAAW;QACX,YAAY;QACZ,kBAAkB;QAClB,uBAAuB;QACvB,mBAAmB;QACnB,SAAS;QACT,YAAY;QACZ,WAAW;QACX,kBAAkB;QAClB,iBAAiB;QACjB,gBAAgB;QAChB,kBAAkB;QAClB,mCAAmC;;IAEvC;AACJ;;AAEA;IACI,WAAW;IACX,6BAA6B;AACjC;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".div_mdl {\n    display: flex;\n    gap: 2%;\n}\n.div_mdl  p:nth-child(1){\n    width: 40%;\n    font-size: 16px;\n    color: black;\n    font-weight: 500;\n}\n.div_mdl  p:nth-child(2){\n    width: 60%;\n    font-size: 16px;\n    font-weight: 500;\n    color: #989898;\n}\n\n.header {\n    display: flex;\n    justify-content: space-between;\n    margin: 20px 20px 0;\n\n\n    & h6 {\n        font-family: Inter;\n        font-size: 1.1rem;\n        font-weight: 600;\n    }\n}\n\n\n\n.model_button {\n    display: flex;\n    justify-content: flex-end;\n    gap: 10px;\n\n    & button {\n        display: flex;\n        width: 87px;\n        height: 39px;\n        padding: 10px 20px;\n        justify-content: center;\n        align-items: center;\n        gap: 10px;\n        border: none;\n        color: #FFF;\n        font-family: Inter;\n        font-size: 0.8rem;\n        font-weight: 500;\n        border-radius: 3px;\n        background-color: var(--main-color);\n\n    }\n}\n\n.model_button button:hover {\n    color: #FFF;\n    background: var(--main-color);\n}\n.bdy{\n    padding: 20px 20px 0 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"div_mdl": `Pay_div_mdl__48eFv`,
	"header": `Pay_header__vHSmU`,
	"model_button": `Pay_model_button__dypde`,
	"bdy": `Pay_bdy__L1l2T`
};
export default ___CSS_LOADER_EXPORT___;
