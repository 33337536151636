import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import styles from './ImageUploader.module.css';
import { fileChecker } from '../../Component/FileChecker/fileChecker';


const ImageUploader = ({ onImageSelect, currentFile , label }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [inputId] = useState(`fileInput-${Math.random().toString(36).substr(2, 9)}`); // Generate a unique ID

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const checkFile = fileChecker(file);
            if (!checkFile.value) {
                toast.error(checkFile.message);
                return;
            }
            setSelectedImage(file);
            setPreviewUrl(URL.createObjectURL(file));
            onImageSelect(file); // Pass the selected file to the parent component
        }
    };

    // const handleUploadClick = () => {
    //     document.getElementById('fileInput').click();

    // };
    const handleUploadClick = () => {
        document.getElementById(inputId).click(); // Use the unique ID
    };

    useEffect(() => {
        if (currentFile) {
            setPreviewUrl(currentFile)
        }
    }, [currentFile])

    return (
        <div className={styles.main_div}>
            <label htmlFor="fileInput">{label ?? 'Upload Image'}</label>
            <div
                className={`${styles.upload_box} w-100 mt-0`}
                onClick={handleUploadClick}
            >
                <p className="mb-0 fw-medium" style={{ color: '#AE0000' }}>
                    Upload Image or jpeg and png
                </p>
                <input
                    type="file"
                    id={inputId}
                    accept="image/jpeg, image/png"
                    onChange={handleFileChange}
                    className="d-none"

                />
            </div>
            {previewUrl && (
                <div className={styles.preview_container}>
                    <img
                        src={previewUrl}
                        alt="Preview"
                        className={styles.preview_image}
                        width={50}
                        height={50}
                    />
                </div>
            )}
        </div>
    );
};

ImageUploader.propTypes = {
    onImageSelect: PropTypes.func.isRequired,
};

export default ImageUploader;
