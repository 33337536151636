import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie'
import { BASE_URL } from '../../Apis/BaseUrl';
import axios from 'axios';
import ToasterUpdate from '../Toaster/ToasterUpdate';

const WithDrawalModal = ({ id ,type1 , title, status, action, show, handleClose, getData }) => {
  const[loading, setLoading] = useState(false)
  // console.log(id)

  let profileTokenJson = Cookies.get("v&b_token");
  let profileToken = JSON.parse(profileTokenJson);
  // console.log(profileToken.token)
  let token = profileToken.token;

  const handleAction = async () => {
    // e.preventDefault()
    
    // let register = `${BASE_URL}/withdraw-request/${id}/status`
    const myToast = toast.loading('Please Wait...')
    let register;
    let method; 
    if (title === 'withdraw') {
      register = `${BASE_URL}/withdraw-request/${id}/status`;
      method = 'put'
    } else if (title === 'report') {
      register = `${BASE_URL}/reviewsReport/${id}/status`
      method = 'patch'
    }
    // console.log(register)
    try {
        

        let res = await axios[method](register, { status: status }, {
            // withCredentials : true,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token} `,
            },
        })

        if (!res.data.success) {
            ToasterUpdate(myToast, res.data.message, "error")
        }
        ToasterUpdate(myToast, res.data.message, "success")
        getData()
    } catch (error) {
        // console.log(error)
        ToasterUpdate(myToast, error.message, "error")
    }
    finally{
        handleClose()
    }

};


  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title style={{fontSize:"20px"}}>{action} request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='fw-medium'>Are you sure you want to {action} this request?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleAction}>
          {action}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WithDrawalModal;
