// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Container_container__\\+5EAA{
    border-radius: 5px;
    background: white;
    border: 1px solid #D9D9D9;
}`, "",{"version":3,"sources":["webpack://./src/UI/Container/Container.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,yBAAyB;AAC7B","sourcesContent":[".container{\n    border-radius: 5px;\n    background: white;\n    border: 1px solid #D9D9D9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Container_container__+5EAA`
};
export default ___CSS_LOADER_EXPORT___;
