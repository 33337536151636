// import React from 'react'
// import classes from './NotificationModal.module.css'
// import Modal from '../../Modal/Modal'
// import Moment from 'react-moment'

// const NotificationModal = (props) => {
//     let data = props?.myData
//     return (
//         <Modal cls={`${classes.popup}`} value={props.isPopup} Func={props.popupFunc}>
// <div className={classes.header}>
//     <></>
//     <span className={classes.span}>
//         {data?.time_diff}
//         {/* <Moment format="DD/MM/YYYY">{data?.createdAt}</Moment> */}
//     </span>
// </div>
// <div className={classes.body}>
//     <p className={classes.p}>
//     {data?.description}
//     </p>
// </div>
//             <div className={classes.bottom}>
//                 <button onClick={()=>{props.popupFunc(!props.isPopup)}}>Cancel</button>
//             </div>
//         </Modal>
//     )
// }

// export default NotificationModal
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import classes from './NotificationModal.module.css'
import Moment from 'react-moment';

function NotificationModal({show, handleClose, data }) {

 const differTime = <Moment fromNow date={data?.createdAt} />


    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className={classes.header}>Notifications    <span className={classes.span}>
                            {differTime}

                        </span></Modal.Title>
                 
                </Modal.Header>
                <Modal.Body className='p-0'>
                    

                      
                    
                    <div className={classes.body}>
                    
                        <p className={classes.p}>
                            {data?.description}
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}

export default NotificationModal;
