import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie'
import { BASE_URL } from '../../Apis/BaseUrl';
import axios from 'axios';
import ToasterUpdate from '../Toaster/ToasterUpdate';

const ApproveModal = ({ id, apiTitle, action, show, handleClose, getData }) => {
  const[loading, setLoading] = useState(false)
  
  let profileTokenJson = Cookies.get("v&b_token");
  let profileToken = JSON.parse(profileTokenJson);
  // console.log(profileToken.token)
  let token = profileToken.token;

  const handleAction = async () => {
    // e.preventDefault()
    
    let register = `${BASE_URL}/refund/${id}/${apiTitle}`
    const myToast = toast.loading('Please Wait...')
    // console.log(register)
    try {
        let res = await axios.put(register, {}, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token} `,
            },
        })

        if (!res.data.success) {
            ToasterUpdate(myToast, res.data.message, "error")
        }
        ToasterUpdate(myToast, res.data.message, "success")
        getData()
    } catch (error) {
        // console.log(error)
        ToasterUpdate(myToast, error.message, "error")
    }
    finally{
        handleClose()
    }

};


// const handleAction = async () => {
//   // e.preventDefault()
  
//   let register = `${BASE_URL}/refund/${id}/${apiTitle}`
//   const myToast = toast.loading('Please Wait...')
//   // console.log(register)

//   try {
   

//       let res = await axios.put(register, {}, {
//           // withCredentials : true,
//           headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${token} `,
//           },
//       })

//       if (!res.data.success) {
//           ToasterUpdate(myToast, res.data.message, "error")
//       }
//       ToasterUpdate(myToast, res.data.message, "success")
//       getData()
//   } catch (error) {
//       // console.log(error)
//       ToasterUpdate(myToast, error.message, "error")
//   }
//   finally{
//       handleClose()
//   }

// };

  const handleDelete = async () => {
    
    const myToast = toast.loading('Please Wait...')
    setLoading(true)
    try {
      let register = `${BASE_URL}/user/${id}`

      const response = await axios.delete(register,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        });
      console.log(response)
      toast.update(myToast, {
        render: response.data.message,
        type: 'success',
        isLoading: false,
        autoClose: 1500
      });

      getData()
    } catch (error) {
      toast.update(myToast, {
        render: error.message,
        type: 'error',
        isLoading: false,
        autoClose: 1500
      })
      console.error('Error deleting resource:', error);
    }
    finally {
      setLoading(false)
      handleClose()
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title style={{fontSize:"20px"}}>{action} Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='fw-medium'>Are you sure you want to {action} this account?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleAction}>
          {action}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ApproveModal;


