import React from 'react'
import { Card } from 'react-bootstrap'

const CompCard = ({label,data,handleUpdate,handleDelete}) => {

    const capitalizedHeading = data[label] ? data[label].charAt(0).toUpperCase() + data[label].slice(1) : '';

    return (

        <Card className='my-3'>
            <Card.Body className='d-flex justify-content-between align-items-center'>
                <Card.Text className='mb-0 fw-medium text-capitalize'>
                    {capitalizedHeading ?? data?.type}
                </Card.Text>
                <div className='d-flex gap-2'>
                    <img onClick={handleUpdate} src="/image/edit.png" style={{ cursor: "pointer" }} alt="edit" />
                    <img onClick={handleDelete} src="/image/delete.png" style={{ cursor: "pointer" }} alt="delete" />
                </div>
            </Card.Body>
        </Card>
    )
}

export default CompCard