// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticket_main_section__ACG9e {
    display: flex;
    justify-content: flex-start;
    gap: 50px;
    margin-bottom: 10px;


    & label {
        white-space: normal;
        font-family: Inter;
        font-size: 0.8rem;
        font-weight: 500;
    }

    & p {

        color: #AE0000;
        font-family: Inter;
        font-size: 0.8rem;
        font-weight: 500;
    }

    & h6 {
        font-family: Inter;
        font-size: 0.8rem;
        font-weight: 500;
    }

    & textarea {
        padding: 10px;
        width: 100%;
        border-radius: 5px;
        border: 1px solid #D9D9D9;

    }
}

.ticket_model_button__AymxU {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    & button {
        white-space: normal;
        display: flex;
        width: auto;
        height: 39px;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border: none;
        color: #FFF;
        font-family: Inter;
        font-size: 0.8rem;
        font-weight: 500;
        border-radius: 3px;
        background: var(--main-color);
    }
}

.ticket_model_button__AymxU button:hover {
    color: #FFF;
    background: var(--main-color);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Support/SupportTicket/ticket.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,SAAS;IACT,mBAAmB;;;IAGnB;QACI,mBAAmB;QACnB,kBAAkB;QAClB,iBAAiB;QACjB,gBAAgB;IACpB;;IAEA;;QAEI,cAAc;QACd,kBAAkB;QAClB,iBAAiB;QACjB,gBAAgB;IACpB;;IAEA;QACI,kBAAkB;QAClB,iBAAiB;QACjB,gBAAgB;IACpB;;IAEA;QACI,aAAa;QACb,WAAW;QACX,kBAAkB;QAClB,yBAAyB;;IAE7B;AACJ;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,SAAS;;IAET;QACI,mBAAmB;QACnB,aAAa;QACb,WAAW;QACX,YAAY;QACZ,kBAAkB;QAClB,uBAAuB;QACvB,mBAAmB;QACnB,SAAS;QACT,YAAY;QACZ,WAAW;QACX,kBAAkB;QAClB,iBAAiB;QACjB,gBAAgB;QAChB,kBAAkB;QAClB,6BAA6B;IACjC;AACJ;;AAEA;IACI,WAAW;IACX,6BAA6B;AACjC","sourcesContent":[".main_section {\n    display: flex;\n    justify-content: flex-start;\n    gap: 50px;\n    margin-bottom: 10px;\n\n\n    & label {\n        white-space: normal;\n        font-family: Inter;\n        font-size: 0.8rem;\n        font-weight: 500;\n    }\n\n    & p {\n\n        color: #AE0000;\n        font-family: Inter;\n        font-size: 0.8rem;\n        font-weight: 500;\n    }\n\n    & h6 {\n        font-family: Inter;\n        font-size: 0.8rem;\n        font-weight: 500;\n    }\n\n    & textarea {\n        padding: 10px;\n        width: 100%;\n        border-radius: 5px;\n        border: 1px solid #D9D9D9;\n\n    }\n}\n\n.model_button {\n    display: flex;\n    justify-content: flex-end;\n    gap: 10px;\n\n    & button {\n        white-space: normal;\n        display: flex;\n        width: auto;\n        height: 39px;\n        padding: 10px 20px;\n        justify-content: center;\n        align-items: center;\n        gap: 10px;\n        border: none;\n        color: #FFF;\n        font-family: Inter;\n        font-size: 0.8rem;\n        font-weight: 500;\n        border-radius: 3px;\n        background: var(--main-color);\n    }\n}\n\n.model_button button:hover {\n    color: #FFF;\n    background: var(--main-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_section": `ticket_main_section__ACG9e`,
	"model_button": `ticket_model_button__AymxU`
};
export default ___CSS_LOADER_EXPORT___;
