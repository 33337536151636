// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Heading_container__79Uvg {
    align-items: center;
    display: flex;
    margin-bottom: 25px;
    justify-content: space-between;
}

.Heading_container__79Uvg>div>h3 {
    font-weight: 500;
}

.Heading_container__79Uvg>div>p {
    color: #989898;
    font-size: 13px;
}

.Heading_button__OsaxM {
    height: 40px;
    padding: 0 20px;
}

@media screen and (max-width:650px) {
    .Heading_container__79Uvg {
        flex-direction: column;
        row-gap: 20px;
        align-items: flex-start;
    }

    .Heading_button__OsaxM {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/Component/Heading/Heading.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI;QACI,sBAAsB;QACtB,aAAa;QACb,uBAAuB;IAC3B;;IAEA;QACI,WAAW;IACf;AACJ","sourcesContent":[".container {\n    align-items: center;\n    display: flex;\n    margin-bottom: 25px;\n    justify-content: space-between;\n}\n\n.container>div>h3 {\n    font-weight: 500;\n}\n\n.container>div>p {\n    color: #989898;\n    font-size: 13px;\n}\n\n.button {\n    height: 40px;\n    padding: 0 20px;\n}\n\n@media screen and (max-width:650px) {\n    .container {\n        flex-direction: column;\n        row-gap: 20px;\n        align-items: flex-start;\n    }\n\n    .button {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Heading_container__79Uvg`,
	"button": `Heading_button__OsaxM`
};
export default ___CSS_LOADER_EXPORT___;
