// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationDiv_row__9seID {
    display: flex;
    align-items: center;
    column-gap: 4px;
    justify-content: space-between;
    border-bottom: 1px solid var(--border);
    padding: 10px 20px;
    cursor: pointer;
    transition: 0.2s ease;
}
.NotificationDiv_row1__vOfUB {
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: space-between;
    border-bottom: 1px solid #d9d9d9;
    cursor: pointer;
    transition: 0.2s ease;
    padding: 10px;
}

/* .row:hover {
    background: var(--sc);
} */

.NotificationDiv_bell__73WeH {
    /* width: 35px;
    height: 35px; */
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f9f9f9;
    border-radius: 50%;
    padding: 10px;
}

.NotificationDiv_p__RZ2mm {
    font-size: 14px;
    font-weight: 500;
    width: 75%;
    color: #3d3b3b;
    margin-bottom: 0;
}

.NotificationDiv_span__hyBSq {
    text-align: right;
    font-size: 12px;
    color: red;
    font-weight: 500;
    width: 150px;
}`, "",{"version":3,"sources":["webpack://./src/Component/Notifications/NotificationDiv/NotificationDiv.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,8BAA8B;IAC9B,sCAAsC;IACtC,kBAAkB;IAClB,eAAe;IACf,qBAAqB;AACzB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,8BAA8B;IAC9B,gCAAgC;IAChC,eAAe;IACf,qBAAqB;IACrB,aAAa;AACjB;;AAEA;;GAEG;;AAEH;IACI;mBACe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,UAAU;IACV,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,UAAU;IACV,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".row {\n    display: flex;\n    align-items: center;\n    column-gap: 4px;\n    justify-content: space-between;\n    border-bottom: 1px solid var(--border);\n    padding: 10px 20px;\n    cursor: pointer;\n    transition: 0.2s ease;\n}\n.row1 {\n    display: flex;\n    align-items: center;\n    column-gap: 10px;\n    justify-content: space-between;\n    border-bottom: 1px solid #d9d9d9;\n    cursor: pointer;\n    transition: 0.2s ease;\n    padding: 10px;\n}\n\n/* .row:hover {\n    background: var(--sc);\n} */\n\n.bell {\n    /* width: 35px;\n    height: 35px; */\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: #f9f9f9;\n    border-radius: 50%;\n    padding: 10px;\n}\n\n.p {\n    font-size: 14px;\n    font-weight: 500;\n    width: 75%;\n    color: #3d3b3b;\n    margin-bottom: 0;\n}\n\n.span {\n    text-align: right;\n    font-size: 12px;\n    color: red;\n    font-weight: 500;\n    width: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `NotificationDiv_row__9seID`,
	"row1": `NotificationDiv_row1__vOfUB`,
	"bell": `NotificationDiv_bell__73WeH`,
	"p": `NotificationDiv_p__RZ2mm`,
	"span": `NotificationDiv_span__hyBSq`
};
export default ___CSS_LOADER_EXPORT___;
