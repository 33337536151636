import React, { useEffect, useRef, useState } from 'react'
import styles from './detail.module.css'
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import { Table } from 'react-bootstrap'
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from 'react-step-progress-bar';
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import { BASE_URL } from '../../Apis/BaseUrl'
import axios from 'axios'
import Moment from 'react-moment'
import UpdateLog from './UpdateLog'
import FallProduct from '../../Component/FallbackImgae/FallProduct'
import formatter from '../../Component/NumberFormatter/formatter'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FormatNumber } from '../../Component/FormatNumber/FormatNumber'

const OrderDetails = () => {
    const [data, setData] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const { id } = useParams()

    const divRef = useRef(null);

    const handleDownload = () => {
        html2canvas(divRef.current).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210; // A4 size
            const imgHeight = canvas.height * imgWidth / canvas.width;
            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

            // Add styling
            const filename = 'downloaded.pdf';
            pdf.save(filename);
        });
    };
    const handleShow = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;
    const getData = async () => {

        let register = `${BASE_URL}/order/${id}/show`

        // console.log(register)
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        console.log(res.data.data)
        setData(res.data.data)
        // setPageInfo({ ...res.data.data, docs: null })
    }

    useEffect(() => {
        getData()
    }, [])
    const steps = [
        { label: 'Order Placed', date: '' },
        { label: 'Processing', date: '' },
        { label: 'Shipped', date: '' },
        { label: 'Delivered', date: '' },
    ];
    const step1 = [
        { label: 'Order Placed', date: '' },
        { label: data?.orderStatus, date: '' },

    ];
    const step2 = [
        { label: 'Order Placed', date: '' },
        { label: 'Return', date: '' },

    ];
    let var1;
    let colr;
    // let colorDetails = {
    //     "pending": [0, "#55B87B"],
    //     "processing": [34, "#55B87B"],
    //     "shipped": [67, "#55B87B"],
    //     "delivered": [100, "#55B87B"],
    //     "cancelled": [100, "red"],
    //     "return": [100, "red"],
    //     "default": [0, 0]

    // }
    // var1 = colorDetails[data?.orderStatus || "default"][0] || 0
    // colr = colorDetails[data?.orderStatus || "default"][1] || 0

    if (data?.orderStatus == "pending") {
        var1 = 0
        colr = "#55B87B"
    } else if (data?.orderStatus == "processing") {
        var1 = 34
        colr = "#55B87B"
    } else if (data?.orderStatus == "shipped") {
        var1 = 67
        colr = "#55B87B"
    } else if (data?.orderStatus == "delivered") {
        var1 = 100
        colr = "#55B87B"
    } else if (data?.orderStatus == "cancelled") {
        var1 = 100
        colr = "red"
    } else if (data?.orderStatus == "return") {
        var1 = 100
        colr = "red"
    }
    else {
        var1 = 0
    }
    function stepFunc(stepsData) {
        return (
            stepsData.map((step, index) => (
                <div key={index} className={styles.label}>
                    {step.label}
                </div>
            ))
        )
    }
    const taxData = (a, b) => {
        let tx_data =  a + b 
       let finaldata =  formatter.format(tx_data || 0)
        return finaldata
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Order Details</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            {/* --------------hero-section---------------- */}

            <div className={styles.hero_Section}>
                <div className={styles.shipped_section}>
                    <div>
                        <div className={styles.milestone_container}>
                            <div className={styles.labels}>
                                {(data?.orderStatus == "cancelled" && stepFunc(step1)) || (data?.orderStatus == "return" && stepFunc(step2)) || (data?.orderStatus == "pending" && stepFunc(steps)) || (data?.orderStatus == "processing" && stepFunc(steps)) || (data?.orderStatus == "shipped" && stepFunc(steps)) || (data?.orderStatus == "delivered" && stepFunc(steps)) || []}

                            </div>
                            <ProgressBar
                                percent={var1}
                                filledBackground={colr}
                            >
                                {(data?.orderStatus !== "cancelled" || data?.orderStatus !== "return") && steps?.map((step, index) => (
                                    <Step key={index} transition="scale">
                                        {({ accomplished }) => (
                                            <div
                                                className={`${styles.step} ${data.orderStatus !== "cancelled" && data.orderStatus !== "return" ? styles.accomplished : styles.accomplished1}`}
                                            ></div>
                                        )}
                                    </Step>
                                ))}



                            </ProgressBar>
                            <div className={styles.percentages}>
                                {data.orderStatus !== "cancelled" && steps.map((step, index) => (
                                    <div key={index} className="percentage">
                                        {step.date}
                                    </div>
                                ))}
                                {data.orderStatus == "cancelled" && step1.map((step, index) => (
                                    <div key={index} className="percentage">
                                        {step.date}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={styles.timing}>
                        <h6>Logs {data.orderStatus}</h6>
                        {data?.logs?.map((item, index) => (
                            <p>{item?.log} at  <Moment format="DD-MM-YYYY" utc>{item?.date}</Moment></p>
                        ))}


                    </div>
                    <div className={styles.update_btn}>
                        <button onClick={handleShow}>Update Log</button>
                    </div>
                </div>

                <div className={styles.address}>
                    <h6>Buyer Details</h6>

                    <div>
                        <p>{data?.customerId?.name}</p>
                        <p>{data?.customerId?.email}</p>
                        {data?.billingAddress && <p>Billing Address : <span>{data?.billingAddress}</span></p>}
                        {data?.shippingAddress && <p>Shipping Address : <span>{data?.shippingAddress}</span></p>
                        }

                    </div>
                </div>
                <div className={styles.address}>
                    <h6>Pick-up Details</h6>

                    <div>
                        <p>{data?.pickupAddress}</p>


                    </div>
                </div>

                <div className={styles.order_detail} div ref={divRef}>
                    <h6>Order Details</h6>

                    <div className={styles.list_section}>
                        <ul>
                            <li>Order ID</li>
                            <li>Seller Name</li>
                            <li>Delivery Status</li>
                            <li>date</li>
                            <li>Total Amount</li>
                            <li>Payment Method</li>
                            <li>Payment Ref.</li>
                            <li>Delivery Scheduled</li>
                            {data?.notes && <li>Additional Info</li>}
                        </ul>
                        <ul>
                            <li>#{data?.orderId}</li>
                            <li className='text-capitalize'>{data?.sellerId?.name}</li>
                            {/* {data?.orderStatus} */}
                            {data?.orderStatus == "return" && <li className='text-capitalize'><span class="badge bg-danger">{data?.orderStatus}</span></li>}
                            {data?.orderStatus == "cancelled" && <li className='text-capitalize'><span class="badge bg-danger">{data?.orderStatus}</span></li>}
                            {data?.orderStatus !== "return" && data?.orderStatus !== "cancelled" && <li className='text-capitalize'><span class="badge bg-success">{data?.orderStatus}</span></li>}
                            {console.log(data.date)}
                            <li><Moment format="YYYY/MM/DD" utc>{data?.date}</Moment></li>
                            <li>{FormatNumber(data?.total || 0)}</li>
                            <li className='text-capitalize'>{data?.paymentMethod ? data?.paymentMethod : "---"}</li>
                            <li>{data?.paymentRefNumber ? data?.paymentRefNumber : "---"}</li>
                            <li>{data?.delivery_scheduled ? data.delivery_scheduled : "---"}</li>
                            {data?.notes && <li>{data?.notes}</li>}
                        </ul>
                    </div>

                    {/* -------------table-section--------------- */}

                    <div className={styles.main_table}>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    {/* <th>#</th> */}
                                    <th className='px-3'>Photo</th>
                                    <th className='px-3'>Name</th>
                                    <th className='px-3'>ORDER TYPE</th>
                                    <th className='px-3'>QTY</th>
                                    <th className='px-3'>PRICE</th>
                                    <th className='px-3'>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.items?.map((item, index) => (
                                    <tr>
                                        {/* <td>{index + 1}</td> */}
                                        <td className='px-3'><FallProduct cls={styles.img1} imgData={item?.productId?.url} alt="product" /></td>
                                        <td className='text-capitalize px-3'>{item?.productId?.name}</td>
                                        <td className='text-capitalize px-3'>{data?.deliveryType}</td>
                                        <td className='px-3'>{item?.qty}</td>
                                        <td className='px-3'> {formatter.format(item?.price || 0)}</td>
                                        <td className='px-3'> {formatter.format((item?.qty * item?.price) || 0)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>

                    <div className={styles.total_section}>
                        <ul>
                            <div className={styles.total1}>
                                <li>Sub Total : </li> <span>{formatter.format(data?.subtotal || 0)}</span>

                            </div>
                            <div className={styles.total1}><li>Tax: </li> <span>{taxData(data?.tax?.gst, data?.tax?.pst)}</span></div>
                            <div className={styles.total1}>
                                <li>Discount: </li> <span>{formatter.format(data?.discount || 0)}</span>
                            </div>
                            <div className={styles.total1}>
                                <li>Shipping : </li><span> {formatter.format(data?.shippingCharge || 0)}</span>
                            </div>
                            <div className={styles.total1}>
                                <li>Total : </li><span>{formatter.format(data?.total || 0)}</span>
                            </div>
                        </ul>

                    </div>
                    <div className='d-flex justify-content-end'>
                        <div className={styles.icon} style={{ cursor: "pointer" }}>
                            <img src="/image/printer.png" alt="printer" onClick={handleDownload} />
                        </div>
                    </div>

                </div>



            </div>
            {isOpen && <UpdateLog id={id} getData={getData} isOpen={isOpen} closeModal={closeModal} status={data?.orderStatus} />}
        </React.Fragment>
    )
}

export default OrderDetails