import React from 'react';
import styles from './import.module.css'
import TopNavbar from '../../Component/TopMenu/TopNavbar';
import NavbarSection from '../../Component/NavbarSection/NavbarSection';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const BulkImport = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Bulk Import</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            {/* -----------------hero-section---------------- */}

            <div className={styles.hero_Section}>


                <Row>
                    <Col md={9} sm={12} className={styles.maincol}>
                        <h6>Bulk Products Upload</h6>
                        <div className={styles.mainSection}>
                            <div className={styles.childSection}>
                                <p>1. Download the skeleton file and fill it with data.:</p>
                            </div>
                            <div className={styles.childSection}>
                                <p>2. You can download the example file to understand how the data must be filled.:</p>
                            </div>
                            <div className={styles.childSection}>
                                <p>3. Once you have downloaded and filled the skeleton file, upload it in the form below and submit.:</p>
                            </div>
                            <div className={styles.childSection}>
                                <p>4. After uploading products you need to edit them and set products images and choices.</p>
                            </div>
                            <button>Download CSV</button>
                        </div>
                        <div className={styles.categorySection}>
                            <div className={styles.childSection}>
                                <p>1. Category and Brand should be in numerical id.:</p>
                            </div>
                            <div className={styles.childSection}>
                                <p>2. You can download the pdf to get Category and Brand </p>
                            </div>
                            <button>Download CSV</button>
                            <button className='ms-2'>Download CSV</button>
                        </div>
                        <div className={styles.uploadSection}>
                            <p>Upload CSV file</p>
                            <hr />
                            <div className={styles.forminput}>
                                <label for="leftInput">CSV</label>
                                <input type="file" id="leftInput" name="leftInput" />
                            </div>
                            <div className={styles.btn_section}>
                                <button>Upload CSV</button>
                            </div>

                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default BulkImport