import React, { useEffect, useState } from 'react'
import styles from './AppModal.module.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../../Apis/BaseUrl';
import axios from 'axios';
import { fileChecker } from '../../../Component/FileChecker/fileChecker';
import Cookies from 'js-cookie';
import { Form } from 'react-bootstrap';
import AsyncSelect from 'react-select/async'
import ReactSelect from 'react-select';

const AddObjectProduct = ({ isOpen, closeModal, getData }) => {

    const [type, setType] = useState('')
    const [name, setName] = useState('')
    const [variations, setVariations] = useState('')
    const [place, setPlace] = useState('')
    const [binFile, setBinFile] = useState(null)
    const [objectFile, setObjectFile] = useState(null)
    const [loading, setLoading] = useState(false)
const [product,setProduct] = useState([])
    const [object_id, setObjectID] = useState([])
    const [dataOptions, setDataOptions] = useState([])


    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    let token = profileToken.token;

    const handleDataUpload = async () => {
        const formData = new FormData();
        // formData.append('name', name);
        formData.append('type', type);
        formData.append('place', place.value);
        formData.append('object_id', object_id.value);
        formData.append('product_id', product.value);
        formData.append('binFile', binFile)
        formData.append('objectFile', objectFile)
        formData.append('variations', JSON.stringify(variations))

        // variations.forEach(element => {
        //     formData.append(`variations.material`,element._id );    
        //     formData.append(`variations.variationName`,element.type );    
        // });


        const register = `${BASE_URL}/visualizer/object-product`

        const myToast = toast.loading('Please Wait...')
        setLoading(true)
        try {
            const response = await axios.post(register, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            })

            if (!response.data.success) {
                throw new Error(response.data.message)

            }
            toast.update(myToast, {
                render: response.data.message,
                type: 'success',
                isLoading: false,
                autoClose: 1500
            });
            closeModal()

            getData()
            setObjectFile(null)
            setBinFile(null)
            setType('')
            setVariations('')
            setPlace('')
            setLoading('')
        } catch (error) {
            console.error('Error while uploading data:', error);

            toast.update(myToast, {
                render: error.message,
                type: 'error',
                isLoading: false,
                autoClose: 1500
            });
        }
        finally {
            setLoading(false)
        }
    };


    const getProductsData = async (query = '') => {
        try {
            let register = `${BASE_URL}/product?limit=10&page=1&search=${query}`

            let res = await axios.get(register, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            return res.data.data.docs.map((element) => ({ label: element.name, value: element._id }))
        }
        catch (error) {
            console.error('Error while uploading data:', error);

            toast.error({
                render: error.message,
                type: 'error',
                isLoading: false,
                autoClose: 1500
            });
        }
    }
    const getPlaceData = async (query = '') => {
        try {
            let register = `${BASE_URL}/visualizer/place?limit=10&page=1&search=${query}`

            let res = await axios.get(register, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            return res.data.data.docs.map((element) => ({ label: element.name, value: element._id }))
        }
        catch (error) {
            console.error('Error while uploading data:', error);

            toast.error({
                render: error.message,
                type: 'error',
                isLoading: false,
                autoClose: 1500
            });
        }
    }

    const handlePlaceValue = value => {
        setPlace(value)
    }

    const getMaterialData = async (query = '') => {
        try {
            let register = `${BASE_URL}/visualizer/material?limit=10&page=1&search=${query}`

            let res = await axios.get(register, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            return res.data.data.docs.map(element => ({ material: element._id, variationName: element.type }))
        }
        catch (error) {
            console.error('Error while uploading data:', error);

            toast.error({
                render: error.message,
                type: 'error',
                isLoading: false,
                autoClose: 1500
            });
        }
    }

    const handleMaterialValue = value => {
        setVariations(value)
    }

    const getObjectsData = async () => {
        try {
            let register = `${BASE_URL}/visualizer/place/${place.value}/show`

            let res = await axios.get(register, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })

            let dt = res.data.data.objects
            if (dt.length > 0) {
                dt = dt.map(element => ({ value: element._id, label: element.name }))  
            }

            setDataOptions(dt)


        }
        catch (error) {
            console.error('Error while uploading data:', error);

            toast.error({
                render: error.message,
                type: 'error',
                isLoading: false,
                autoClose: 1500
            });
        }
    }

    useEffect(() => {
        if (place) {
            getObjectsData()
        }
    }, [place])

    return (
        <React.Fragment>
            <Modal show={isOpen} onHide={closeModal}>
                <Modal.Header>
                    <Modal.Title className={styles.modal_title}>Add Product Object</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-3'>
                    <div className='mx-auto' md={9}>
                        <div className={styles.main_section}>
                            <Form>
                                {/* <div className={styles.main_div}>
                                    <label htmlFor="">Name</label>
                                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                </div> */}
                                <div className={styles.main_div}>
                                    <label htmlFor="">Type</label>
                                    <input type="text" value={type} onChange={(e) => setType(e.target.value)} />
                                </div>
                                <div className={styles.main_div}>
                                    <label htmlFor="">Variations</label>
                                    {/* <input type="text" value={variations} onChange={(e) => setVariations(e.target.value)} /> */}
                                    <AsyncSelect onInputChange={(text) => getMaterialData(text)} cacheOptions getOptionValue={e => e.material} getOptionLabel={e => e.variationName} loadOptions={getMaterialData} value={variations} defaultOptions onChange={handleMaterialValue} isMulti />
                                </div>
                                <div className={styles.main_div}>
                                    <label htmlFor="">Place</label>
                                    <AsyncSelect onInputChange={(text) => getPlaceData(text)} cacheOptions getOptionValue={e => e.value} loadOptions={getPlaceData} value={place} defaultOptions onChange={handlePlaceValue} />
                                </div>
                                <div className={styles.main_div}>
                                    <label htmlFor="">Object</label>
                                    <ReactSelect getOptionValue={e => e.value} value={object_id} options={dataOptions} onChange={x=>setObjectID(x)} />
                                </div>
                                <div className={styles.main_div}>
                                    <label htmlFor="">Product</label>
                                    <AsyncSelect onInputChange={(text) => getProductsData(text)} cacheOptions getOptionValue={e => e.value} loadOptions={getProductsData} value={product} defaultOptions onChange={pd=>setProduct(pd)} />
                                </div>
                                <div className={styles.main_div}>
                                    <label htmlFor="">Object File</label>
                                    <input type="file" onChange={(e) => setObjectFile(e.target.files[0])} />
                                </div>
                                <div className={styles.main_div}>
                                    <label htmlFor="">Bin File</label>
                                    <input type="file" onChange={(e) => setBinFile(e.target.files[0])} />
                                </div>
                            </Form>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div className={styles.model_button}>
                        <button className='bg-white text-black border' variant="secondary" onClick={closeModal}> Cancel  </button>
                        <button disabled={loading} variant="primary" onClick={handleDataUpload}>{loading ? 'Loading' : 'Add Object'}</button>
                    </div>

                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default AddObjectProduct