import React, { useState } from 'react';
import styles from './att.module.css'
import TopNavbar from '../../Component/TopMenu/TopNavbar';
import NavbarSection from '../../Component/NavbarSection/NavbarSection';
import ContainerSection from '../../Component/ContainerSection/ContainerSection';
import AttModal from './AttModal/AttModal';
import { Helmet } from 'react-helmet';

const Attributes = () => {
    const [isOpen, setIsOpen] = useState(false)

    const closeModal = () => {
        setIsOpen(false)
    }
    const openModal = () => {
        setIsOpen(true)
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>Attributes</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            {/* -----------------hero-section---------------- */}

            <div className={styles.hero_Section}>
                <ContainerSection />

                <div className={styles.filter_section}>
                    <div className={styles.filter}>
                        <p>Filters : </p>
                        <p>Status </p>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                            <label className="form-check-label" for="flexRadioDefault2">
                                All
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Active
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Inactive
                            </label>
                        </div>
                    </div>
                    <AttModal isOpen={isOpen} closeModal={closeModal} />
                    <div className={styles.btn_section}>
                        <button onClick={openModal}>Add Attributes</button>
                    </div>

                </div>


                {/* -------------table_section-------------- */}

                <div className={styles.main_table}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col"><input type="radio" /></th>
                                <th scope="col">#</th>
                                <th scope="col">Attribute</th>
                                <th scope="col">Values</th>
                                <th className='text-end' scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row"><input type="radio" /></th>
                                <td>1</td>
                                <td>Size</td>
                                <td className='d-flex gap-2 ps-2'>
                                    <div className={styles.value}>M</div>
                                    <div className={styles.value}>L</div>
                                    <div className={styles.value}>S</div>
                                    <div className={styles.value}>XL</div>
                                    <div className={styles.value}>XXL</div>
                                    <div className={styles.value}>64GB</div>
                                    <div className={styles.value}>128GB</div>
                                    <div className={styles.value}>256GB</div>
                                    <div className={styles.value}>512GB</div>
                                </td>
                                <td className='text-end'>
                                    <div className={styles.image_section}>
                                        <img src="/image/edit.png" alt="edit" />
                                        <img src="/image/delete.png" alt="delete" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row"><input type="radio" /></th>
                                <td>2</td>
                                <td>Liter</td>
                                <td className='d-flex gap-2 ps-2'>
                                    <div className={styles.value}>1 Ltr</div>
                                    <div className={styles.value}>2 Ltr</div>
                                    <div className={styles.value}>3 Ltr</div>
                                    <div className={styles.value}>4 Ltr</div>
                                    <div className={styles.value}>5 Ltr</div>
                                </td>
                                <td className='text-end'>
                                    <div className={styles.image_section}>
                                        <img src="/image/edit.png" alt="edit" />
                                        <img src="/image/delete.png" alt="delete" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row"><input type="radio" /></th>
                                <td>3</td>
                                <td>Kg</td>
                                <td className='d-flex gap-2 ps-2'>
                                    <div className={styles.value}>1Kg</div>
                                    <div className={styles.value}>2Kg</div>
                                    <div className={styles.value}>3Kg</div>
                                    <div className={styles.value}>4Kg</div>
                                </td>
                                <td className='text-end'>
                                    <div className={styles.image_section}>
                                        <img src="/image/edit.png" alt="edit" />
                                        <img src="/image/delete.png" alt="delete" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </React.Fragment>
    )
}

export default Attributes