import React from 'react'
import './permission.css'
import styles from './permission.module.css'
import TopNavbar from '../../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../../Component/NavbarSection/NavbarSection'
import { Accordion, Col, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'


const Permission = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Role Permission</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            {/* --------------hero_section--------------- */}

            <Row className={styles.row_section}>
                <Col className='mx-auto' md={10}>
                    <div className={styles.hero_section}>
                        <label For="">Shop Name</label>
                        <input type="text" placeholder='Customer Service Representatives' />
                    </div>
                    <h6>Permission</h6>

                    {/* ------------Accordion_section------------- */}

                    <Accordion defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Product</Accordion.Header>
                            <Accordion.Body>
                                <div className={styles.main_section}>
                                    <div className={styles.switch_box}>
                                        <p>Add New Product</p>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked />
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className='mt-3 text-black' eventKey="1">
                            <Accordion.Header className='product_accordian'>Product category</Accordion.Header>
                            <Accordion.Body>
                                <div className={styles.main_section}>
                                    <div className={styles.switch_box}>
                                        <p>Add New Product</p>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked />
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className='mt-3' eventKey="2">
                            <Accordion.Header>Product Attribute</Accordion.Header>
                            <Accordion.Body>
                                <div className={styles.main_section}>
                                    <div className={styles.switch_box}>
                                        <p>Product Attribute</p>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked />
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>


        </React.Fragment>
    )
}

export default Permission