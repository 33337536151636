import React, { useState } from 'react'
import styles from './modal.module.css'
import { Button, Modal } from 'react-bootstrap';
import { VscEye, VscEyeClosed } from "react-icons/vsc";


const StaffModal = ({ isOpen, closeModal }) => {
    const [showPassword, setShowPassword] = useState(false)

    const handleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    return (
        <React.Fragment>
            <Modal show={isOpen} onHide={closeModal}>
                <Modal.Header>
                    <Modal.Title>Add New Staff</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-3'>
                    <div className={`${styles.main_section_name}`}>
                        <div className='d-flex flex-column '>
                            <label for="name">Name</label>
                            <input type="text" id='name' />
                        </div>
                        <div className='d-flex flex-column'>
                            <label for="name">Phone</label>
                            <input type="Number" id='name' />
                        </div>
                    </div>
                    <div className={styles.main_section}>
                        <label for="name2">Email Id</label>
                        <input type="text" id='name2' />
                    </div>
                    <div className={styles.main_section}>
                        {showPassword ? (<VscEye className={styles.password_icon} onClick={handleShowPassword} />) :
                            (<VscEyeClosed className={styles.password_icon} onClick={handleShowPassword} />)
                        }
                        <label for="tittle">Password</label>
                        <input type={showPassword ? 'text' : 'password'} id='tittle' />
                    </div>
                    <div className={styles.main_section}>
                        <label for="meta">Role</label>
                        <input type="text" id='meta' />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={styles.model_button}>
                        <Button className='bg-white text-black border' variant="secondary" onClick={closeModal}> Cancel  </Button>
                        <Button variant="primary" onClick={closeModal}>  Save </Button>
                    </div>

                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default StaffModal