// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section_grid_container__kJ1e0 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
}

.section_box__hxlPZ {
    padding: 10px;
    width: auto;
    height: 110px;
    border-radius: 5px;
    border: 1px solid #B9B9B9;
    background: #FFF;

    & p {
        font-family: Inter;
        font-size: 0.97rem;
        font-weight: 500;
    }

    & h6 {
        color: var(--main-color);
        font-family: Inter;
        font-size: 24px;
        font-weight: 500;
    }
}

/* --------Media query for responsiveness---------- */
@media (max-width: 568px) {
    .section_grid_container__kJ1e0 {
        grid-template-columns: repeat(1, 1fr);

    }
}`, "",{"version":3,"sources":["webpack://./src/Component/ContainerSection/section.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;AACb;;AAEA;IACI,aAAa;IACb,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;;IAEhB;QACI,kBAAkB;QAClB,kBAAkB;QAClB,gBAAgB;IACpB;;IAEA;QACI,wBAAwB;QACxB,kBAAkB;QAClB,eAAe;QACf,gBAAgB;IACpB;AACJ;;AAEA,qDAAqD;AACrD;IACI;QACI,qCAAqC;;IAEzC;AACJ","sourcesContent":[".grid_container {\n    display: grid;\n    grid-template-columns: repeat(5, 1fr);\n    gap: 20px;\n}\n\n.box {\n    padding: 10px;\n    width: auto;\n    height: 110px;\n    border-radius: 5px;\n    border: 1px solid #B9B9B9;\n    background: #FFF;\n\n    & p {\n        font-family: Inter;\n        font-size: 0.97rem;\n        font-weight: 500;\n    }\n\n    & h6 {\n        color: var(--main-color);\n        font-family: Inter;\n        font-size: 24px;\n        font-weight: 500;\n    }\n}\n\n/* --------Media query for responsiveness---------- */\n@media (max-width: 568px) {\n    .grid_container {\n        grid-template-columns: repeat(1, 1fr);\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid_container": `section_grid_container__kJ1e0`,
	"box": `section_box__hxlPZ`
};
export default ___CSS_LOADER_EXPORT___;
