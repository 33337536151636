// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.permission_row_section__al-IX {
  width: 100%;

  & h6 {
    font-family: Poppins;
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 20px;
  }
}

.permission_hero_section__tdaLP {
  display: flex;
  margin: 30px 0;
  justify-content: flex-start;
  gap: 40px;
  width: 100%;
  height: auto;


  & label {
    font-family: Poppins;
    font-size: 0.8rem;
    font-weight: 500;
    white-space: normal;
  }

  & input {
    padding: 10px;
    width: 100%;
    height: 35px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;

  }
}

.permission_main_section__98uqd {
  padding: 20px;
}

.permission_switch_box__L1Iro {
  width: 130px;
  display: flex;
  height: auto;
  padding: 10px;
  gap: 8px;
  border-radius: 5px;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #d9d9d9;

  & p {
    margin: 0;
    color: #1B1B28;
    font-family: "Inter";
    font-size: 0.8rem;
    font-weight: 400;
    white-space: normal;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/RolePermission/PermissionData/permission.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;;EAEX;IACE,oBAAoB;IACpB,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;EACrB;AACF;;AAEA;EACE,aAAa;EACb,cAAc;EACd,2BAA2B;EAC3B,SAAS;EACT,WAAW;EACX,YAAY;;;EAGZ;IACE,oBAAoB;IACpB,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;;EAE3B;AACF;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,aAAa;EACb,QAAQ;EACR,kBAAkB;EAClB,sBAAsB;EACtB,uBAAuB;EACvB,yBAAyB;;EAEzB;IACE,SAAS;IACT,cAAc;IACd,oBAAoB;IACpB,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;EACrB;AACF","sourcesContent":[".row_section {\n  width: 100%;\n\n  & h6 {\n    font-family: Poppins;\n    font-size: 0.8rem;\n    font-weight: 500;\n    margin-bottom: 20px;\n  }\n}\n\n.hero_section {\n  display: flex;\n  margin: 30px 0;\n  justify-content: flex-start;\n  gap: 40px;\n  width: 100%;\n  height: auto;\n\n\n  & label {\n    font-family: Poppins;\n    font-size: 0.8rem;\n    font-weight: 500;\n    white-space: normal;\n  }\n\n  & input {\n    padding: 10px;\n    width: 100%;\n    height: 35px;\n    border-radius: 5px;\n    border: 1px solid #d9d9d9;\n\n  }\n}\n\n.main_section {\n  padding: 20px;\n}\n\n.switch_box {\n  width: 130px;\n  display: flex;\n  height: auto;\n  padding: 10px;\n  gap: 8px;\n  border-radius: 5px;\n  flex-direction: column;\n  align-items: flex-start;\n  border: 1px solid #d9d9d9;\n\n  & p {\n    margin: 0;\n    color: #1B1B28;\n    font-family: \"Inter\";\n    font-size: 0.8rem;\n    font-weight: 400;\n    white-space: normal;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row_section": `permission_row_section__al-IX`,
	"hero_section": `permission_hero_section__tdaLP`,
	"main_section": `permission_main_section__98uqd`,
	"switch_box": `permission_switch_box__L1Iro`
};
export default ___CSS_LOADER_EXPORT___;
