// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :root {
    --main-color: #AE0000;
    --secondary-color: #000000
  }

  .form-control:focus, .form-select:focus{
    box-shadow: none !important;
    border: 1px solid #d9d9d9;
}
.form-check-input:focus {
  border:1px solid#d9d9d9 !important;
  outline: 0;
  box-shadow: none;
}
.btn:hover {
  color: var(--bs-btn-active-color) !important;
  background-color: var(--main-color) !important;
  border-color: none !important;
}
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: white !important;
}
.navbar-toggler:focus{
  box-shadow: none !important;
  border: 2px solid black;
}

/* div::-webkit-scrollbar {
  display: none;
} */

.form-check-input:checked{
  background-color: #AE0000;
  border-color: #AE0000;
}
table thead tr th{
  padding: 20px 0 !important;
}
table thead{
  background: #EDEDED;
}
.btn-close{
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"EAIE;IACE,SAAS;IACT,UAAU;IACV,sBAAsB;EACxB;;EAEA;IACE,qBAAqB;IACrB;EACF;;EAEA;IACE,2BAA2B;IAC3B,yBAAyB;AAC7B;AACA;EACE,kCAAkC;EAClC,UAAU;EACV,gBAAgB;AAClB;AACA;EACE,4CAA4C;EAC5C,8CAA8C;EAC9C,6BAA6B;AAC/B;AACA;EACE,2CAA2C;EAC3C,qBAAqB;EACrB,kCAAkC;AACpC;AACA;EACE,2BAA2B;EAC3B,uBAAuB;AACzB;;AAEA;;GAEG;;AAEH;EACE,yBAAyB;EACzB,qBAAqB;AACvB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,mBAAmB;AACrB;AACA;EACE,wBAAwB;AAC1B","sourcesContent":["  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');\n  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,600&display=swap');\n\n\n  * {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n  }\n\n  :root {\n    --main-color: #AE0000;\n    --secondary-color: #000000\n  }\n\n  .form-control:focus, .form-select:focus{\n    box-shadow: none !important;\n    border: 1px solid #d9d9d9;\n}\n.form-check-input:focus {\n  border:1px solid#d9d9d9 !important;\n  outline: 0;\n  box-shadow: none;\n}\n.btn:hover {\n  color: var(--bs-btn-active-color) !important;\n  background-color: var(--main-color) !important;\n  border-color: none !important;\n}\n.dropdown-item:active {\n  color: var(--bs-dropdown-link-active-color);\n  text-decoration: none;\n  background-color: white !important;\n}\n.navbar-toggler:focus{\n  box-shadow: none !important;\n  border: 2px solid black;\n}\n\n/* div::-webkit-scrollbar {\n  display: none;\n} */\n\n.form-check-input:checked{\n  background-color: #AE0000;\n  border-color: #AE0000;\n}\ntable thead tr th{\n  padding: 20px 0 !important;\n}\ntable thead{\n  background: #EDEDED;\n}\n.btn-close{\n  display: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
