// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFound_div_data__C6gml{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
}

.NotFound_div_data__C6gml p{
    font-size: 18px;
    font-weight: 500;
    color: #989898;
}`, "",{"version":3,"sources":["webpack://./src/Component/NotFound/NotFound.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,gCAAgC;AACpC;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".div_data{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    border-bottom: 1px solid #d9d9d9;\n}\n\n.div_data p{\n    font-size: 18px;\n    font-weight: 500;\n    color: #989898;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"div_data": `NotFound_div_data__C6gml`
};
export default ___CSS_LOADER_EXPORT___;
