import React, { useEffect, useState } from 'react'
import styles from './AppModal.module.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../../Apis/BaseUrl';
import axios from 'axios';
import { fileChecker } from '../../../Component/FileChecker/fileChecker';
import Cookies from 'js-cookie';
import { Form } from 'react-bootstrap';
import AsyncSelect from 'react-select/async'
const AddObject = ({ isOpen, closeModal, data }) => {

    const [name, setName] = useState('')
    const [place, setPlace] = useState('')
    const [loading, setLoading] = useState(false)


    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    let token = profileToken.token;

    const handleDataUpload = async () => {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('place', place.value);
        
        // variations.forEach(element => {
        //     formData.append(`variations.material`,element._id );    
        //     formData.append(`variations.variationName`,element.type );    
        // });

        
        const register = `${BASE_URL}/visualizer/object`

        const myToast = toast.loading('Please Wait...')
        setLoading(true)
        try {
            const response = await axios.post(register, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            })

            if (!response.data.success) {
                throw new Error(response.data.message)

            }
            toast.update(myToast, {
                render: response.data.message,
                type: 'success',
                isLoading: false,
                autoClose: 1500
            });
            closeModal()

            data()
            setPlace('')
            setLoading('')
        } catch (error) {
            console.error('Error while uploading data:', error);

            toast.update(myToast, {
                render: error.message,
                type: 'error',
                isLoading: false,
                autoClose: 1500
            });
        }
        finally {
            setLoading(false)
        }
    };


    const getData = async (query = '') => {
        try {
            let register = `${BASE_URL}/visualizer/place?limit=10&page=1&search=${query}`

            let res = await axios.get(register, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            return res.data.data.docs.map((element) => ({ label: element.name, value: element._id }))
        }
        catch (error) {
            console.error('Error while uploading data:', error);

            toast.error({
                render: error.message,
                type: 'error',
                isLoading: false,
                autoClose: 1500
            });
        }
    }

    const handlePlaceValue = value => {
        setPlace(value)
    }


    return (
        <React.Fragment>
            <Modal show={isOpen} onHide={closeModal}>
                <Modal.Header>
                    <Modal.Title className={styles.modal_title}>Add Object</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-3'>
                    <div className='mx-auto' md={9}>
                        <div className={styles.main_section}>
                            <Form>
                                <div className={styles.main_div}>
                                    <label htmlFor="">Name</label>
                                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className={styles.main_div}>
                                    <label htmlFor="">Place</label>
                                    <AsyncSelect onInputChange={(text) => getData(text)} cacheOptions getOptionValue={e => e.value} loadOptions={getData} value={place} defaultOptions onChange={handlePlaceValue} />
                                </div>
                            </Form>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div className={styles.model_button}>
                        <button className='bg-white text-black border' variant="secondary" onClick={closeModal}> Cancel  </button>
                        <button disabled={loading} variant="primary" onClick={handleDataUpload}>{loading ? 'Loading' : 'Add Object'}</button>
                    </div>

                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default AddObject