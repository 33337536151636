import React from 'react'
import styles from './edit.module.css'
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import { Col, Form, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

const Editseller = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Edit Sellers</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />
            {/* -----------------hero-section---------------- */}

            <div className={styles.hero_Section}>
                <Row className={styles.categories}>
                    <Col className='mx-auto' md={9}>
                        <div className={styles.product_info}>
                            <div className={styles.product_info_section}>
                                <h6>Basic Info</h6>
                            </div>

                            <div className={styles.main_section}>
                                <Form>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="inputField"> Name</label>
                                        <input type="text" id="inputField_3" required placeholder='Filon Asset Store' />
                                    </div>
                                    <div className={`${styles.file}`}>
                                        <label for="inputField_2">Seller Logo</label>
                                        <input type="file" id="inputField_5" placeholder='upload logo' />
                                    </div>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="inputField_3">Seller phone</label>
                                        <input type="number" id="inputField_3" required placeholder='610-628-1815' />
                                    </div>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="inputField_4">Seller Address</label>
                                        <input type="address" id="inputField_4" required placeholder='3475 Jody Road Philadelphia, PA 19108' />
                                    </div>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="inputField_5">Meta Title</label>
                                        <input type="number" id="inputField_5" required placeholder='Filon Asset Store' />
                                    </div>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="inputField_6">Meta Description</label>
                                        <input type="telnumber" id="inputField_6" required placeholder='It is a long established' />
                                    </div>


                                    <div className={styles.save_btn}>
                                        <button>Save</button>
                                    </div>

                                </Form>

                            </div>
                        </div>
                        <div className={`${styles.product_info} ${styles.tax_number}`}>
                            <div className={styles.product_info_section}>
                                <h6>Tax Information</h6>
                            </div>
                            <div className={styles.main_section}>
                                <Form>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="info"> Tax Information</label>
                                        <input type="text" id="info" required />
                                    </div>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="gst"> GST Number</label>
                                        <input type="number" id="gst" required />
                                    </div>

                                    <div className={styles.save_btn}>
                                        <button>Save</button>
                                    </div>

                                </Form>

                            </div>
                        </div>
                        <div className={`${styles.product_info} ${styles.tax_number}`}>
                            <div className={styles.product_info_section}>
                                <h6>Bank Detail</h6>
                            </div>
                            <div className={styles.main_section}>
                                <Form>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="bank_name"> Bank  Name</label>
                                        <input type="text" id="bank_name" required />
                                    </div>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="account"> Account Number</label>
                                        <input
                                            type="number"
                                            required
                                            id='account'
                                        />
                                    </div>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="codde"> IFSC Code</label>
                                        <input type="text" id="codde" required />
                                    </div>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="branch"> Branch</label>
                                        <input type="text" id="branch" required />
                                    </div>

                                    <div className={styles.save_btn}>
                                        <button>Save</button>
                                    </div>

                                </Form>

                            </div>
                        </div>
                        <div className={`${styles.product_info} ${styles.tax_number}`}>
                            <div className={styles.product_info_section}>
                                <h6>Social Media Link</h6>
                            </div>
                            <div className={styles.main_section}>
                                <Form>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="Facebook"> Facebook</label>
                                        <div>
                                            <input type="text" id="Facebook" required />
                                            <span>Insert link with https</span>
                                        </div>
                                    </div>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="Instagram"> Instagram</label>

                                        <div>
                                            <input type="text" id="Instagram" required />
                                            <span>Insert link with https</span>
                                        </div>
                                    </div>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="Twitter"> Twitter</label>
                                        <div>
                                            <input type="text" id="Twitter" required />
                                            <span>Insert link with https</span>
                                        </div>
                                    </div>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="Youtube"> Youtube</label>
                                        <div>
                                            <input type="text" id="Youtube" required />
                                            <span>Insert link with https</span>
                                        </div>
                                    </div>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="Google"> Google</label>
                                        <div>
                                            <input type="text" id="Google" required />
                                            <span>Insert link with https</span>
                                        </div>
                                    </div>

                                    <div className={styles.save_btn}>
                                        <button>Save</button>
                                    </div>

                                </Form>

                            </div>
                        </div>
                        <div className={`${styles.product_info} ${styles.tax_number}`}>
                            <div className={styles.product_info_section}>
                                <h6>Pickup address</h6>
                            </div>
                            <div className={styles.main_section}>
                                <Form>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="address">Address</label>
                                        <textarea id="address" rows="4" cols="50"></textarea>
                                    </div>
                                    <div className={`${styles.input_container_unit}`}>
                                        <label for="hours"> Available Hours </label>
                                        <input type="text" id="hours" required />
                                    </div>

                                    <div className={styles.save_btn}>
                                        <button>Save</button>
                                    </div>

                                </Form>

                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

        </React.Fragment>
    )
}

export default Editseller