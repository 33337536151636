import React from 'react'
import styles from './role.module.css'
import { Helmet } from 'react-helmet'
import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import ContainerSection from '../../Component/ContainerSection/ContainerSection'

const Role = () => {
    const navigate = useNavigate()
    return (
        <React.Fragment>
            <React.Fragment>
                <Helmet>
                    <title>Role</title>
                </Helmet>
                <TopNavbar />
                <NavbarSection />

                {/* -----------------hero-section---------------- */}

                <div className={styles.hero_Section}>
                    <ContainerSection />

                    <div className={styles.mainSection}>
                        <Row className='w-100'>
                            <Col>
                                <h6>All Role</h6>
                            </Col>


                        </Row>
                    </div>


                    {/* -------------table_section-------------- */}

                    <div className={styles.main_table}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td> George M. Winters </td>
                                    <td>
                                        <div className={styles.image_section}>
                                            <img onClick={() => navigate(`/rolepermission/permission`)} src="/image/edit.png" alt="edit" />
                                            <img src="/image/delete.png" alt="edit" />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        </React.Fragment>
    )
}

export default Role