import React from 'react'
import './nav.css'
import { NavDropdown, Navbar, Nav } from 'react-bootstrap';
import { MarketingData, NavData, StaffData, secondMenu, sellerData } from './NavData/NavData';
import { NavLink, useLocation } from 'react-router-dom';

const NavbarSection = () => {

    const {pathname} = useLocation()


    return (
        <React.Fragment>
            <Navbar expand="lg" className='main_nav'>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className='align-items-center'>
                       <NavLink className='childtext' to="/dashboard">Dashboard</NavLink>
                        <NavDropdown className={`navDropdown ${pathname.includes('product') ? "active" : ""} `} title="Products" id="basic-nav-dropdown">
                            {NavData.map((item, index) => (
                                <NavDropdown.Item key={index} className='customChildStyle text-black  mx-0' href={item.link}>
                                    {item.title}
                                </NavDropdown.Item>
                            ))}
                        </NavDropdown>
                        <NavDropdown className={`navDropdown ${pathname.includes('sales') ? "active" : ""} `}  title="Sales" id="basic-nav-dropdown">
                            {secondMenu.map((item, index) => (
                                <NavDropdown.Item key={index} className='customChildStyle text-black mx-0' href={item.link}>
                                    {item.title}
                                </NavDropdown.Item>
                            ))}
                        </NavDropdown>
                        <NavLink className='childtext' to="/refund">Refunds</NavLink>
                        <NavLink className='childtext' to="/customer">Customers</NavLink>
                        <NavLink className='childtext' to="/seller">Sellers</NavLink>
                        {/* <NavDropdown className='navDropdown' title="Sellers" id="basic-nav-dropdown">
                            {sellerData.map((item, index) => (
                                <NavDropdown.Item key={index} className='customChildStyle text-black' href={item.link}>
                                    {item.title}
                                </NavDropdown.Item>
                            ))}
                        </NavDropdown> */}
                        {/* <Nav.Link className='childtext' href="#home">Upload Files</Nav.Link> */}
                        {/* <Nav.Link className='childtext' href="#home">Reports</Nav.Link> */}
                        <NavLink className='childtext' to="/blogs">Blogs System</NavLink>
                        <NavLink className='childtext' to="/support">Support</NavLink>
                        <NavLink className='childtext' to="/apperance">Appearance</NavLink>
                        
                        {/* <NavDropdown className='navDropdown' title="Staffs" id="basic-nav-dropdown">
                            {StaffData.map((item, index) => (
                                <NavDropdown.Item key={index} className='customChildStyle text-black' href={item.link}>
                                    {item.title}
                                </NavDropdown.Item>
                            ))}
                        </NavDropdown> */}
                        <NavDropdown title="Marketing" className={`navDropdown ${pathname.includes('marketing') ? "active" : ""} `}  id="basic-nav-dropdown">
                            {MarketingData.map((item, index) => (
                                <NavDropdown.Item key={index} className='customChildStyle text-black  mx-0' href={item.link}>
                                    {item.title}
                                </NavDropdown.Item>
                            ))}
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>

            </Navbar>
        </React.Fragment>
    )
}

export default NavbarSection