import React, { useEffect, useRef, useState } from 'react'
import classes from './SupportDetails.module.css'
import Container from "../../../UI/Container/Container"
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import Cookies from 'js-cookie'
import ChatTextarea from '../../../Component/ChatCompoments/ChatTextarea/ChatTextarea'
import ChatMessage from '../../../Component/ChatCompoments/ChatMessage/ChatMessage'
import Moment from 'react-moment'
import ToasterUpdate from '../../../Component/Toaster/ToasterUpdate'
import { BASE_URL } from '../../../Apis/BaseUrl'
import { Helmet } from 'react-helmet'
import TopNavbar from '../../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../../Component/NavbarSection/NavbarSection'

const SupportDetails = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})
    const [myChat, setMyChat] = useState([])

    const navigate = useNavigate()
    const { id } = useParams()
    // console.log(id)
    const tutToken = Cookies.get("v&b_token")
    const getTutToken = JSON.parse(tutToken)
    const token = getTutToken.token


    const reqData = () => {
        const register = `${BASE_URL}/ticket/${id}/show`;
        axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token} `,
            },
        })
            .then((res) => {
                setData(res.data.data)
                // console.log(res.data.data)
                setMyChat(res.data.data.responds)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        reqData()
    }, [])

    const closeChat = async () => {
        const register = `${BASE_URL}/ticket/${id}/close`
        const myToast = toast.loading('Please Wait...')
        setLoading(true)
        try {
            const response = await axios.put(register, null, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
            console.log(response, "pppppppp")
            if (!response.data.success) {
                throw new Error(response.data.message)
            }
            ToasterUpdate(myToast, response.data.message, "success")
            reqData()
            navigate('/support')
        } catch (error) {
            console.error('Error while uploading data:', error);
            ToasterUpdate(myToast, error.message, "error")
        }
        finally {
            setLoading(false)
        }
    };
    const reopenChat = async () => {
        const register = `${BASE_URL}/ticket/${id}/open`
        const myToast = toast.loading('Please Wait...')
        setLoading(true)
        try {
            const response = await axios.put(register, null, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
            console.log(response, "pppppppp")
            if (!response.data.success) {
                throw new Error(response.data.message)
            }
            ToasterUpdate(myToast, response.data.message, "success")
            reqData()
            // navigate('/support')
        } catch (error) {
            console.error('Error while uploading data:', error);
            ToasterUpdate(myToast, error.message, "error")
        }
        finally {
            setLoading(false)
        }
    };

    const myDivRef = useRef(null);

    const scrollToBottom = () => {
        if (myDivRef.current) {
            myDivRef.current.scrollTop = myDivRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    });
    const props = {
        setMyChat,
        myChat,
        data,
        reqData,
        url: 'ticket'
    }
    // console.log(data?.ticketDetails?.status)
    return (
        <React.Fragment>
            <Helmet>
                <title>Ticket Details</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection/>
            <Container cls={classes.container}>
                <div className={classes.header}>
                    <div>
                        <h3 className='mb-0'>Ticket Details</h3>
                        <span className='fw-medium'># {data?.ticketId}</span>
                    </div>
                    {data?.status === 'closed' && 
                    <button className={classes.close_btn} click={true} onClick={reopenChat}>Re-Open Chat</button> }

                    {data?.status === 'open' &&
                    <button className={classes.close_btn} click={true} onClick={closeChat}>Close Chat</button>}
  {/* <select>
                    <option onClick={closeChat}>Close Chat</option>
                    <option value="2">Mark 2</option>
                </select> */}
                </div>
                <div className={classes.middle}>
                    <div className={classes.row}>
                        <p className='mb-0'>Subject</p>
                        <span>{data?.subject}</span>
                    </div>
                    <div className={classes.row}>
                        <p className='mb-0'>Date Created</p>
                        <span><Moment format="YYYY/MM/DD">{data?.createdAt}</Moment></span>
                    </div>
                    <div className={classes.row}>
                        <p className='mb-0'>User</p>
                        <span>{data?.createdBy?.name}</span>
                    </div>
                    <div className={classes.row}>
                        <p className='mb-0'>Priority</p>
                        <span>{data?.priority}</span>
                    </div>
                    <div className={classes.row}>
                        <p className='mb-0'>Email ID</p>
                        <span>{data?.createdBy?.email}</span>
                    </div>
                    <div className={classes.row}>
                        <p className='mb-0'>Phone Number</p>
                        <span>{data?.createdBy?.phone}</span>
                    </div>
                </div>
                <Container cls={classes.container_css}>
                    <div ref={myDivRef} style={data?.status == 'closed' ? { height: '100%' } : {}} className={classes.chats_container}>
                        {myChat?.map((element, index) => (
                            <ChatMessage key={index} data={element} />
                        ))}
                    </div>
                    {data?.status !== 'closed' &&
                        <ChatTextarea {...props} />}
                </Container>
            </Container>
        </React.Fragment>
    )
}

export default SupportDetails