// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FileLabel_input_container__acYxD {
    display: flex;
    position: relative;
    width: 100% ;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
}
.FileLabel_input_container__acYxD > input {
    position: absolute;
    visibility: hidden;
    cursor: pointer;
}
.FileLabel_input_container__acYxD > label {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    border-right: 1px solid #d9d9d9;
    font-size: 14px;
    font-weight: 400;
    background: #f9f9f9;
}
.FileLabel_input_container__acYxD > div{
    display: flex;
    align-items: center;
    padding-inline: 15px;
    font-size: 14px;
    color: #989898;
    font-weight: 400;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Component/FileLabel/FileLabel.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,+BAA+B;IAC/B,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,oBAAoB;IACpB,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".input_container {\n    display: flex;\n    position: relative;\n    width: 100% ;\n    border: 1px solid #d9d9d9;\n    border-radius: 5px;\n    overflow: hidden;\n    cursor: pointer;\n}\n.input_container > input {\n    position: absolute;\n    visibility: hidden;\n    cursor: pointer;\n}\n.input_container > label {\n    height: 40px;\n    display: flex;\n    align-items: center;\n    padding: 0px 10px;\n    border-right: 1px solid #d9d9d9;\n    font-size: 14px;\n    font-weight: 400;\n    background: #f9f9f9;\n}\n.input_container > div{\n    display: flex;\n    align-items: center;\n    padding-inline: 15px;\n    font-size: 14px;\n    color: #989898;\n    font-weight: 400;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input_container": `FileLabel_input_container__acYxD`
};
export default ___CSS_LOADER_EXPORT___;
