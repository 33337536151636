import React, { useEffect, useState } from 'react'
import styles from './section.module.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../../Apis/BaseUrl';
import axios from 'axios';
import { fileChecker } from '../../../Component/FileChecker/fileChecker';
import Cookies from 'js-cookie';
import FallProduct from '../../../Component/FallbackImgae/FallProduct';
import FileLabel from '../../../Component/FileLabel/FileLabel';

const EditBrand = ({ isOpen, closeModal, data , id}) => {
    console.log(id)
    const [selectedImage, setSelectedImage] = useState(null);
const[name, setName] = useState('')
const[metaTitle, setMetaTitle] = useState('')
const[metaDescription, setMetaDescription] = useState('')
const [file, setFile] = useState('')
const[loading, setLoading] = useState(false)

const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
};

    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;

    const getDataById = async () => {

        let register = `${BASE_URL}/brand/${id}`
// console.log(id)
        // console.log(register)
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        console.log(res)
        setName(res.data.data.name)
        setFile(res.data.data.logoUrl)
       
    }
    useEffect(() => {
        getDataById()
    }, [])

    const handleDataUpload = async () => {
        // alert('clicked')
        // console.log("yyyy")
        const formData = new FormData();
    //    if (name) {
        formData.append('name', name);
    //    }
       if (metaTitle) {
           formData.append('meta_title', metaTitle);
       }
       if (metaTitle) {
        formData.append('meta_description', metaDescription);
       }
        if (selectedImage) {
            let checkFile = fileChecker(selectedImage)
            if (!checkFile.value) {
                toast.error(checkFile.message)
                return 
            }
            formData.append('logo', selectedImage);
        }
        const register = `${BASE_URL}/brand/${id}`

        const myToast = toast.loading('Please Wait...')
        setLoading(true)
        try {
            const response = await axios.put(register, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            })

            console.log(response)
            if (!response.data.success) {
                throw new Error(response.data.message)
            }
            toast.update(myToast, {
                render: response.data.message,
                type: 'success',
                isLoading: false,
                autoClose: 1500
            });
            data()
        } catch (error) {
            console.error('Error while uploading data:', error);
        
            toast.update(myToast, {
                render: error.message,
                type: 'error',
                isLoading: false,
                autoClose: 1500
            });
        }
        finally {
            closeModal()
            setName("")
            setSelectedImage(null)
            setLoading(false)
        }
    };

    return (
        <React.Fragment>
            <Modal show={isOpen} onHide={closeModal}>
                <Modal.Header>
                    <Modal.Title style={{fontSize:"18px"}}>Edit Brand</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-3'>
                    {/* <div className={styles.main_section}>
                        <label for="name">Add new Brand</label>
                        <input type="text" id='name' />
                    </div> */}
                    <div className={styles.main_section}>
                        <label for="name2">Name</label>
                        <input type="text" value={name} onChange={(e)=> setName(e.target.value)} id='name2' />
                    </div>
                  
                    <div className={styles.main_section}>
                        <label for="Image">Upload Image </label>
                        <FileLabel  id="attachment" file_label={styles.file_label}  setter={handleImageChange}
                                            //  onChange={(e) => setSelectedImage(e.target.files[0])} 
                                            placeholder='upload logo' />
                        {/* <input className='p-0' onChange={(e) => setSelectedImage(e.target.files[0])} type="file" id='Image' /> */}
                    </div>
                    <div className='d-flex align-items-start justify-content-start'>
                                        <FallProduct imgData={file} cls={styles.img2} />
                                    </div>
                    <div className={styles.main_section}>
                        <label for="name2">Meta Title</label>
                        <input type="text" value={metaTitle} onChange={(e)=> setMetaTitle(e.target.value)} id='name2' />
                        
                    </div>
                    <div className={styles.main_section}>
                        <label for="name2">Meta Description</label>
                        <input type="text" value={metaDescription} onChange={(e)=> setMetaDescription(e.target.value)} id='name2' />
                       
                    </div>
                    {/* <div className={styles.main_section}>
                        <label for="tittle">Meta Title</label>
                        <input type="text" id='tittle' />
                    </div>
                    <div className={styles.main_section}>
                        <label for="meta">Meta Description</label>
                        <input type="text" id='meta' />
                    </div> */}
                </Modal.Body>
                <Modal.Footer>
                    <div className={styles.model_button}>
                        <Button className='bg-white text-black border' variant="secondary" onClick={closeModal}> Cancel  </Button>
                        <Button variant="primary" onClick={handleDataUpload}>  Save </Button>
                    </div>

                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default EditBrand