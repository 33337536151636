import './App.css';
import React from 'react';
import Brands from './Pages/Brands/Brands';
import Dashboard from './Pages/Dashboard/Dashboard';
import AllProduct from './Pages/AllProduct/AllProduct';
import BulkImport from './Pages/BulkImport/BulkImport';
import Categories from './Pages/Categories/Categories';
import Attributes from './Pages/Attributes/Attributes';
import AddNewProduct from './Pages/AddNew/AddNewProduct';
import { Route, Routes, BrowserRouter, useLocation } from "react-router-dom";
import Colors from './Pages/Colors/Colors';
import ProductReview from './Pages/ProductReview/ProductReview';
import AddCategories from './Pages/Categories/AddNewCategories/AddCategories';
import AllOrder from './Pages/Sales/AllOrder';
import Payments from './Pages/Payments/Payments';
import OrderDetails from './Pages/OrderDetails/OrderDetails';
import RefundRequest from './Pages/Refund/RefundRequest';
import Sellers from './Pages/Sellers/Sellers';
import Editseller from './Pages/EditSeller/Editseller';
import Blogs from './Pages/Blogs/Blogs';
import EditBlogs from './Pages/Blogs/EditBlogs/EditBlogs';
import Support from './Pages/Support/Support';
import Ticket from './Pages/Support/SupportTicket/Ticket';
import Staff from './Pages/Staff/Staff';
import Role from './Pages/RolePermission/Role';
import Permission from './Pages/RolePermission/PermissionData/Permission';
import Customer from './Pages/Customer/Customer';
import Apperance from './Pages/Apperance/Apperance';
import Flash from './Pages/FlashDetails/Flash';
import EditFlash from './Pages/EditFlashDeals/EditFlash';
import Coupons from './Pages/Coupons/Coupons';
import Subscribe from './Pages/SEmail/Subscribe';
import { ToastContainer } from 'react-toastify';
import SignInPage from './Pages/UserSignUp/SignInPage';
import 'react-toastify/dist/ReactToastify.css';
import PrivateComponent from './privateComponents/PrivateComponent';
import EditaCategories from './Pages/Categories/AddNewCategories/EditCategory';
import EditCategories from './Pages/Categories/AddNewCategories/EditCategory';
import AddBlogs from './Pages/Blogs/EditBlogs/AddBlogs';
import SupportDetails from './Pages/Support/SupportDetails/SupportDetails';
import AppearanceMainPage from './Pages/Apperance/AppearanceMainPage';
import ContactPage from './Pages/SEmail/ContactPage';
import WithdrawalPayment from './Pages/Payments/WithDrawalPayment';
import ProductReport from './Pages/ProductReport/ProductReport';
import DraftOrder from './Pages/DraftOrder/DraftOrder';
import Draftdetail from './Pages/DraftOrder/DraftDetail/Draftdetail';
import Visulizations from './Pages/Visulizations/Visulizations';

const App = () => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <React.Fragment>
        <ToastContainer
        position='top-right'
        autoClose='1000'
        closeOnClick
        pauseOnHover
        draggable
        theme="light"
      /> 
      {path.includes("user-") || path === '/'  ? (
        <Routes>
          <Route path="/" element={<SignInPage/>} />
        </Routes>
      ) : (
        <>
      {/* <BrowserRouter> */}
        <Routes>
        <Route element={<PrivateComponent />}>

          <Route exact path='/dashboard' element={<Dashboard />} />
          <Route path='/product/allproduct' element={<AllProduct />} />
          <Route path='/product/addnewproduct' element={<AddNewProduct />} />
          <Route path='/bultimport' element={<BulkImport />} />
          <Route path='/product/categories' element={<Categories />} />
          <Route path='/product/addcategories' element={<AddCategories />} />
          <Route path='/product/editcategories/:id' element={<EditCategories/>} />
          <Route path='/product/brands' element={<Brands />} />
          <Route path='/attributes' element={<Attributes />} />
          <Route path='/colors' element={<Colors />} />
          <Route path='/customer' element={<Customer />} />
          <Route path='/product/productreview' element={<ProductReview />} />
          <Route path='/product/productreport' element={<ProductReport />} />
          <Route path='/sales/allorder' element={<AllOrder />} />
          <Route path='/sales/allorder/orderdetails/:id' element={<OrderDetails />} />
          <Route path='/sales/draft' element={<DraftOrder />} />
          <Route path='/sales/draft/draftdetail/:id' element={<Draftdetail />} />
          <Route path='/sales/payment' element={<Payments />} />
          <Route path='/sales/withdraw' element={<WithdrawalPayment />} />
          <Route  path='/refund' exact element={<RefundRequest />} />
          <Route path='/seller' element={<Sellers />} />
          <Route path='/seller/editseller' element={<Editseller />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='/blogs/editblogs/:id' element={<EditBlogs />} />
          <Route path='/blogs/addblogs' element={<AddBlogs />} />
          <Route path='/support' element={<Support />} />
          <Route path='/support/detail/:id' element={<SupportDetails />} />
          <Route path='/allstaff' element={<Staff />} />
          <Route path='/rolepermission' element={<Role />} />
          <Route path='/rolepermission/permission' element={<Permission />} />
          <Route path='/apperance' element={<AppearanceMainPage />} />
          <Route path='/appearance/detail' element={<Apperance />} />
          <Route path='/flashdeals' element={<Flash />} />
          <Route path='/flashdeals/editflash' element={<EditFlash />} />
          <Route path='/marketing/coupons' element={<Coupons />} />
          <Route path='/marketing/subscribemail' element={<Subscribe />} />
          <Route path='/marketing/contact' element={<ContactPage />} />
          <Route path='/visualize' element={<Visulizations />} />
          {/* <Route path='/add-model' element={<AddModel />} /> */}
          {/* <Route path='/models' element={<Models />} />
          <Route path='/add-model' element={<AddModel />} /> */}
       </Route>
        </Routes>
      {/* </BrowserRouter> */}
      </> )}
    </React.Fragment>

  );
};

export default App;


