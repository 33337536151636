import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from '../../../Apis/BaseUrl';
import ToasterUpdate from '../../../Component/Toaster/ToasterUpdate';
import styles from './AppModal.module.css';
import ImageUploader from '../ImageUploader';

const UniversalSliderEditModal = ({ handleClose, getData, url, modalStatus, id }) => {
    const [sequence, setSequence] = useState(1);
    const [loading, setLoading] = useState(false);
    const [youtubeUrl, setYoutubeUrl] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);

    const [mobileBanner, setMobileBanner] = useState('')
    const [currentFile, setCurrentFile] = useState('')
    const [currentMobileBanner, setCurrentMobileBanner] = useState('')

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleImageSelect = (setterFunc, content) => {
        setterFunc(content);
    };

    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    let token = profileToken.token;

    const handleDataUpload = async () => {
        const formData = new FormData();
        formData.append('sequence', sequence);
        formData.append('contentType', selectedOption);

        if (selectedOption === 'image' && selectedImage) {
            formData.append('image', selectedImage);
        } else if (selectedOption === 'youtube') {
            formData.append('image', youtubeUrl);
        }

        if (mobileBanner) {
            formData.append('mobile_banner', mobileBanner)
        }

        const register = `${BASE_URL}/${url}/${id}/edit`;

        const myToast = toast.loading('Please Wait...');
        setLoading(true);
        try {
            const response = await axios.put(register, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (!response.data.success) {
                ToasterUpdate(myToast, response.data.message, 'error');
                return;
            }
            toast.update(myToast, {
                render: response.data.message,
                type: 'success',
                isLoading: false,
                autoClose: 1500,
            });
            getData();
            handleClose();
        } catch (error) {
            console.error('Error while uploading data:', error);

            toast.update(myToast, {
                render: error.message,
                type: 'error',
                isLoading: false,
                autoClose: 1500,
            });
        } finally {
            setSequence('');
            setSelectedImage(null);
            setLoading(false);
        }
    };


    const fetchData = async () => {
        try {
            let register = `${BASE_URL}/${url}/${id}/show`

            // console.log(register)
            let res = await axios.get(register, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            setSequence(res.data.data?.sequence)
            // setSelectedImage(res.data.data?.url)
            setYoutubeUrl(res.data.data.url)
            setSelectedOption(res.data.data?.contentType)
            if (res.data.data?.contentType === 'image') {
                setCurrentFile(res.data.data?.url)
            }
            if (res.data.data?.mobile_banner_url) {
                setCurrentMobileBanner(res.data.data?.mobile_banner_url)
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <React.Fragment>
            <Modal show={modalStatus} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title className={styles.modal_title}>Edit Slider</Modal.Title>
                </Modal.Header>
                <Modal.Body className="mb-3">
                    <div className="mx-auto" md={9}>
                        <div className={styles.main_section}>
                            <Form>
                                <div className={styles.main_div}>
                                    <label htmlFor="">Sequence</label>
                                    <Form.Select
                                        required
                                        aria-label="Default select example"
                                        value={sequence}
                                        onChange={(e) => setSequence(e.target.value)}
                                    >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                    </Form.Select>
                                </div>
                                <Form.Group className={`${styles.main_div}`}>
                                    <Form.Label>Banner</Form.Label>
                                    <Form.Select
                                        style={{ color: '#989898' }}
                                        value={selectedOption}
                                        onChange={handleOptionChange}
                                        required
                                        aria-label="Default select example"
                                    >
                                        <option value="">Select an option</option>
                                        <option value="youtube">Video (YouTube URL)</option>
                                        <option value="image">File</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className={`${styles.main_div}`}>
                                    {selectedOption === 'youtube' && (
                                        <>
                                            <Form.Label>YouTube URL:</Form.Label>
                                            <div className={styles.video}>
                                                <input
                                                    type="text"
                                                    className="w-100"
                                                    style={{
                                                        height: '45px',
                                                        border: '1px solid #d9d9d9',
                                                        borderRadius: '5px',
                                                        outline: 'none',
                                                        color: '#989898',
                                                    }}
                                                    onChange={(e) => setYoutubeUrl(e.target.value)}
                                                    id="videoUrl"
                                                    name="videoUrl"
                                                />
                                            </div>
                                        </>
                                    )}
                                </Form.Group>
                                {selectedOption === 'image' && (
                                    <ImageUploader currentFile={currentFile} onImageSelect={handleImageSelect.bind('', setSelectedImage)} />
                                )}
                                <ImageUploader currentFile={currentMobileBanner} label={'Upload Mobile Banner'} onImageSelect={handleImageSelect.bind('', setMobileBanner)} />
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={styles.model_button}>
                        <button
                            className="bg-white text-black border"
                            variant="secondary"
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                        <button variant="primary" disabled={loading} onClick={handleDataUpload}>
                            Add Slider
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default UniversalSliderEditModal;
