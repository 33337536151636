import React, { useEffect, useState } from 'react'
import styles from './modal.module.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { BASE_URL } from '../../../Apis/BaseUrl';
import axios from 'axios';

const RefundModal = ({ isOpen, closeModal, id }) => {
    console.log(id)
    const[data, setData] = useState([])

    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;

    const getData = async () => {
     
        let register = `${BASE_URL}/refund/${id}/show`
    
        // console.log(register)
        let res = await axios.get(register, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
        console.log(res.data.data)
        setData(res.data.data)
      }

      useEffect(() => {
        getData()
    }, [])

    return (
        <React.Fragment>
            <Modal show={isOpen} onHide={closeModal}>

                <Modal.Title> <div className={styles.header}>
                    <div>
                        <h6>Review Detail</h6>
                    </div>
                    {/* <div className="d-flex justify-content-end gap-3">
                        <h6>Status</h6>
                        <h6>Active</h6>
                    </div> */}
                </div>
                    <hr />
                </Modal.Title>

                <Modal.Body className='py-0'>
                    <div className={`${styles.main_section}`}>
                        <h6 className='mb-0'>Subject</h6>
                        <p className='mb-0'>{data?.subject}</p>
                    </div>
                    <div className={styles.main_section}>
                        <h6 className='mb-0'>Description</h6>
                        <p className='mb-0'>{data?.message}</p>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div className={styles.model_button}>
                        <Button variant="primary" onClick={closeModal}>Close </Button>
                    </div>

                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default RefundModal