import React, { useEffect, useState } from 'react'
import styles from './Apperance.module.css';
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import ContainerSection from '../../Component/ContainerSection/ContainerSection'
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../Apis/BaseUrl';
import axios from 'axios';
import NewPagination from '../../Component/NewPagination/NewPagination';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import CardSection from './CardSection';
import AddBanner from './Modal/AddBanner';
import AddSlider from './Modal/AddSlider';
import OverallBannerSlider from '../../Component/OverallBannerSlider/OverallBannerSlider';

const AppearanceMainPage = () => {
    const [sliderData, setSliderData] = useState([])
    const [show, setShow] = useState(false)
    const [show1, setShow1] = useState(false)
    const [data, setData] = useState([])
    const [page, setPage] = useState(1)
    const [pageInfo, setPageInfo] = useState({})
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    const handleShow = () => setShow(true)
    const closeModal = () => setShow(false)
    const handleShow1 = () => setShow1(true)
    const closeModal1 = () => setShow1(false)
    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;

    const getData = async () => {

        let register = `${BASE_URL}/banner?limit=${limit}&page=${page}&search=${search}`

        // console.log(register)
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        // console.log(res.data.data.docs)
        setData(res.data.data.docs)
        setPageInfo({ ...res.data.data, docs: null })
    }

    useEffect(() => {
        getData()
    }, [page, limit, search])

    const getSliderData = async () => {

        let register = `${BASE_URL}/slider?limit=${limit}&page=${page}&search=${search}`

        // console.log(register)
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        // console.log(res.data.data.docs)
        setSliderData(res.data.data.docs)
        setPageInfo({ ...res.data.data, docs: null })
    }

    useEffect(() => {
        getSliderData()
    }, [page, limit, search])


    return (
        <React.Fragment>
            <Helmet>
                <title>Apperance</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            {/* -----------------hero-section---------------- */}

            <div className={styles.hero_Section}>
                <div>
                    <div className='d-flex justify-content-between align-items-center mt-3'>
                        <h6 style={{ color: "#AE0000" }}>Banners</h6>
                        <button className='px-3 py-2 rounded-1 border-0' style={{ backgroundColor: "#AE0000", color: "white" }} onClick={handleShow}>Add Banner</button>
                    </div>
                    {data?.map((item) => (
                        <CardSection banner={true} data={item} func={getData} />
                    ))}

                </div>

                <div>
                    <div className='d-flex justify-content-between align-items-center mt-5'>
                        <h6 style={{ color: "#AE0000" }}>Sliders</h6>
                        <button className='px-3 py-2 rounded-1 border-0' style={{ backgroundColor: "#AE0000", color: "white" }} onClick={handleShow1}>Add Slider</button>
                    </div>
                    {sliderData?.map((item) => (
                        <CardSection slider={true} data={item} func={getSliderData} />
                    ))}
                </div>
                
                <OverallBannerSlider url={'groupbuyslider'} title={'Group Buy'} />
                <OverallBannerSlider url={'instabuildslider'} title={'Instabuild'} />
                <OverallBannerSlider url={'clearanceslider'} title={'Clearance'} />

            </div>
              

          
            {show && <AddBanner isOpen={show} closeModal={closeModal}
                data={getData}
            />}
            {show1 && <AddSlider isOpen={show1} closeModal={closeModal1}
                data={getSliderData}
            />}
        </React.Fragment>
    )
}

export default AppearanceMainPage