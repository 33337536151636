import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import styles from './Apperance.module.css'
import { Col, Form, Row } from 'react-bootstrap'
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'

const Apperance = () => {
        const [youtubeUrl, setYoutubeUrl] = useState('')

    const [slides, setSlides] = useState([{ heading: '', tagline: '', actionUrl: '', uploadImage: '' }]);
    const [showDeleteButton, setShowDeleteButton] = useState(false);

    const handleUploadClick = () => {
        document.getElementById("fileInput").click();
    };

    const handleAddMore = (e) => {
        e.preventDefault();
        setSlides([...slides, { heading: '', tagline: '', actionUrl: '', uploadImage: '' }]);
        setShowDeleteButton(true);
    };

    const handleDeleteNewFields = () => {
        setSlides([{ heading: '', tagline: '', actionUrl: '', uploadImage: '' }]);
        setShowDeleteButton(false);
    };

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const list = [...slides];
        list[index][name] = value;
        setSlides(list);
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Apperance</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            <div className={styles.hero_Section}>
                <Row className={styles.categories}>
                    <Col className='mx-auto' md={9}>

                        <div className={styles.main_section}>
                            <Form>
                                {slides.map((slide, index) => (
                                    <div key={index} style={{borderBottom:"1px solid #d9d9d9", margin:"20px 0"}}>
                                        <h5 className='fw-medium' style={{ color: "#AE0000" }}>Slides</h5>
                                        <div className={styles.main_div}>
                                            <label htmlFor="">Heading</label>
                                            <input
                                                type="text"
                                                name="heading"
                                                value={slide.heading}
                                                onChange={e => handleInputChange(index, e)}
                                            />
                                        </div>
                                        <div className={styles.main_div}>
                                            <label htmlFor="">Tagline</label>
                                            <input
                                                type="text"
                                                name="tagline"
                                                value={slide.tagline}
                                                onChange={e => handleInputChange(index, e)}
                                            />
                                        </div>
                                        <div className={styles.main_div}>
                                            <label htmlFor="">Call to Action Url</label>
                                            <input
                                                type="text"
                                                name="actionUrl"
                                                value={slide.actionUrl}
                                                onChange={e => handleInputChange(index, e)}
                                            />
                                        </div>
                                        <div className={styles.main_div}>
                                            <label htmlFor="">Upload Image</label>
                                            <div className={`${styles.upload_box} w-100 mt-0`} onClick={handleUploadClick}>
                                                <p className='mb-0 fw-medium' style={{ color: "#AE0000" }}>Upload Image only in jpeg and png</p>
                                                <input type="file" id="fileInput" accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" className='d-none' />
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <div className={styles.save_btn}>
                                    <button onClick={handleAddMore}>Add Another Slide</button>
                                    {showDeleteButton && <button onClick={handleDeleteNewFields}>Delete Slide</button>}
                                    <button>Save</button>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

export default Apperance;


