import React, { useEffect, useState } from 'react'
import styles from './AppModal.module.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../../Apis/BaseUrl';
import axios from 'axios';
import { fileChecker } from '../../../Component/FileChecker/fileChecker';
import Cookies from 'js-cookie';
import { Form } from 'react-bootstrap';

const UpdateMaterial = ({ isOpen, closeModal, data , getData }) => {
    const [type, setType] = useState('')
    const [pattern, setPattern] = useState('')
    const [color, setColor] = useState('')
    const [materialFile,setMaterialFile] = useState(null)
    const [loading, setLoading] = useState(false)


    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    let token = profileToken.token;

    const handleDataUpload = async () => {
        const formData = new FormData();
        formData.append('type', type);
        formData.append('pattern', pattern);
        formData.append('color', color);

        if (materialFile) {
            formData.append('materialFile',materialFile)
        }


        const register = `${BASE_URL}/visualizer/material/${data?._id}/edit`

        const myToast = toast.loading('Please Wait...')
        setLoading(true)
        try {
            const response = await axios.put(register, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            })

            if (!response.data.success) {
                throw new Error(response.data.message)

            }
            toast.update(myToast, {
                render: response.data.message,
                type: 'success',
                isLoading: false,
                autoClose: 1500
            });
              closeModal()

            getData()
            setColor('')
            setLoading('')
            setPattern('')
            setType('')
        } catch (error) {
            console.error('Error while uploading data:', error);

            toast.update(myToast, {
                render: error.message,
                type: 'error',
                isLoading: false,
                autoClose: 1500
            });
        }
        finally {
            setLoading(false)
        }
    };

    useEffect(()=>{
        setColor(data?.color)
        setPattern(data?.pattern)
        setType(data?.type)
    },[])

    return (
        <React.Fragment>
            <Modal show={isOpen} onHide={closeModal}>
                <Modal.Header>
                    <Modal.Title className={styles.modal_title}>Add Material</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-3'>
                    <div className='mx-auto' md={9}>
                        <div className={styles.main_section}>
                            <Form>
                                <div className={styles.main_div}>
                                    <label htmlFor="">Type</label>
                                    <input type="text" value={type} onChange={(e) => setType(e.target.value)} />
                                </div>
                                <div className={styles.main_div}>
                                    <label htmlFor="">Pattern</label>
                                    <input type="text" value={pattern} onChange={(e) => setPattern(e.target.value)} />
                                </div>
                                <div className={styles.main_div}>
                                    <label htmlFor="">Color</label>
                                    <input type="text" value={color} onChange={(e) => setColor(e.target.value)} />
                                </div>
                                <div className={styles.main_div}>
                                    <label htmlFor="">Material File</label>
                                    <input type="file" onChange={(e) => setMaterialFile(e.target.files[0])} />
                                </div>
                            </Form>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div className={styles.model_button}>
                        <button className='bg-white text-black border' variant="secondary" onClick={closeModal}> Cancel  </button>
                        <button disabled={loading} variant="primary" onClick={handleDataUpload}>{loading ? 'Loading' : 'Add Material'}</button>
                    </div>

                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default UpdateMaterial