import React, { useEffect, useRef, useState } from 'react'
import styles from './Draft.module.css'
import TopNavbar from '../../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../../Component/NavbarSection/NavbarSection'
import { Table } from 'react-bootstrap'
import "react-step-progress-bar/styles.css";
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import { BASE_URL } from '../../../Apis/BaseUrl'
import axios from 'axios'
import Moment from 'react-moment'

import FallProduct from '../../../Component/FallbackImgae/FallProduct'
import formatter from '../../../Component/NumberFormatter/formatter'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FormatNumber } from '../../../Component/FormatNumber/FormatNumber'
import { toast } from 'react-toastify'
import ToasterUpdate from '../../../Component/Toaster/ToasterUpdate'

const Draftdetail = () => {
    const [data, setData] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [shippingCharges, setShippingCharges] = useState(0)
    const { id } = useParams()

    const divRef = useRef(null);

    const handleDownload = () => {
        html2canvas(divRef.current).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210; // A4 size
            const imgHeight = canvas.height * imgWidth / canvas.width;
            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

            // Add styling
            const filename = 'downloaded.pdf';
            pdf.save(filename);
        });
    };
    const handleShow = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;
    const getData = async () => {

        let register = `${BASE_URL}/order-draft/${id}/show`

        // console.log(register)
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        console.log(res.data.data)
        setShippingCharges(res?.data?.data?.shippingCharge)
        setData(res.data.data)
    }

    useEffect(() => {
        getData()
    }, [])
    const steps = [
        { label: 'Order Placed', date: '' },
        { label: 'Processing', date: '' },
        { label: 'Shipped', date: '' },
        { label: 'Delivered', date: '' },
    ];
    const step1 = [
        { label: 'Order Placed', date: '' },
        { label: data?.orderStatus, date: '' },

    ];
    const step2 = [
        { label: 'Order Placed', date: '' },
        { label: 'Return', date: '' },

    ];
    let var1;
    let colr;
    // let colorDetails = {
    //     "pending": [0, "#55B87B"],
    //     "processing": [34, "#55B87B"],
    //     "shipped": [67, "#55B87B"],
    //     "delivered": [100, "#55B87B"],
    //     "cancelled": [100, "red"],
    //     "return": [100, "red"],
    //     "default": [0, 0]

    // }
    // var1 = colorDetails[data?.orderStatus || "default"][0] || 0
    // colr = colorDetails[data?.orderStatus || "default"][1] || 0

    if (data?.orderStatus == "pending") {
        var1 = 0
        colr = "#55B87B"
    } else if (data?.orderStatus == "processing") {
        var1 = 34
        colr = "#55B87B"
    } else if (data?.orderStatus == "shipped") {
        var1 = 67
        colr = "#55B87B"
    } else if (data?.orderStatus == "delivered") {
        var1 = 100
        colr = "#55B87B"
    } else if (data?.orderStatus == "cancelled") {
        var1 = 100
        colr = "red"
    } else if (data?.orderStatus == "return") {
        var1 = 100
        colr = "red"
    }
    else {
        var1 = 0
    }
    function stepFunc(stepsData) {
        return (
            stepsData.map((step, index) => (
                <div key={index} className={styles.label}>
                    {step.label}
                </div>
            ))
        )
    }
    const taxData = (a, b) => {
        let tx_data = a + b
        let finaldata = formatter.format(tx_data || 0)
        return finaldata
    }

    const handleShippingChargeChange = (e) => {
        const value = e.target.value;
        setShippingCharges(value === '' ? '' : Number(value)); // Allow empty string or number
    };
    const handleAddShipCharge = async () => {
        let register = `${BASE_URL}/order-draft/${id}/shippingCharge`
        const myToast = toast.loading('Please Wait...')
        // console.log(register)
        try {
            let res = await axios.put(register, { shippingCharge: shippingCharges }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token} `,
                },
            })

            if (!res.data.success) {
                ToasterUpdate(myToast, res.data.message, "error")
            }
            ToasterUpdate(myToast, res.data.message, "success")

        } catch (error) {
            // console.log(error)
            ToasterUpdate(myToast, error.message, "error")
        }

    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Order Draft Details</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            {/* --------------hero-section---------------- */}

            <div className={styles.hero_Section}>


                <div className={styles.address}>
                    <div>
                        <h6>Shipping Address</h6>

                        <div>
                            <p>{data?.customerId?.name}</p>
                            <p>{data?.customerId?.email}</p>

                            {data?.shippingAddress && <p> <span>{data?.shippingAddress}</span></p>
                            }

                        </div>
                    </div>
                    <div>
                        <h6>Billing Address</h6>

                        <div>
                            <p>{data?.customerId?.name}</p>
                            <p>{data?.customerId?.email}</p>
                            {data?.billingAddress && <p> <span>{data?.billingAddress}</span></p>}


                        </div>
                    </div>
                </div>


                <div className={styles.order_detail} div ref={divRef}>
                    <h6>Order Details</h6>

                    <div className={styles.list_section}>
                        <ul>
                            <li>Order Draft ID</li>
                            <li>Order Status</li>
                            <li>Shipping Status</li>
                            <li>Shipping Charge</li>
                            <li>Date</li>
                        </ul>
                        <ul>
                            <li>#{data?.draftId}</li>
                            {data?.orderStatus == "hold" && <li className='text-capitalize'><span class="badge bg-warning">{data?.orderStatus}</span></li>}
                            {data?.orderStatus == "cancelled" && <li className='text-capitalize'><span class="badge bg-danger">{data?.orderStatus}</span></li>}
                            {data?.orderStatus == "ordered" && <li className='text-capitalize'><span class="badge bg-success">{data?.orderStatus}</span></li>}
                            <li>
                                {data.shippingStatus == "pending" && <span className="badge bg-danger" >{data.shippingStatus}</span>}
                                {data.shippingStatus == "calculated" && <span className="badge bg-warning" >{data.shippingStatus}</span>}
                                {data.shippingStatus == "confirmed" && <span className="badge bg-success" >{data.shippingStatus}</span>}
                            </li>
                            <li>{data?.shippingCharge}</li>
                            <li><Moment format="YYYY/MM/DD" utc>{data?.date}</Moment></li>
                        </ul>
                    </div>

                    {/* -------------table-section--------------- */}

                    <div className={styles.main_table}>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    {/* <th>#</th> */}
                                    <th className='px-3'>Photo</th>
                                    <th className='px-3'>Name</th>
                                    {/* <th className='px-3'>ORDER TYPE</th> */}
                                    <th className='px-3'>QTY</th>
                                    <th className='px-3'>PRICE</th>
                                    <th className='px-3'>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.items?.map((item, index) => (
                                    <tr>
                                        {/* <td>{index + 1}</td> */}
                                        <td className='px-3'><FallProduct cls={styles.img1} imgData={item?.product?.url} alt="product" /></td>
                                        <td className='text-capitalize px-3'>{item?.product?.name}</td>
                                        {/* <td className='text-capitalize px-3'>{data?.deliveryType}</td> */}
                                        <td className='px-3'>{item?.quantity}</td>
                                        <td className='px-3'> {formatter.format((item?.product?.price - item?.product?.discount) || 0)}</td>
                                        <td className='px-3'> {formatter.format(((item?.product?.price - item?.product?.discount) * item.quantity) || 0)}</td>
                                    </tr>

                                ))}
                            </tbody>
                        </Table>
                    </div>

                    <div className={styles.total_section}>
                        <ul>
                            <div className={styles.total1}>
                                <li>Sub Total : </li> <span>{formatter.format(data?.subtotal || 0)}</span>

                            </div>
                            <div className={styles.total1}><li>GST: </li> <span>{data?.tax?.gst}</span></div>
                            <div className={styles.total1}><li>PST/HST: </li> <span>{data?.tax?.pst}</span></div>

                            <div className={styles.total1}>
                                <li>Shipping : </li><span>{formatter.format(data?.shippingCharge || 0)}</span>
                            </div>
                            <div className={styles.total1}>
                                <li>Total : </li><span>{formatter.format(data?.total || 0)}</span>
                            </div>
                            <div className={styles.totalShip}>
                                <label>Shipping : </label>
                                <input type="number" placeholder='0' value={shippingCharges} onChange={handleShippingChargeChange} />
                                <button onClick={handleAddShipCharge}>Update</button>
                            </div>
                        </ul>

                    </div>
                    <div className='d-flex justify-content-end'>
                        <div className={styles.icon} style={{ cursor: "pointer" }}>
                            <img src="/image/printer.png" alt="printer" onClick={handleDownload} />
                        </div>
                    </div>

                </div>



            </div>

        </React.Fragment>
    )
}

export default Draftdetail