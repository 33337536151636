// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Visualizations_section__VHKL8{
    padding: 50px;
    flex-direction: column;
    display: flex;
    row-gap: 30px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Visulizations/Visualizations.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,aAAa;AACjB","sourcesContent":[".section{\n    padding: 50px;\n    flex-direction: column;\n    display: flex;\n    row-gap: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `Visualizations_section__VHKL8`
};
export default ___CSS_LOADER_EXPORT___;
