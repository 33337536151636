import React, { useState } from 'react'
import styles from './edit.module.css'
import { CloseButton, Col, Form, Row } from 'react-bootstrap'
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import { IoCloseCircleOutline } from "react-icons/io5";

const EditFlash = () => {
    const [isHidden, setIsHidden] = useState(false);
    const [imageSrcList, setImageSrcList] = useState([]);

    const handleImageChange = (event) => {
        const files = event.target.files;

        if (files.length > 0) {
            const newImageSrcList = [...imageSrcList];

            for (let i = 0; i < files.length; i++) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    newImageSrcList.push(reader.result);
                    setImageSrcList([...newImageSrcList]);
                };
                reader.readAsDataURL(files[i]);
            }
        }
    };

    const handleRemoveImage = (indexToRemove) => {
        const updatedImageList = [...imageSrcList];
        updatedImageList.splice(indexToRemove, 1);
        setImageSrcList(updatedImageList);
    };


    const handleClick = () => {
        setIsHidden(true);
    };
    return (
        <React.Fragment>
            <TopNavbar />
            <NavbarSection />
            <div className={styles.hero_Section}>
                <Row className={styles.categories}>
                    <Col className='mx-auto' md={9}>
                        <h5>Edit Flash Deals</h5>
                        <div className={styles.product_info}>
                            <div className={styles.product_info_section}>
                                <h6>Basic Info</h6>
                            </div>

                            <div className={styles.main_section}>
                                <Form>
                                    <div className={`${styles.input_container_unit}`}>
                                        <Row className='w-100'>
                                            <Col sm={12} md={4}>
                                                <label for="inputField_2">Tittle</label>
                                            </Col>
                                            <Col sm={12} md={8}>
                                                <input type="text" id="inputField_2" required placeholder='Filon Asset Store' />
                                            </Col>
                                        </Row>

                                    </div>
                                    <div className={`${styles.file}`}>
                                        <Row className='w-100'>
                                            <Col sm={12} md={4}>
                                                <label for="inputField_2">Banner</label>
                                            </Col>
                                            <Col sm={12} md={8}>
                                                <div className={styles.img_banner}>
                                                    <input type="file" id="inputField_2" placeholder='upload logo' onChange={handleImageChange} />

                                                    <div className={styles.container}>
                                                        {imageSrcList.map((imageSrc, index) => (
                                                            <div key={index} className={styles.imageContainer}>
                                                                <img
                                                                    src={imageSrc}
                                                                    alt={`Uploaded ${index + 1}`}
                                                                    style={{ display: 'block', marginTop: '10px', maxWidth: '100%' }}
                                                                />
                                                                <IoCloseCircleOutline
                                                                    className={styles.icon}
                                                                    onClick={() => handleRemoveImage(index)}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>

                                                </div>

                                            </Col>
                                        </Row>

                                    </div>

                                    <div className={`${styles.file}`}>
                                        <Row className='w-100'>
                                            <Col sm={12} md={4}>
                                                <label for="inputField_2">Date</label>
                                            </Col>
                                            <Col sm={12} md={8}>
                                                <input type="date" id="inputField_2" required placeholder='Filon Asset Store' />
                                            </Col>
                                        </Row>

                                    </div>

                                    <div className={`${styles.input_container_unit}`}>
                                        <Row className='w-100'>
                                            <Col sm={12} md={4}>
                                                <label for="product">Date</label>
                                            </Col>
                                            <Col sm={12} md={8}>
                                                <input type="text" id="product" required />
                                            </Col>
                                        </Row>

                                    </div>

                                    <div className={styles.products_section}>

                                        <div className={`${styles.product} ${isHidden ? styles.hidden : ''}`}>
                                            {!isHidden && (
                                                <p>
                                                    Roofing Solution 2x2 <CloseButton onClick={handleClick} />
                                                </p>
                                            )}
                                        </div>
                                        <div className={styles.product}>
                                            <p>Roofing Solution 2x2 <CloseButton /></p>
                                        </div>
                                        <div className={styles.product}>
                                            <p>Roofing Solution 2x2 <CloseButton /></p>
                                        </div>
                                        <div className={styles.product}>
                                            <p>Roofing Solution 2x2 <CloseButton /></p>
                                        </div>
                                        <div className={styles.product}>
                                            <p>Roofing Solution 2x2 <CloseButton /></p>
                                        </div>
                                        <div className={styles.product}>
                                            <p>Roofing Solution 2x2 <CloseButton /></p>
                                        </div>
                                        <div className={styles.product}>
                                            <p>Roofing Solution 2x2 <CloseButton /></p>
                                        </div>
                                    </div>

                                    <div className={styles.save_btn}>
                                        <button>Save</button>
                                    </div>

                                </Form>

                            </div>
                        </div>

                    </Col>
                </Row>
            </div>


        </React.Fragment>
    )
}

export default EditFlash