import React, { useEffect, useState } from 'react'
import styles from './modal.module.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Rating from '../../../Component/Rating/RatingCard';
import { Link } from 'react-router-dom';
import RatingCard from '../../../Component/Rating/RatingCard';
import Cookies from 'js-cookie';
import axios from 'axios';
import { BASE_URL } from '../../../Apis/BaseUrl';
import ToasterUpdate from '../../../Component/Toaster/ToasterUpdate';
import { toast } from 'react-toastify';
import FallProduct from '../../../Component/FallbackImgae/FallProduct';


const ReviewModal = ({ isOpen, closeModal, id, func }) => {
    const [reply, setReply] = useState('')

    // console.log(data)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;

    const getData = async () => {
        // console.log(register)
        let res = await axios.get(`${BASE_URL}/review/${id}/show`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token} `,
            },
        })
        console.log(res.data.data)
        setData(res.data.data)
        // setPageInfo({ ...res.data.data, docs: null })
    }

    useEffect(() => {
        getData()
    }, [])

    const handleAdd = async (e) => {
        e.preventDefault()

        let bdy = {
            reply: reply
        }
        let register = `${BASE_URL}/review/${id}/reply`
        const myToast = toast.loading('Please Wait...')
        setLoading(true)
        try {

            let res = await axios.post(register, bdy, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token} `,
                },
            })
            console.log(res)
            if (!res.data.success) {
                ToasterUpdate(myToast, res.data.message, "error")
            }
            ToasterUpdate(myToast, res.data.message, "success")

            func()
            closeModal()
        } catch (error) {
            // console.log(error)
            ToasterUpdate(myToast, error.message, "error")
        }
        finally {
            setLoading(false)
            setReply('')
        }
    };


    const actualPrice = data?.productId?.price;
    const discountAmount = data?.productId?.discount;

    // Calculate discount percentage
    const discountPercentage = (discountAmount / actualPrice) * 100;

    // Calculate discounted price
    const discountedPrice = actualPrice - discountAmount;

    return (
        <React.Fragment>
            <Modal show={isOpen} onHide={closeModal}>
                <Modal.Title>

                    <div className={styles.header}>
                        <div>
                            <h6>Review Detail</h6>
                        </div>
                        <div className="form-check form-switch d-flex justify-content-end">
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={data?.approved} />
                        </div>
                    </div>
                    <hr className='mb-0' />
                </Modal.Title>

                {data?.productId ? <>
                    <Modal.Body className={`${styles.bdy} mb-3`}>
                        <div className={styles.main_section_edit}>
                            <RatingCard data={data?.rating} />
                            {/* <Link>Edit</Link> */}
                        </div>
                        <div className={`${styles.content} mt-4`}>
                            <p>{data?.comment}</p>
                            <button>{data?.user?.name}</button>
                        </div>
                        <div className={styles.details_Section}>
                            <h6>Product Details</h6>
                            <div className={styles.product_details}>
                                <FallProduct imgData={data?.productId?.url} cls={styles.imgFall} alt="product" />
                                <div className={styles.child_section}>
                                    <p>{data?.productId?.name}</p>
                                    <div className='d-flex gap-1 align-items-center'>
                                        <RatingCard data={data?.rating} /> <span>{data?.productId?.numReviews ? data?.productId?.numReviews : "0"}+ Reviews</span>
                                    </div>
                                    <div className='d-flex gap-2 align-items-center'>
                                        {data?.productId?.discount ? <>
                                            <button className='px-4 py-2' style={{ fontSize: "12px" }}>{discountPercentage.toFixed(2)}%</button>   <p className='text-decoration-line-through pt-1'>₹{actualPrice}</p>
                                            <h6>₹{discountedPrice}</h6>
                                        </>
                                            : <h6>₹{data?.productId?.price}</h6>}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.main_section}>
                            <label for="name2">Reply</label>
                            <textarea type="text" id='name2' placeholder='Type Message' value={reply} onChange={(e) => setReply(e.target.value)} rows="4" cols="50" required />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className={styles.model_button}>
                            <Button className='bg-white text-black border' variant="secondary" onClick={closeModal}> Cancel  </Button>
                            <Button variant="primary" onClick={handleAdd}>Publish</Button>
                        </div>

                    </Modal.Footer></> : <p  style={{
                        padding: "20px",
                        fontSize: "18px",
                        fontWeight: 500
                    }} className='mb-0'> Sorry , the product might be deleted or not available!</p>}
            </Modal>
        </React.Fragment>
    )
}

export default ReviewModal