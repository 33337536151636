import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie'
import { BASE_URL } from '../../Apis/BaseUrl';
import axios from 'axios';

const DeleteModal = ({ id, title, show, handleClose, getData }) => {
  const[loading, setLoading] = useState(false)
  console.log(id)

  let profileTokenJson = Cookies.get("v&b_token");
  let profileToken = JSON.parse(profileTokenJson);
  // console.log(profileToken.token)
  let token = profileToken.token;

  const handleDelete = async () => {
    
    const myToast = toast.loading('Please Wait...')
    setLoading(true)
    try {
      let register;
    if (title === 'customer') {
      register = `${BASE_URL}/user/${id}`;
    } else if (title === 'seller') {
      register = `${BASE_URL}/vendor/${id}/remove`
    }
      // let register = `${BASE_URL}/user/${id}`

      const response = await axios.delete(register,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        });
      console.log(response)
      toast.update(myToast, {
        render: response.data.message,
        type: 'success',
        isLoading: false,
        autoClose: 1500
      });

      getData()
    } catch (error) {
      toast.update(myToast, {
        render: error.message,
        type: 'error',
        isLoading: false,
        autoClose: 1500
      })
      console.error('Error deleting resource:', error);
    }
    finally {
      setLoading(false)
      handleClose()
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this account?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
