// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notifications_overlay__HEIBt {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #0000002e;
    z-index: 9;
    left: 0;
}

.Notifications_box__xi9-x {
    position: fixed;
    right: 20px;
    z-index: 11;
    width: 360px;
    top: 80px;
}

.Notifications_header__\\+2t8u {
    background: #ebe6e6;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.Notifications_markasread__7NjK5{
    text-align: center;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    width: 100%;
    color:rgb(209 21 21);    ;
    text-decoration: underline;
    position: absolute;
    bottom: 0;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
}
.Notifications_body__xWZi7 {
    padding: 15px;
    /* margin-top: 10px; */
    height: 200px;
    overflow: scroll;
}`, "",{"version":3,"sources":["webpack://./src/Component/Notifications/Notifications.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,UAAU;IACV,OAAO;AACX;;AAEA;IACI,eAAe;IACf,WAAW;IACX,WAAW;IACX,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,oBAAoB;IACpB,WAAW;IACX,oBAAoB;IACpB,0BAA0B;IAC1B,kBAAkB;IAClB,SAAS;IACT,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,gBAAgB;AACpB","sourcesContent":[".overlay {\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    background: #0000002e;\n    z-index: 9;\n    left: 0;\n}\n\n.box {\n    position: fixed;\n    right: 20px;\n    z-index: 11;\n    width: 360px;\n    top: 80px;\n}\n\n.header {\n    background: #ebe6e6;\n    height: 50px;\n    display: flex;\n    align-items: center;\n    padding-left: 10px;\n}\n.markasread{\n    text-align: center;\n    background: none;\n    border: none;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding-bottom: 10px;\n    width: 100%;\n    color:rgb(209 21 21);    ;\n    text-decoration: underline;\n    position: absolute;\n    bottom: 0;\n    font-size: 14px;\n    font-weight: 400;\n    margin-bottom: 10px;\n}\n.body {\n    padding: 15px;\n    /* margin-top: 10px; */\n    height: 200px;\n    overflow: scroll;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `Notifications_overlay__HEIBt`,
	"box": `Notifications_box__xi9-x`,
	"header": `Notifications_header__+2t8u`,
	"markasread": `Notifications_markasread__7NjK5`,
	"body": `Notifications_body__xWZi7`
};
export default ___CSS_LOADER_EXPORT___;
