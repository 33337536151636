// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationModal_popup__DT-\\+r{
    width: 530px;
}

.NotificationModal_header__50TXO{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
}
.NotificationModal_btn-close__JNeZB{
    display: none !important;
}
.NotificationModal_header__50TXO h4{}

.NotificationModal_body__ytrJv{
    padding: 20px;
}

.NotificationModal_p__hqF\\+7 {
    font-size: 15px;
    width: 100%;
    color: #3d3b3b;
    margin-bottom: 0;
}

.NotificationModal_span__zqS4n {
    font-size: 14px;
    color: red;
    font-weight: 500;
}

.NotificationModal_bottom__EhX81 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.NotificationModal_bottom__EhX81>button {
    border-radius: var(--br);
    border: none;
    height: 40px;
    cursor: pointer;
    font-weight: 500;
    margin-left: auto;
}

.NotificationModal_bottom__EhX81>button:nth-child(1) {
    width: 40%;
    background: #f1f3f4;
}
`, "",{"version":3,"sources":["webpack://./src/Component/NotificationModal/NotificationModal.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;AACnB;AACA;IACI,wBAAwB;AAC5B;AACA,oCAAW;;AAEX;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,aAAa;AACjB;;AAEA;IACI,wBAAwB;IACxB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,mBAAmB;AACvB","sourcesContent":[".popup{\n    width: 530px;\n}\n\n.header{\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    font-size: 20px;\n}\n.btn-close{\n    display: none !important;\n}\n.header h4{}\n\n.body{\n    padding: 20px;\n}\n\n.p {\n    font-size: 15px;\n    width: 100%;\n    color: #3d3b3b;\n    margin-bottom: 0;\n}\n\n.span {\n    font-size: 14px;\n    color: red;\n    font-weight: 500;\n}\n\n.bottom {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 20px;\n}\n\n.bottom>button {\n    border-radius: var(--br);\n    border: none;\n    height: 40px;\n    cursor: pointer;\n    font-weight: 500;\n    margin-left: auto;\n}\n\n.bottom>button:nth-child(1) {\n    width: 40%;\n    background: #f1f3f4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `NotificationModal_popup__DT-+r`,
	"header": `NotificationModal_header__50TXO`,
	"btn-close": `NotificationModal_btn-close__JNeZB`,
	"body": `NotificationModal_body__ytrJv`,
	"p": `NotificationModal_p__hqF+7`,
	"span": `NotificationModal_span__zqS4n`,
	"bottom": `NotificationModal_bottom__EhX81`
};
export default ___CSS_LOADER_EXPORT___;
