import React, { useEffect, useState } from 'react'
import AsyncSelect from "react-select/async"
import { AsyncPaginate } from 'react-select-async-paginate'
import { BASE_URL } from '../../Apis/BaseUrl'
import axios from 'axios'
import Cookies from 'js-cookie'

const DropInputCoupon = ({func, search, data, setSearch}) => {
    // const [data2, setData2] = useState([])
    // const [inputValue, setValue] = useState('')
    // let search = props?.search
    // let setSearch = props?.setSearch
    // let setProductSearch = props?.setProductSearch

    // const handleInputChange = value => {
    //     setValue(value)
    // }

    const obj = {
        category:{
            url:'',
            data:[]
        },
        product:{

        }
    }

    const getData = async () => {

    }

    const handleChange = value => {
        console.log(value, "jhh")
        // if (value?.value) {
        //     // setProductSearch(value.value)
        // }else{
            setSearch(value)
        // }
    }


    // let option = props?.option
    // let func = props?.func

    // console.log(option)
    // const selectOptions = option.map(item => ({
    //     value: item._id,
    //     label: item.name
    // }));
    // let options = selectOptions
    // console.log(search)

    // let profileTokenJson = Cookies.get("v&b_token");
    // let profileToken = JSON.parse(profileTokenJson);
    // let token = profileToken.token;

    // const getProductData = async () => {
    //     console.log(search)
    //     let res = await axios.get(`${BASE_URL}/product?limit=2&page=1&search=${search}`, {
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${token} `,
    //         },
    //     })
    //     console.log(res.data.data.docs)

    //     setData2(res.data.data.docs)
    //      let resData =  res?.data?.data?.docs?.map(item => ({
    //         value: item._id,
    //         label: item.name
    //     }));
    //     console.log(resData)
    //     return resData
    // }


    // // console.log(selectOptions)



    // useEffect(() => {
    //     getProductData()
    // }, [search])
    return (
        <div>
            {/* <Select options={options}  onChange={setSearch} isSearchable noOptionsMessage={() => "No product found!"}/> */}
          
            <AsyncSelect
                cacheOptions
                defaultOptions
                id="companyId"
                loadOptions={func}
                defaultValue={search}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => option.label}
                onChange={handleChange}
                onInputChange={handleChange}
                placeholder="Select an option"
            
            />
        </div>
    )
}

export default DropInputCoupon