import React from 'react'
import { Helmet } from 'react-helmet'
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import classes from './Visualizations.module.css'
import Places from './components/Places'
import Material from './components/Material'
import Object from './components/Objects'
import ObjectProduct from './components/ObjectProduct'

const Visulizations = () => {

    return (
        <React.Fragment>
            <Helmet>
                <title>Visualizations</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            <section className={classes.section}>
                <Places />
                <Object />
                <Material />
                <ObjectProduct />
            </section>


        </React.Fragment >
    )
}

export default Visulizations