import React, { useState } from 'react'
import styles from './color.module.css'
import ContainerSection from '../../Component/ContainerSection/ContainerSection'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import ColorModal from './ColorModal/ColorModal'
import { Helmet } from 'react-helmet'

const Colors = () => {
    const [modalOpen, setModalOpen] = useState(false)

    const closeModal = () => {
        setModalOpen(false);
    };

    const modalClickOpen = () => {
        setModalOpen(true);
    };
    return (
        <React.Fragment>
            <Helmet>
                <title>Color</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            {/* -----------------hero-section---------------- */}

            <div className={styles.hero_Section}>
                <ContainerSection />

                <div className={styles.filter_section}>
                    <div className={styles.filter}>
                        <p>Filters : </p>
                        <p>Status </p>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                            <label className="form-check-label" for="flexRadioDefault2">
                                All
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Active
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Inactive
                            </label>
                        </div>
                    </div>

                    <ColorModal modalOpen={modalOpen} closeModal={closeModal} />
                    <div className={styles.btn_section}>
                        <button onClick={modalClickOpen}>Add Color</button>
                    </div>

                </div>

                {/* -------------table_section-------------- */}

                <div className={styles.main_table}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col"><input type="radio" /></th>
                                <th scope="col">#</th>
                                <th scope="col">Attribute</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row"><input type="radio" /></th>
                                <td>1</td>
                                <td>Color Name</td>
                                <td>
                                    <div className={styles.image_section}>
                                        <img src="/image/edit.png" alt="edit" />
                                        <img src="/image/delete.png" alt="delete" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </React.Fragment>
    )
}

export default Colors