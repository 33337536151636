import React, { useEffect, useState } from 'react'
import styles from './modal.module.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { BASE_URL } from '../../../Apis/BaseUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import ToasterUpdate from '../../../Component/Toaster/ToasterUpdate';

const ContactModal = ({ isOpen, closeModal, id }) => {
    const[message, setMessage] = useState('')
    const[subject, setSubject] = useState('')
    const[loading, setLoading] = useState(false)

    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;

    const handleAdd = async (e) => {
        e.preventDefault()

        let bdy = {
            subject,
            message
        }
        console.log(bdy)
        let register = `${BASE_URL}/contacts/${id}/reply`
        const myToast = toast.loading('Please Wait...')
        setLoading(true)
        try {

            let res = await axios.post(register, bdy, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token} `,
                },
            })
            console.log(res)
            if (!res.data.success) {
                ToasterUpdate(myToast, res.data.message, "error")
            }
            ToasterUpdate(myToast, res.data.message, "success")

            // func()
            closeModal()
        } catch (error) {
            // console.log(error)
            ToasterUpdate(myToast, error.message, "error")
        }
        finally {
            setLoading(false)
            setMessage('')
            setSubject('')
        }
    };

    return (
        <React.Fragment>
            <Modal show={isOpen} onHide={closeModal}>

                <Modal.Title> <div className={styles.header}>
                    <div>
                        <h6>Reply</h6>
                    </div>
                  
                </div>
                    <hr />
                </Modal.Title>

                <Modal.Body className='py-0'>
                    <div className={`${styles.main_section}`}>
                        <h6 className='mb-0'>Subject</h6>
                        <input type="text" value={subject} onChange={(e)=> setSubject(e.target.value)}/>
                    </div>
                    <div className={styles.main_section}>
                        <h6 className='mb-0'>Message</h6>
                        <textarea name="" rows={5} id=""  value={message} onChange={(e)=> setMessage(e.target.value)}>

                        </textarea>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div className={styles.model_button} style={{gap:"10px"}}>
                        <button onClick={closeModal} style={{backgroundColor:"#989898"}}>Close </button>
                        <Button variant="primary" onClick={handleAdd} disabled={loading}>Send </Button>
                    </div>

                </Modal.Footer>
            </Modal>
            
        </React.Fragment>
    )
}

export default ContactModal