import React from 'react'
import styles from './att.module.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const AttModal = ({ isOpen, closeModal }) => {
    return (

        <React.Fragment>
            <Modal show={isOpen}>
                <Modal.Header>
                    <Modal.Title>Add new attribute</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-3'>
                    <div className={styles.main_section}>
                        <label for="name">Name</label>
                        <input type="text" id='name' />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={styles.model_button}>
                        <Button className='bg-white text-black border' variant="secondary" onClick={closeModal}> Cancel  </Button>
                        <Button variant="primary" onClick={closeModal}>  Save </Button>
                    </div>

                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default AttModal