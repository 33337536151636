import React, { useState } from 'react';
import styles from './staff.module.css'
import { Col, Row } from 'react-bootstrap'
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import ContainerSection from '../../Component/ContainerSection/ContainerSection'
import { StaffData } from '../../Component/NavbarSection/NavData/NavData';
import StaffModal from './StaffModal/StaffModal';
import { Helmet } from 'react-helmet';

const Staff = () => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => {
        setIsOpen(true)
    }
    const closeModal = () => {
        setIsOpen(false)
    }
    return (
        <React.Fragment>
            <React.Fragment>
                <Helmet>
                    <title>Staff</title>
                </Helmet>
                <TopNavbar />
                <NavbarSection />

                {/* -----------------hero-section---------------- */}

                <div className={styles.hero_Section}>
                    <ContainerSection />

                    <div className={styles.mainSection}>
                        <Row className='w-100'>
                            <Col>
                                <h6>Staff</h6>
                            </Col>


                        </Row>
                    </div>



                    {/* -------------table_section-------------- */}

                    <div className={styles.main_table}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Phone</th>
                                    <th scope="col">Role</th>
                                    <th scope="col">Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td> George M. Winters </td>
                                    <td>Example@gmail com</td>
                                    <td>7503063585</td>
                                    <td> Order clerks</td>
                                    <td>
                                        <div className={styles.image_section}>
                                            <StaffModal isOpen={isOpen} closeModal={closeModal} />
                                            <img onClick={openModal} src="/image/edit.png" alt="edit" />
                                            <img src="/image/delete.png" alt="edit" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td> George M. Winters </td>
                                    <td>Example@gmail com</td>
                                    <td>7503063585</td>
                                    <td> Order clerks</td>
                                    <td>
                                        <div className={styles.image_section}>
                                            <StaffModal isOpen={isOpen} closeModal={closeModal} />
                                            <img onClick={openModal} src="/image/edit.png" alt="edit" />
                                            <img src="/image/delete.png" alt="edit" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td> George M. Winters </td>
                                    <td>Example@gmail com</td>
                                    <td>7503063585</td>
                                    <td> Order clerks</td>
                                    <td>
                                        <div className={styles.image_section}>
                                            <img src="/image/edit.png" alt="edit" />
                                            <img src="/image/delete.png" alt="edit" />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        </React.Fragment>
    )
}

export default Staff