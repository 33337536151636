import React from 'react'
import styles from './flash.module.css'
import { Helmet } from 'react-helmet'
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import ContainerSection from '../../Component/ContainerSection/ContainerSection'
import { useNavigate } from 'react-router-dom'

const Flash = () => {
    const navigate = useNavigate()
    return (
        <React.Fragment>
            <Helmet>
                <title>Flash Deals</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            {/* ----------Hero-Section----------- */}

            <div className={styles.hero_Section}>
                <ContainerSection />

                <div className={styles.product_review_section}>
                    <h6>Flash deals</h6>
                </div>


                {/* -------------table_section-------------- */}

                <div className={styles.main_table}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Sno</th>
                                <th scope="col">Sale Name</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">End Date</th>
                                <th scope="col">Products Include</th>
                                <th scope="col">Feature</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Flash Sale</td>
                                <td>20/8/2023</td>
                                <td>20/9/2023</td>
                                <td>25</td>
                                <td>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
                                    </div>
                                </td>
                                <td>
                                    <div className={styles.image_section}>
                                        <img onClick={() => navigate(`/flashdeals/editflash`)} src="/image/edit.png" alt="edit" />
                                        <img src="/image/delete.png" alt="delete" />
                                    </div>
                                </td>

                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>




        </React.Fragment>
    )
}

export default Flash