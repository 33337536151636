import React, { useEffect, useState } from 'react'
import classes from './OBS.module.css'
import { BASE_URL } from '../../Apis/BaseUrl'
import axios from 'axios'
import Cookies from 'js-cookie'
import { Card } from 'react-bootstrap'
import { toast } from 'react-toastify'
import UniversalSliderModal from '../../Pages/Apperance/Modal/UniversalSliderModal'
import UniversalSliderEditModal from '../../Pages/Apperance/Modal/UniversalSliderEditModal'

function VerticalCard({ handleDelete, handleEdit, data }) {
    return (
        <Card className='my-3'>
            <Card.Body className='d-flex justify-content-between align-items-center'>
                <Card.Text className='mb-0 fw-medium'>
                    Banner Sequence {data?.sequence}
                    {/* <p className='mb-0 fw-normal'>Sequence : {props?.data?.sequence}</p> */}
                </Card.Text>
                <div className='d-flex gap-2'>
                    <img src="/image/edit.png" style={{ cursor: "pointer" }} onClick={handleEdit} alt="edit" />
                    <img src="/image/delete.png" style={{ cursor: "pointer" }} onClick={handleDelete} alt="delete" />
                </div>
            </Card.Body>
        </Card>
    )
}


const OverallBannerSlider = ({ url, title }) => {

    const [data, setData] = useState([])
    const [page, setPage] = useState(1)
    const [pageInfo, setPageInfo] = useState({})
    const [limit, setLimit] = useState(10)
    const [loading, setLoading] = useState(false)
    const [taskLoading, setTaskLoading] = useState(false)

    const [modal, setModal] = useState(false)
    const [edit,setEdit] = useState(false)
    const [edit_id,setEditID] = useState('')

    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    let token = profileToken.token;


    const getData = async () => {
        try {
            setLoading(true)
            let register = `${BASE_URL}/${url}?limit=${limit}&page=${page}`

            let res = await axios.get(register, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            setData(res.data.data.docs)
            setPageInfo({ ...res.data.data, docs: null })
        } catch (error) {
            console.log(error)
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getData()
    }, [page, limit])

    // DELETE HANDLER
    const deleteHandler = async (id) => {
        const myToast = toast.loading('Please Wait...')
        setTaskLoading(true)
        try {
            let register = `${BASE_URL}/${url}/${id}/delete`

            const response = await axios.delete(register,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`
                    }
                });
            toast.update(myToast, {
                render: response.data.message,
                type: 'success',
                isLoading: false,
                autoClose: 1500
            });
            getData()
        } catch (error) {
            toast.update(myToast, {
                render: error.message,
                type: 'error',
                isLoading: false,
                autoClose: 1500
            })
        }
        finally {
            setTaskLoading(false)
        }
    }

    const editHandler = async (id) => {
        setEditID(id)
        setEdit(true)
    }

    return (
        <>
            <div className='d-flex justify-content-between align-items-center mt-3'>
                <h6 style={{ color: "#AE0000" }}>{title}</h6>
                <button onClick={() => setModal(true)} className='px-3 py-2 rounded-1 border-0' style={{ backgroundColor: "#AE0000", color: "white" }} >Add Banner</button>
            </div>

            <div style={classes.container}>
                {loading ? 'Loading' : data.length > 0 ? data.map(element => (
                    <VerticalCard handleDelete={() => deleteHandler(element._id)} handleEdit={() => editHandler(element._id)} key={element._id} data={element} />
                )) : 'No Data Found!'}
            </div>

            {modal && <UniversalSliderModal modalStatus={modal} getData={getData} handleClose={() => setModal(false)} url={url} />}
            {edit && <UniversalSliderEditModal modalStatus={edit} getData={getData} handleClose={() => setEdit(false)} url={url} id={edit_id} />}

        </>
    )
}

export default OverallBannerSlider