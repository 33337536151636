import React, { useEffect, useState } from 'react'
import styles from './brand.module.css'
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import ContainerSection from '../../Component/ContainerSection/ContainerSection'
import BrandsModal from './BrandsModal/BrandsModal'
import { Helmet } from 'react-helmet'
import { BASE_URL } from '../../Apis/BaseUrl'
import Cookies from 'js-cookie'
import axios from 'axios'
import { toast } from 'react-toastify'
import EditBrand from './BrandsModal/EditBrand'
import FallbackImage from '../../Component/FallbackImgae/FallbackImage'
import FallProduct from '../../Component/FallbackImgae/FallProduct'
import ToasterUpdate from '../../Component/Toaster/ToasterUpdate'
import NotFound from '../../Component/NotFound/NotFound'
import NewPagination from '../../Component/NewPagination/NewPagination'


const Brands = () => {
    const[data, setData] = useState([])
    const[page, setPage] = useState(1)
    const[pageInfo, setPageInfo] = useState({})
    const[limit, setLimit] = useState(10)
    const[search, setSearch] = useState('')
    const[loading, setLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [edit, setEdit] = useState(false)
    const[ID1, setId1] = useState('')

    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;

    const closeModal = () => {
        setIsOpen(false)
    }
    const openModal = () => {
        setIsOpen(true)
    }

    const getData = async () => {
     
        let register = `${BASE_URL}/brand?limit=${limit}&page=${page}&active=${search}`
    
        // console.log(register)
        let res = await axios.get(register, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
        console.log(res.data.data)
        setData(res.data.data.docs)
        setPageInfo({ ...res.data.data, docs: null })
      }

    useEffect(() => {
        getData()
    }, [limit, page, search]
    )

    useEffect(() => {
        setPage(1)
    }, [limit]
    )

    const paginationProps = {
        setPage,
        pageInfo, setLimit
      }
    const handleActive = async (id, item) => {
       console.log(item)
        let register = `${BASE_URL}/brand/${id}`
        const myToast = toast.loading('Please Wait...')
        // console.log(register)
        try {

            let res = await axios.put(register, { active: !item.active }, {
                // withCredentials : true,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token} `,
                },
            })

            if (!res.data.success) {
                ToasterUpdate(myToast, res.data.message, "error")
            }
            ToasterUpdate(myToast, res.data.message, "success")
            getData()
        } catch (error) {
            // console.log(error)
            ToasterUpdate(myToast, error.message, "error")
        }

    };
    const handleDelete = async (id) => {
        console.log(id)
        const myToast = toast.loading('Please Wait...')
        setLoading(true)
        try {
          let register = `${BASE_URL}/brand/${id}`

          const response = await axios.delete(register,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
              }
            });
            console.log(response)
            toast.update(myToast, {
                render: response.data.message,
                type: 'success',
                isLoading: false,
                autoClose: 1500
            });
            
            getData()
        } catch (error) {
          toast.update(myToast, {
            render: error.message,
            type: 'error',
            isLoading: false,
            autoClose: 1500
          })
          console.error('Error deleting resource:', error);
        }
        finally{
            setLoading(false)
        }
      }
      const navigateToEdit = (id) => {
        // console.log(id)
        setEdit(true)
        setId1(id)}

      const closeEditModal = () => {
        setEdit(false)
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Brands</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            {/* -----------------hero-section---------------- */}

            <div className={styles.hero_Section}>
                <ContainerSection />

                <div className={styles.filter_section}>
                    <div className={styles.filter}>
                        <p>Filters : </p>
                        <p>Status </p>
                        <div classNames="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" value=""    onChange={(e) => setSearch(e.target.value)} id="flexRadioDefault2" checked={search == ''} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                All
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="active"
                               onChange={(e) => setSearch(e.target.value)} checked={search === "active"} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Active
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"    onChange={(e) => setSearch(e.target.value)}value="inactive" checked={search === "inactive"} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Inactive
                            </label>
                        </div>
                    </div>
                    <BrandsModal isOpen={isOpen} closeModal={closeModal} data={getData} />
                    <div className={styles.btn_section}>
                        <button onClick={openModal}>Add Brands</button>
                    </div>

                </div>


                {/* -------------table_section-------------- */}
                {(data?.length > 0) ? 
               ( <> <div className={styles.main_table}>
                    <table className="table">
                        <thead>
                            <tr>
                                {/* <th scope="col"><input type="radio" /></th> */}
                                {/* <th scope="col">#</th> */}
                                <th  className='px-5'>Logo</th>
                                <th >Name</th>
                                <th>Status</th>
                                <th className='text-center' >Action</th>
                            </tr>
                        </thead>
                        <tbody>
                          {data?.map((item, index) => (
                              <tr>
                              {/* <th scope="row"><input type="radio" /></th> */}
                              {/* <td>{index+1}</td> */}
                              <td className='px-5'><FallProduct imgData={item?.logoUrl} cls={styles.table_img}/></td>
                              <td className='text-capitalize'>{item.name}</td>
                              <td>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" onChange={() => handleActive(item._id, item)} checked={item.active} />
                                        </div>
                                    </td>
                              {/* <td>{item?.logoUrl}</td> */}

                             
                              <td className=''>
                                  <div className={styles.image_section}>
                                      <img src="/image/edit.png" alt="copy" onClick={() => navigateToEdit(item._id)} />
                                      <img src="/image/delete.png" alt="delete" onClick={()=>handleDelete(item._id)} />
                                  </div>

                              </td>
                          </tr>
                          ))}
                            
                        </tbody>
                    </table>
                    </div>
                    <NewPagination {...paginationProps}/>

                </>) : <NotFound/> }
               
            </div>
            {edit &&  <EditBrand isOpen={edit} closeModal={closeEditModal} data={getData} id={ID1} /> }
        </React.Fragment>
    )
}

export default Brands