// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BlackButton_button__LkTjv{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 14px;
    font-weight: 500;
    background: black;
    color: white;
    border-radius: var(--br);
    border: none;
}`, "",{"version":3,"sources":["webpack://./src/Component/BlackButton/BlackButton.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,wBAAwB;IACxB,YAAY;AAChB","sourcesContent":[".button{\n    display: flex;\n    align-items: center;\n    justify-content: space-evenly;\n    font-size: 14px;\n    font-weight: 500;\n    background: black;\n    color: white;\n    border-radius: var(--br);\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `BlackButton_button__LkTjv`
};
export default ___CSS_LOADER_EXPORT___;
