// import React, { useState } from 'react'

// const Rating = ({ totalStars }) => {
//     const [rating, setRating] = useState(0);

//     const handleStarClick = (index) => {
//         setRating(index + 1);
//     };
//     return (
//         <div>
//             {[...Array(totalStars)].map((_, index) => (
//                 <span
//                     key={index}
                    
//                     // onClick={() => handleStarClick(index)}
//                     style={{ cursor: 'pointer', fontSize:"30px", marginBottom:"10px", color: index < rating ? 'gold' : 'gray' }}
//                 >
//                     &#9733;
//                 </span>
//             ))}
//             {/* <p>Selected Rating: {rating}</p> */}
//         </div>
//     );
// }

// export default Rating

import React, { useState } from 'react'
import { Rating } from 'react-simple-star-rating'

const RatingCard = (props) => {
    const [ratingData, setRatingData] = useState(1)
    const [loading, setLoading] = useState(false)
    console.log(props?.data)
    let rating = props?.data
    // let classId  = props?.data?.classDetails?._id
    // let rating = props?.data?.ratings === null ? 0 : props?.data?.ratings?.rating
    // let readonly  = props?.data?.ratings !== null 

  return (
    <>
  
    <Rating
    // className={classes.stars_con}
    // onClick={rate => handleAddExperience(rate)}
    size={30}
    initialValue={rating}
    readonly
  />
  </>
  )
}

export default RatingCard