
export const NavData = [
    { title: 'All Product', link: '/product/allproduct' },
    // { title: 'Add New Product', link: '/allproduct/addnewproduct' },
    // { title: 'Bulk Import', link: '/bultimport' },
    // { title: 'Bulk Export', link: '#action/3.4' },
    { title: 'Categories', link: '/product/categories' },
    { title: 'Brand', link: '/product/brands' },
    // { title: 'Attributes', link: '/attributes' },
    // { title: 'Color', link: '/colors' },
    { title: 'Product Reviews', link: '/product/productreview' },
    { title: 'Product Review Report', link: '/product/productreport' },
];

export const secondMenu = [
    { title: 'All Orders', link: '/sales/allorder' },
    { title: 'Orders Draft', link: '/sales/draft' },
    { title: 'Payments', link: '/sales/payment' },
    { title: 'Withdrawal Request', link: '/sales/withdraw' },
];

export const sellerData = [
    { title: 'All Seller', link: '/seller' },
    // { title: 'Payouts', link: '#action/3.2' },
    // { title: 'Payouts Request', link: '#action/3.3' },
    // { title: 'Seller Commission', link: '#action/3.3' }
]
export const StaffData = [
    { title: 'All Staff', link: '/allstaff' },
    { title: 'Role Permission', link: '/rolepermission' },

]
export const MarketingData = [
    // { title: 'Flash Deals', link: '/flashdeals' },
    { title: 'Coupons', link: '/marketing/coupons' },
    { title: 'Subscribe Email', link: '/marketing/subscribemail' },
    { title: 'Contacts', link: '/marketing/contact' },

]