import React from 'react'
import styles from './section.module.css'

const ContainerSection = (props) => {
    // console.log(props.cls)
    return ( <>
    <div className={`${styles.grid_container} ${props.cls}`}>
{props?.data?.map((item)=>(
    <div className={styles.box}>
        <p>{item.h5}</p>
        <h6>{item.p}</h6>
    </div>

))}    
</div>
</>        // {/* <div className={styles.box}>
            //     <p>Monthly Sale</p>
            //     <h6>$50,000</h6>
            // </div>
            // <div className={styles.box}>
            //     <p>Products</p>
            //     <h6>309</h6>
            // </div>
            // <div className={styles.box}>
            //     <p>Wallet</p>
            //     <h6>$109,000</h6>
            // </div>
            // <div className={styles.box}>
            //     <p>Orders</p>
            //     <h6>082</h6>
            // </div> */}
    )
}

export default ContainerSection