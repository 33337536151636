// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageUploader_upload_box__p780y {
    height: 50px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 2px dashed #AE0000;
    background: #FFF3F3;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
}

.ImageUploader_upload_box__p780y>svg {
    font-size: 40px;
}

.ImageUploader_preview_container__nCScb {
    margin-top: 10px;
}

.ImageUploader_preview_image__RVTkT {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Apperance/ImageUploader.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,cAAc;IACd,kBAAkB;IAClB,0BAA0B;IAC1B,mBAAmB;IACnB,sBAAsB;IACtB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".upload_box {\n    height: 50px;\n    flex-shrink: 0;\n    border-radius: 5px;\n    border: 2px dashed #AE0000;\n    background: #FFF3F3;\n    width: 100% !important;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    font-size: 14px;\n}\n\n.upload_box>svg {\n    font-size: 40px;\n}\n\n.preview_container {\n    margin-top: 10px;\n}\n\n.preview_image {\n    border: 1px solid #d9d9d9;\n    border-radius: 5px;\n    object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload_box": `ImageUploader_upload_box__p780y`,
	"preview_container": `ImageUploader_preview_container__nCScb`,
	"preview_image": `ImageUploader_preview_image__RVTkT`
};
export default ___CSS_LOADER_EXPORT___;
