import React, { useEffect, useState } from 'react'
import styles from './modal.module.css'
import { Button, Form, Modal } from 'react-bootstrap';
import Cookies from 'js-cookie';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../Apis/BaseUrl';
import ToasterUpdate from '../../Component/Toaster/ToasterUpdate';


const UpdateLog = ({ isOpen, closeModal, getData, id , status}) => {
    // console.log(status)
    const[loading, setLoading] = useState(false)
    const[logName, setLogName] = useState('')
    const[statusType, setStatusType] = useState(status)

 

    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    let token = profileToken.token;
 

const handleAdd = async (e) => {
    e.preventDefault()
let bdy = {
    status : statusType,
    log : logName
  }
  
 let register = `${BASE_URL}/order/${id}/status`
    const myToast = toast.loading('Please Wait...')
    setLoading(true)
    try {

        let res = await axios.put(register, bdy, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token} `,
            },
        })
        console.log(res)
        if (!res.data.success) {
            ToasterUpdate(myToast, res.data.message, "error")
        }
        ToasterUpdate(myToast, res.data.message, "success")
      getData()
    } catch (error) {
        ToasterUpdate(myToast, error.message, "error")
    }
    finally {
        setLoading(false)
       closeModal()
    }

}
   

// console.log(couponType)
    return (
        <React.Fragment>
            <Modal show={isOpen} onHide={closeModal}>
                <Modal.Header>
                    <Modal.Title style={{fontSize:"18px"}}>Update Log</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-3'>
                    
                    <div className={styles.main_section_name}>
                    <div className='d-flex flex-column w-100'>
                            <label for="name">Status</label>
                            <Form.Select aria-label="Default select example" value={statusType} onChange={(e) => setStatusType(e.target.value)}>
                               
                                <option value="pending" selected>Pending</option>
                                <option value="processing">Processing</option>
                                <option value="shipped">Shipped</option>
                                <option value="delivered">Delivered</option>
                                <option value="cancelled">Cancelled</option>
                                <option value="return">Return</option>
                            </Form.Select>
                        </div>
                   
                      
                    </div>

                    <div className={styles.main_section}>
                            <label for="name">Log</label>
                            <input value={logName} onChange={(e) => setLogName(e.target.value)} type="texts" id='name' />
                        </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={styles.model_button}>
                        <Button className='bg-white text-black border' variant="secondary" onClick={closeModal}> Cancel </Button>
                        <Button variant="primary" onClick={handleAdd}>  Save </Button>
                    </div>

                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default UpdateLog