// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_main_section__ES9CQ {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;


    & label {
        font-family: Inter;
        font-size: 0.8rem;
        font-weight: 500;
    }

    & input {
        height: 35px;
        border-radius: 5px;
        border: 1px solid #D9D9D9;

    }
}

.modal_model_button__oo8aw {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    & button {
        display: flex;
        width: 87px;
        height: 39px;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border: none;
        color: #FFF;
        font-family: Inter;
        font-size: 0.8rem;
        font-weight: 500;
        border-radius: 3px;
        background: var(--main-color);
    }
}

.modal_model_button__oo8aw button:hover {
    color: #FFF;
    background: var(--main-color);
}

.modal_model_button__oo8aw button:active {
    color: #FFF;
    background: var(--main-color);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Colors/ColorModal/modal.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,sBAAsB;IACtB,SAAS;IACT,mBAAmB;;;IAGnB;QACI,kBAAkB;QAClB,iBAAiB;QACjB,gBAAgB;IACpB;;IAEA;QACI,YAAY;QACZ,kBAAkB;QAClB,yBAAyB;;IAE7B;AACJ;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,SAAS;;IAET;QACI,aAAa;QACb,WAAW;QACX,YAAY;QACZ,kBAAkB;QAClB,uBAAuB;QACvB,mBAAmB;QACnB,SAAS;QACT,YAAY;QACZ,WAAW;QACX,kBAAkB;QAClB,iBAAiB;QACjB,gBAAgB;QAChB,kBAAkB;QAClB,6BAA6B;IACjC;AACJ;;AAEA;IACI,WAAW;IACX,6BAA6B;AACjC;;AAEA;IACI,WAAW;IACX,6BAA6B;AACjC","sourcesContent":[".main_section {\n    display: flex;\n    justify-content: flex-start;\n    flex-direction: column;\n    gap: 10px;\n    margin-bottom: 10px;\n\n\n    & label {\n        font-family: Inter;\n        font-size: 0.8rem;\n        font-weight: 500;\n    }\n\n    & input {\n        height: 35px;\n        border-radius: 5px;\n        border: 1px solid #D9D9D9;\n\n    }\n}\n\n.model_button {\n    display: flex;\n    justify-content: flex-end;\n    gap: 10px;\n\n    & button {\n        display: flex;\n        width: 87px;\n        height: 39px;\n        padding: 10px 20px;\n        justify-content: center;\n        align-items: center;\n        gap: 10px;\n        border: none;\n        color: #FFF;\n        font-family: Inter;\n        font-size: 0.8rem;\n        font-weight: 500;\n        border-radius: 3px;\n        background: var(--main-color);\n    }\n}\n\n.model_button button:hover {\n    color: #FFF;\n    background: var(--main-color);\n}\n\n.model_button button:active {\n    color: #FFF;\n    background: var(--main-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_section": `modal_main_section__ES9CQ`,
	"model_button": `modal_model_button__oo8aw`
};
export default ___CSS_LOADER_EXPORT___;
