// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion-item:not(:first-of-type) {
    border-top: 1px solid #d9d9d9;
}

.accordion-item:first-of-type .accordion-button {
    color: #1B1B28;
    font-family: "Inter";
    font-size: 0.8rem;
    font-weight: 400;
}

.accordion-item:last-of-type .accordion-button.collapsed {
    color: #1B1B28;
    font-family: "Inter";
    font-size: 0.8rem;
    font-weight: 400;
}

.product_accordian:nth-child(2) .accordion-button.collapsed {
    color: #1B1B28;
    font-family: "Inter";
    font-size: 0.8rem;
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/Pages/RolePermission/PermissionData/permission.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".accordion-item:not(:first-of-type) {\n    border-top: 1px solid #d9d9d9;\n}\n\n.accordion-item:first-of-type .accordion-button {\n    color: #1B1B28;\n    font-family: \"Inter\";\n    font-size: 0.8rem;\n    font-weight: 400;\n}\n\n.accordion-item:last-of-type .accordion-button.collapsed {\n    color: #1B1B28;\n    font-family: \"Inter\";\n    font-size: 0.8rem;\n    font-weight: 400;\n}\n\n.product_accordian:nth-child(2) .accordion-button.collapsed {\n    color: #1B1B28;\n    font-family: \"Inter\";\n    font-size: 0.8rem;\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
