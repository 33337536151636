// import React from 'react';
// import { MdKeyboardArrowLeft } from "react-icons/md";
// import { MdKeyboardArrowRight } from "react-icons/md";
// import styles from './newpagination.module.css'


// const NewPagination = ({setPage, pageInfo , setLimit}) => {

//   const handlePageChange = (newPage) => {
//     if (newPage < 1 || newPage > pageInfo.totalPages) {
//       return;
//     }
//     setPage(newPage);
//   };

//   const renderPageNumbers = () => {
//     const pageNumbers = [];

//     for (let i = 1; i <= pageInfo.totalPages && i <= 6; i++) {
//       pageNumbers.push(
//         <button
//           key={i}
//           onClick={() => handlePageChange(i)}
//           disabled={i === pageInfo.page}
//           className={` ${styles.btn2} `}
//         >
//           {i}
//         </button>
//       );
//     }

//     return pageNumbers;
//   };

//   return (
//     <div className='d-flex justify-content-between align-items-center'>
//         <select name="" id="" className={styles.select_tag} onChange={(e)=>setLimit(e.target.value)}>
//       <option value="10">10</option>
//       <option value="50">50</option>
//       <option value="100">100</option>
//     </select>
//     <div style={{display:"flex", justifyContent:"end", margin:"10px 0", gap:"5px"}}>
//       <button  className= {`${styles.btn1}`}
//         disabled={!pageInfo.hasPrevPage}
//         onClick={() => handlePageChange(pageInfo.prevPage)}
//       >
//        <MdKeyboardArrowLeft />

//       </button>
//       {renderPageNumbers()}
//       <button className={`${styles.btn1} `}
//         disabled={!pageInfo.hasNextPage}
//         onClick={() => handlePageChange(pageInfo.nextPage)}
//       >
//        <MdKeyboardArrowRight />

//       </button>
//     </div>
  
//     </div>
//   );
// };

// export default NewPagination;
import React, { useEffect, useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import styles from './newpagination.module.css';
import { useLocation } from 'react-router-dom';

const NewPagination = ({ setPage, pageInfo, setLimit }) => {

  const [input,setInput] = useState(10)

  const location = useLocation();
  
  useEffect(() => {
    // Check if the limit query parameter is present
    const queryLimit = new URLSearchParams(location.search).get('limit');
    if (queryLimit) {
        setInput(parseInt(queryLimit)); // Update the limit state if limit query parameter exists
    }
}, [location.search]);

  const handleInput = val => {
    setLimit(val.target.value)
    setInput(val.target.value)
  }

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > pageInfo.totalPages) {
      return;
    }
    setPage(newPage);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPages = pageInfo.totalPages;
    const currentPage = pageInfo.page;

    // Calculate the start and end page numbers
    const startPage = Math.max(1, currentPage - 3);
    const endPage = Math.min(totalPages, currentPage + 2);

    // Ensure at least 6 pages are shown
    const adjustedStartPage = Math.max(1, endPage - 5);
    const adjustedEndPage = Math.min(totalPages, adjustedStartPage + 5);

    for (let i = adjustedStartPage; i <= adjustedEndPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          disabled={i === currentPage}
          className={`${styles.btn2}`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className='d-flex justify-content-between align-items-center'>
      <select className={styles.select_tag} value={input} onChange={handleInput}>
        <option value="10">10</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
      <div style={{ display: "flex", justifyContent: "end", margin: "10px 0", gap: "5px" }}>
        <button
          className={`${styles.btn1}`}
          disabled={!pageInfo.hasPrevPage}
          onClick={() => handlePageChange(pageInfo.prevPage)}
        >
          <MdKeyboardArrowLeft />
        </button>
        {renderPageNumbers()}
        <button
          className={`${styles.btn1}`}
          disabled={!pageInfo.hasNextPage}
          onClick={() => handlePageChange(pageInfo.nextPage)}
        >
          <MdKeyboardArrowRight />
        </button>
      </div>
    </div>
  );
};

export default NewPagination;
