// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchBar_search_bar__qBea1 {
    position: relative;
}

.SearchBar_search_bar__qBea1>img {
    position: absolute;
    width: 20px;
    top: 8px;
    left: 13px;
    cursor: pointer;
}

.SearchBar_search_bar__qBea1>input {
    width: 100%;
    height: 40px;
    border-radius: var(--br);
    border: none;
    padding: 0 50px;
    outline: none;
    color: #b6b6b6;
}

.SearchBar_search_bar__qBea1 input::placeholder {
    color: #b6b6b6;
}

@media screen and (max-width:650px) {
    .SearchBar_search_bar__qBea1 {
      width: 100%;
    }
  }`, "",{"version":3,"sources":["webpack://./src/Component/SearchBar/SearchBar.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,wBAAwB;IACxB,YAAY;IACZ,eAAe;IACf,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI;MACE,WAAW;IACb;EACF","sourcesContent":[".search_bar {\n    position: relative;\n}\n\n.search_bar>img {\n    position: absolute;\n    width: 20px;\n    top: 8px;\n    left: 13px;\n    cursor: pointer;\n}\n\n.search_bar>input {\n    width: 100%;\n    height: 40px;\n    border-radius: var(--br);\n    border: none;\n    padding: 0 50px;\n    outline: none;\n    color: #b6b6b6;\n}\n\n.search_bar input::placeholder {\n    color: #b6b6b6;\n}\n\n@media screen and (max-width:650px) {\n    .search_bar {\n      width: 100%;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search_bar": `SearchBar_search_bar__qBea1`
};
export default ___CSS_LOADER_EXPORT___;
