import React, { useState } from 'react'
import { BsBell } from 'react-icons/bs'
import classes from './NotificationDiv.module.css'
import Cookies from 'js-cookie'
import { BASE_URL } from '../../../Apis/BaseUrl'
import axios from 'axios'
import Moment from 'react-moment'
import { useNavigate } from 'react-router-dom'

const NotificationDiv = (props) => {
  const [read, setRead] = useState({})

    const data = props?.data
    console.log(data)

    const functionHandler = () => {
        props.popupFunc(true)
        // props.setterFunc(false)
    }
    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    let token = profileToken.token;
    console.log(token)

    const navigate = useNavigate()
    const handleNavigate = () => {
      switch (data?.redirect) {
        case "order":
          navigate('/sales/allorder');
          break;
        case "payment":
          navigate('/sales/payment');
          break;
        case "refund request":
          navigate('/refund');
          break;
        case "withdraw request":
          navigate('/sales/withdraw');
          break;
        case "customer":
          navigate('/customer');
          break;
        case "seller":
          navigate('/seller');
          break;
        case "product":
          navigate('/product/allproduct');
          break;
        case "contact":
          navigate('/marketing/contact');
          break;
        case "newsletter":
          navigate('/marketing/subscribemail');
          break;
        default:
          // Handle the case when data.redirect doesn't match any of the above cases
          break;
      }
      
    }
    const handleClick = async () => {
      try {
        // props?.handleModal(data)
 
        const register = `${BASE_URL}/notification/${data._id}/seen`; //get id by props
        console.log(register)
  
        let res = await axios
          .put(register, {}, {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${token}`,
            }
          })
        console.log(res, "ttt");
        setRead(res.data.data)
        handleNavigate()
        
      } catch (error) {
        console.log(error)
      }
      // setNotificationData(res.data.data?.docs);
  
    }
   

    const differTime = <Moment fromNow date={data?.createdAt} />
    const conditionalClass = data.is_read == true ? classes.row : classes.row1;

  
    return (
        <div className={conditionalClass}  onClick={handleClick}>
            <div className={classes.bell}><BsBell /></div>
            <p className={classes.p}>{data?.subject?.slice(0, 18)}...</p>
            <span className={classes.span}>{differTime}</span>
        </div>
    )
}

export default NotificationDiv