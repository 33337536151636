import React, { useState } from 'react'
import styles from './AppModal.module.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../../Apis/BaseUrl';
import axios from 'axios';
import { fileChecker } from '../../../Component/FileChecker/fileChecker';
import Cookies from 'js-cookie';
import { Form } from 'react-bootstrap';
import ImageUploader from '../ImageUploader';

const AddBanner = ({ isOpen, closeModal, data }) => {
    const [heading, setHeading] = useState('')
    const [tagline, setTagline] = useState('')
    const [ctaUrl, setCtaUrl] = useState('')
    const [sequence, setSequence] = useState('top')
    const [loading, setLoading] = useState(false)
    const [youtubeUrl, setYoutubeUrl] = useState('')
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [mobileBanner, setMobileBanner] = useState(null);

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleImageSelect = (setterFunc,content) => {
        setterFunc(content);
    };


    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;

    const handleDataUpload = async () => {
        // alert('clicked')
        // console.log("yyyy")
        const formData = new FormData();
        formData.append('heading', heading);
        formData.append('tagline', tagline);
        formData.append('ctaUrl', ctaUrl);
        formData.append('sequence', sequence);
        formData.append('contentType', selectedOption);
        // if (selectedOption == "image") {
        //     // console.log("yes")
        //     if (selectedImage) {
        //         let checkFile = fileChecker(selectedImage)
        //         if (!checkFile.value) {
        //             toast.error(checkFile.message)
        //             return
        //         }
        //         formData.append('image', selectedImage);
        //     }
        // } else {
        //     // console.log("no")
        //     formData.append('image', youtubeUrl);
        //     // console.log(youtubeUrl)
        // }

        if (selectedOption === 'image' && selectedImage) {
            formData.append('image', selectedImage);
        } else if (selectedOption === 'youtube') {
            formData.append('image', youtubeUrl);
        }

        if (mobileBanner) {
            formData.append('mobile_banner',mobileBanner)
        }

        const register = `${BASE_URL}/banner`

        const myToast = toast.loading('Please Wait...')
        setLoading(true)
        try {
            const response = await axios.post(register, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            })

            console.log(response)
            if (!response.data.success) {
                throw new Error(response.data.message)

            }
            toast.update(myToast, {
                render: response.data.message,
                type: 'success',
                isLoading: false,
                autoClose: 1500
            });
            data()
            setTagline('')
            setHeading('')
            setCtaUrl('')
            setSequence('')
            setSelectedImage(null)
            setMobileBanner(null)
        } catch (error) {
            console.error('Error while uploading data:', error);

            toast.update(myToast, {
                render: error.message,
                type: 'error',
                isLoading: false,
                autoClose: 1500
            });
        }
        finally {
            closeModal()

            setLoading(false)
        }
    };

    const handleUploadClick = () => {
        document.getElementById("fileInput").click();
    };

    return (
        <React.Fragment>
            <Modal show={isOpen} onHide={closeModal}>
                <Modal.Header>
                    <Modal.Title className={styles.modal_title}>Add Banner</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-3'>
                    <div className='mx-auto' md={9}>
                        <div className={styles.main_section}>
                            <Form>
                                <div className={styles.main_div}>
                                    <label htmlFor="">Heading</label>
                                    <input type="text" value={heading} onChange={(e) => setHeading(e.target.value)} />
                                </div>
                                <div className={styles.main_div}>
                                    <label htmlFor="">Tagline</label>
                                    <input type="text" value={tagline} onChange={(e) => setTagline(e.target.value)} />
                                </div>
                                <div className={styles.main_div}>
                                    <label htmlFor="">Button Url</label>
                                    <input type="text" value={ctaUrl} onChange={(e) => setCtaUrl(e.target.value)} />
                                </div>
                                <div className={styles.new_div}>
                                    <div className={styles.main_div}>
                                        <label htmlFor="">Sequence</label>
                                        <Form.Select required aria-label="Default select example" value={sequence} onChange={(e) => setSequence(e.target.value)} >

                                            <option value="top">Top</option>
                                            <option value="mid">Middle</option>
                                            <option value="bottom">Bottom</option>
                                        </Form.Select>

                                    </div>
                                    <Form.Group className={`${styles.main_div}`} >
                                        <Form.Label>Banner</Form.Label>

                                        <Form.Select style={{ color: "#989898" }} value={selectedOption} onChange={handleOptionChange} required aria-label="Default select example">
                                            <option value="">Select an option</option>
                                            <option value="youtube">Video (YouTube URL)</option>
                                            <option value="image">File</option>
                                        </Form.Select>
                                        {/* )} */}
                                    </Form.Group>
                                </div>
                                <Form.Group className={`${styles.main_div}`
                                }>
                                    {selectedOption === 'youtube' && (
                                        (<>
                                            <Form.Label>YouTube URL:</Form.Label><div className={styles.vidio}>

                                                <input type="text" className="w-100" style={{
                                                    height: "45px",
                                                    border: "1px solid #d9d9d9",
                                                    borderRadius: "5px", outline: "none", color: "#989898"
                                                }} onChange={(e) => setYoutubeUrl(e.target.value)} id="videoUrl" name="videoUrl" />
                                            </div></>)
                                    )}
                                </Form.Group>
                                {selectedOption === 'image' && (
                                    // <div className={styles.main_div}>
                                    //     <label htmlFor="">Upload Image</label>
                                    //     <div className={`${styles.upload_box} w-100 mt-0`} onClick={handleUploadClick}>
                                    //         <p className='mb-0 fw-medium' style={{ color: "#AE0000" }}>Upload Image only in jpeg and png</p>
                                    //         <input type="file" id="fileInput"
                                    //             // accept="png" 

                                    //             onChange={(e) => setSelectedImage(e.target.files[0])}
                                    //             className='d-none' />
                                    //     </div>
                                    // </div>
                                    <ImageUploader onImageSelect={handleImageSelect.bind('',setSelectedImage)} />
                                )}
                                <ImageUploader label={'Upload Mobile Banner'} onImageSelect={handleImageSelect.bind('',setMobileBanner)} />
                            </Form>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div className={styles.model_button}>
                        <button className='bg-white text-black border' variant="secondary" onClick={closeModal}> Cancel  </button>
                        <button variant="primary" onClick={handleDataUpload}> Add Banner </button>
                    </div>

                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default AddBanner