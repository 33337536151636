import React from 'react'
import classes from './BlackButton.module.css'

const BlackButton = (props) => {

  const run = () => {
    if (props.func) {
     
      props.func()
    }
  }

  return (
    <button onClick={run} disabled={props.disabled} type={props.type? props.type : 'button'} className={`${classes.button} ${props.cls}`}>
        {props.children}
    </button>
  )
}

export default BlackButton