import React from 'react'
import styles from './modal.module.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ColorModal = ({ modalOpen, closeModal }) => {
    return (

        <React.Fragment>
            <Modal show={modalOpen}>
                <Modal.Header>
                    <Modal.Title>Add new Color</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-3'>
                    <div className={styles.main_section}>
                        <label for="name">Name</label>
                        <input type="text" id='name' />
                    </div>
                    <div className={`${styles.main_section}`}>
                        <label for="Code">Color Code</label>
                        <input type="text" id='Code' />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={styles.model_button}>
                        <Button className='bg-white text-black border' variant="secondary" onClick={closeModal}> Cancel  </Button>
                        <Button variant="primary" onClick={closeModal}>  Save </Button>
                    </div>

                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default ColorModal