import React from 'react'
import classes from './ChatMessage.module.css'
import { Link } from 'react-router-dom';

const ChatMessage = ({ data }) => {

    // console.log(data);

    const mycls = {
        user: '',
        support: classes.reply,
        counselor: ''
    }
    // console.log("bhdbfhdbfhdbhfhg")
    // console.log(data)
    return (
        <div className={`${classes.message} ${mycls[data.sender]}`}>
            {data.message && data.attachment ? (
                <div className={classes.para}>
                    <p className='mb-0' >
                        {data.message}
                    </p>
                    <a
                        target="_blank"
                        style={{ fontSize: "12px" }}
                        href={data.attachmentUrl}
                    >
                        {data.attachment}
                    </a>
                </div>
            ) : data.message ? (
                <p className={classes.para}>
                    {data.message}
                </p>
            ) : data.attachment ? (
                <p className={classes.para}>  <a
                    target="_blank"
                    style={{ fontSize: "12px" }}
                    href={data.attachmentUrl}
                    // className={classes.para}
                >
                    {data.attachment}
                </a></p>
            ) : ""}

            <div className={classes.message_detail}>
                <span className={classes.message_span}>
                    {/* <GetDate date={data.createdAt} /> */}
                </span>
                <span className={classes.message_span}>
                    {/* <TimeComp timeData={data.createdAt} /> */}
                </span>
            </div>
        </div>
    )
}

export default ChatMessage

