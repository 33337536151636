// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LabelledInput_input_div__UIlvc{}
.LabelledInput_input_div__UIlvc > label{
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #2A2A2A;
}
.LabelledInput_input_div__UIlvc > input{
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    height: 40px;
    padding-left: 10px;
    font-size: 14px;
    color: #989898;
    font-weight: 400;
    width: 100%;
    outline: none;
}
.LabelledInput_tagContainer__nvd-s{
    display: flex;
    gap: 20px;
    margin: 10px 0;
}
.LabelledInput_tag__Ju86\\+{
    background: #F3F3F3;
    padding: 10px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 500;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.LabelledInput_password_toggle_icon__r8UiB {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #6c757d !important;/* Adjust the color as needed */
  }
  
  .LabelledInput_password_toggle_icon__r8UiB:hover {
    color: #007bff; /* Adjust the hover color as needed */
  }
`, "",{"version":3,"sources":["webpack://./src/Component/LabelledInput/LabelledInput.module.css"],"names":[],"mappings":"AAAA,gCAAW;AACX;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,WAAW;IACX,aAAa;AACjB;AACA;IACI,aAAa;IACb,SAAS;IACT,cAAc;AAClB;AACA;IACI,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,2BAA2B;IAC3B,eAAe;IACf,yBAAyB,CAAC,+BAA+B;EAC3D;;EAEA;IACE,cAAc,EAAE,qCAAqC;EACvD","sourcesContent":[".input_div{}\n.input_div > label{\n    display: block;\n    font-size: 14px;\n    font-weight: 600;\n    margin-bottom: 5px;\n    color: #2A2A2A;\n}\n.input_div > input{\n    border-radius: 5px;\n    border: 1px solid #d9d9d9;\n    height: 40px;\n    padding-left: 10px;\n    font-size: 14px;\n    color: #989898;\n    font-weight: 400;\n    width: 100%;\n    outline: none;\n}\n.tagContainer{\n    display: flex;\n    gap: 20px;\n    margin: 10px 0;\n}\n.tag{\n    background: #F3F3F3;\n    padding: 10px;\n    border-radius: 5px;\n    font-size: 13px;\n    font-weight: 500;\n    border: 1px solid #d9d9d9;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 10px;\n}\n\n.password_toggle_icon {\n    position: absolute;\n    top: 50%;\n    right: 15px;\n    transform: translateY(-50%);\n    cursor: pointer;\n    color: #6c757d !important;/* Adjust the color as needed */\n  }\n  \n  .password_toggle_icon:hover {\n    color: #007bff; /* Adjust the hover color as needed */\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input_div": `LabelledInput_input_div__UIlvc`,
	"tagContainer": `LabelledInput_tagContainer__nvd-s`,
	"tag": `LabelledInput_tag__Ju86+`,
	"password_toggle_icon": `LabelledInput_password_toggle_icon__r8UiB`
};
export default ___CSS_LOADER_EXPORT___;
