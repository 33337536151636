import React from 'react'
import styles from './ticket.module.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Ticket = ({ isOpen, closeModal }) => {
    return (
        <React.Fragment>
            <Modal show={isOpen} onHide={closeModal}>
                <Modal.Header>
                    <Modal.Title>Support Ticket Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-3'>
                    <div className={styles.main_section}>
                        <label for="name">Name</label>
                        <p>2147483647</p>
                    </div>
                    <div className={styles.main_section}>
                        <label for="name2">Seller Name</label>
                        <p>Nishant Choudhary</p>
                    </div>
                    <div className={styles.main_section}>
                        <label>Ticket subject </label>
                        <h6>Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</h6>
                    </div>
                    <div className={styles.main_section}>
                        <label for="meta">Description</label>
                        <textarea rows="4" cols="50" type="text" id='meta' placeholder='Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.' />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={styles.model_button}>
                        <Button variant="primary" onClick={closeModal}>  Mark resolved </Button>
                        <Button className='bg-white text-black border' variant="secondary" onClick={closeModal}> Cancel  </Button>
                    </div>

                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default Ticket