import React, { useEffect, useState } from 'react'
import styles from './modal.module.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Rating from '../../../Component/Rating/RatingCard';
import { Link } from 'react-router-dom';
import RatingCard from '../../../Component/Rating/RatingCard';
import Cookies from 'js-cookie';
import axios from 'axios';
import { BASE_URL } from '../../../Apis/BaseUrl';
import ToasterUpdate from '../../../Component/Toaster/ToasterUpdate';
import { toast } from 'react-toastify';
import FallProduct from '../../../Component/FallbackImgae/FallProduct';


const ReviewReportModal = ({ isOpen, closeModal, id, func }) => {
    const [reply, setReply] = useState('')
// console.log(id)
    // console.log(data)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;

    const getData = async () => {
        // console.log(register)
        let res = await axios.get(`${BASE_URL}/reviewsReport/${id}/show`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token} `,
            },
        })
        console.log(res.data.data)
        setData(res.data.data)
        // setPageInfo({ ...res.data.data, docs: null })
    }

    useEffect(() => {
        getData()
    }, [])

    const handleAdd = async (e) => {
        e.preventDefault()

        let bdy = {
            reply: reply
        }
        let register = `${BASE_URL}/review/${id}/reply`
        const myToast = toast.loading('Please Wait...')
        setLoading(true)
        try {

            let res = await axios.post(register, bdy, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token} `,
                },
            })
            console.log(res)
            if (!res.data.success) {
                ToasterUpdate(myToast, res.data.message, "error")
            }
            ToasterUpdate(myToast, res.data.message, "success")

            func()
            closeModal()
        } catch (error) {
            // console.log(error)
            ToasterUpdate(myToast, error.message, "error")
        }
        finally {
            setLoading(false)
            setReply('')
        }
    };


    const actualPrice = data?.productId?.price;
    const discountAmount = data?.productId?.discount;

    // Calculate discount percentage
    const discountPercentage = (discountAmount / actualPrice) * 100;

    // Calculate discounted price
    const discountedPrice = actualPrice - discountAmount;

    return (
        <React.Fragment>
            <Modal show={isOpen} onHide={closeModal}>
                <Modal.Title>

                    <div className={styles.header}>
                        <div>
                            <h6>Review Report Detail</h6>
                        </div>
                        {/* <div className="form-check form-switch d-flex justify-content-end">
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={data?.approved} />
                        </div> */}
                    </div>
                    <hr className='mb-0' />
                </Modal.Title>

                    <Modal.Body className={`${styles.bdy} mb-2`}>
                       
                        <div className={`${styles.content}`}>
                           <div>
                           <p className='fw-semibold'>Subject :  <span className='fw-normal'>{data?.subject}</span></p>
                           
                           </div>
                           <div>
                           <p className='fw-semibold'>Comment :  <span className='fw-normal'>{data?.reviewId?.comment}</span></p>
                           
                           </div>
                           <div>
                           <p className='fw-semibold'>Rating :  <span className='fw-normal'>{data?.reviewId?.rating}</span></p>
                           
                           </div>
                           <div>
                           <p className='fw-semibold'>Description :  <span className='fw-normal'>{data?.describe}</span></p>
                           
                           </div>
                        </div>
                       
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <div className={styles.model_button}>
                            <Button className='bg-white text-black border' variant="secondary" onClick={closeModal}> Cancel  </Button>
                            {/* <Button variant="primary" onClick={handleAdd}>Publish</Button> */}
                        </div>

                    </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default ReviewReportModal