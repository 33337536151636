import React, { useState } from 'react';
import styles from './menu.module.css';
import { Button, Col, Row } from 'react-bootstrap';
import { FaRegUserCircle } from "react-icons/fa";
import { HiOutlineSupport } from "react-icons/hi";
import { IoSearchSharp } from "react-icons/io5";
import { IoNotificationsOutline } from "react-icons/io5";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Notifications from '../Notifications/Notifications';



const TopNavbar = () => {
    const [notification, setNotification] = useState(false)

    const navigate = useNavigate()
    const handleLogout = () => {
        navigate('/')
        Cookies.remove("v&b_token")
    }
    return (
        <React.Fragment>
            {notification && <Notifications setterFunc={setNotification} />}

            <Row className='w-100'>
                <div className={styles.menu}>
                    <Col md={4}>
                        <div className={styles.logo}>
                            <Link to={'/'}> <img src="/image/logo.png" alt="icon" /></Link>
                        </div>
                    </Col>

                    <Col md={8}>
                        <div className={styles.profile_section}>
                            {/* <div className={styles.profile_section1}>
                            <input type="text" placeholder='Search' />
                            <IoSearchSharp className={styles.serach_icon} />
                            </div> */}
                            {/* <h6><HiOutlineSupport className={styles.icon} /> Help</h6>
                            <h6><FaRegUserCircle className={styles.icon} /> Profile</h6> */}
                            <div className='d-flex gap-3'>
                                <Link className={[styles.visualizer]} to={'/visualize'}><span style={{
                                    color: 'black',
                                    fontSize: '23px',
                                    fontFamily: 'inherit',
                                    fontWeight: 'bolder'
                                }}>3D</span> Visualizer</Link>
                                <h6 onClick={() => setNotification(!notification)}><IoNotificationsOutline className={styles.icon} /> Notifications</h6>
                                <Button className='bg-dark p-2 border-0' onClick={handleLogout}>Logout</Button>
                            </div>
                        </div>
                    </Col>
                </div>
            </Row>

        </React.Fragment>

    )
}

export default TopNavbar